<template>
  <div class="checkout-flow-item notes">
    <div class="checkout-flow-item-header">
      <h2 v-text="$t('step-flow-notes-title')"></h2>
      <div class="intro" v-if="false" v-text="$t('step-flow-courses-intro')"></div>
    </div>
    <div class="checkout-flow-item-content only-notes"
      v-if="pointofsale && (!pointofsale.showCourses || (company.courses && company.courses.length == 1) || !pointofsale.enableTableService || !availableDeliveryOptions.includes('table') ||  deliveryType != 'table' ) && pointofsale.allowNotesOnIndividualDishes">
      <meal-list-item v-for="item of itemsList" :item="item" :key="item.id" @notechanged="changeNote(item, $event)"
        :enable-notes="true"></meal-list-item>
    </div>
    <div class="checkout-flow-item-footer">
      <div class="flow-navigation-buttons">
        <ion-button class="back-button" color="medium" expand="block" @click="gotoPreviousStep" :size="frameWidth < 350 ? 'small': 'default'">
          <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
          <span class="label">{{ $t("step-flow-back-button-label") }}</span>
        </ion-button>

        <ion-button class="next-button"  :disabled="totalNumberOfItems == 0" expand="block"
          @click="$emit('next')" :size="frameWidth < 350 ? 'small': 'default'">
          <span class="label">{{ $t("step-flow-cart-notes-next-button-label") }}</span>
          <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/vue"

import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons"
import MealListItem from "./MealListItem.vue"


</script>


<script>

export default {
  props:['step'],
  data() {
    return {
      items: {},
    };
  },
  watch: {
    $route: function () { },
  },
  computed: {
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    itemsList() {
      let items = [];
      for (let courseId in this.itemsByCourse) {
        let itemsInCourse = this.itemsByCourse[courseId]
        for (let item of itemsInCourse) {
          items.push(item)
        }
      }
      return items

    },
    itemsByCourse() {
      return this.services.cartService.itemsByCourse ? this.services.cartService.itemsByCourse.value : null;
    },
    deliveryType() {
      return this.services.cartService.deliveryType ? this.services.cartService.deliveryType.value : false;
    },
    pointofsaleId() {
      return this.$route.params["pointofsaleId"] ? this.$route.params["pointofsaleId"] : null;
    },
    pointofsale() {
      if (!this.pointofsaleId) {
        return null;
      }
      return this.companyService.getPointofsaleById(this.pointofsaleId);
    }
  },
  methods: {

    getNameOfProduct(productId) {
      let product = this.companyService.getProductById(productId);
      if (!product) {
        return null;
      }
      return this.services.translations.translation2Text(product.name);
    },
    
    changeNote(element, data) {
      element.note = data;
    },
    gotoPreviousStep() {
      this.$emit("previous");
    },
  },
};
</script>
<style scoped>
.course-header {
  text-align: center;
  font-weight: 200;
  font-size: 1.4rem;
}

.course-container {
  min-height: 2rem;
}

.meal-item:first-child {
  border-top: 1px solid #ddd;
}
</style>

<style>
.checkout-flow-item.notes .meal-item .drag-handle {
  display: none !important;
  ;
}
</style>