

import { toRefs, reactive, watch } from "vue";
import Validator from "validatorjs";
import companyServiceModule from "./companyService";
const companyService = companyServiceModule();


const state = reactive({
    selectedLanguage: null,
    defaultLanguage: null,
    availableLanguages: [],
    initialized: false,
    initializing: false,
});




function translation2Text(translationMap) {

    if (!translationMap) {
        return null;
    }

    if (typeof translationMap == "string") {
        return translationMap;
    }
    let selectedLanguage = state.selectedLanguage ? state.selectedLanguage : null;

    if (selectedLanguage && translationMap[selectedLanguage]) {
        return translationMap[selectedLanguage];
    }

    let defaultLanguage = state.defaultLanguage ? state.defaultLanguage : null;
    if (defaultLanguage && translationMap[defaultLanguage]) {
        return translationMap[defaultLanguage];
    }

    for (let language of state.availableLanguages) {
        if (translationMap[language]) {
            return translationMap[language];
        }
    }

    for (let language in translationMap) {
        if (translationMap[language]) {
            return translationMap[language];
        }
    }

    return ''
}

function selectLanguage(language) {
    if (state.availableLanguages.includes(language)) {
        state.selectedLanguage = language;
    } else {
        state.selectedLanguage = null;
        // console.log('language not available', language, state.availableLanguages);
    }
    Validator.useLang(state.selectedLanguage ? state.selectedLanguage : 'en');
}


function setAvailableLanguages(languages) {
    if (!languages || languages.length <= 0) {
        languages = []
    }
    state.availableLanguages = languages;

}
function setDefaultLanguage(language) {
    if (!language) {
        language = null;
    }
    state.defaultLanguage = language

}

function settingsChanged() {

    let settings = companyService.company ? companyService.company.value : null;

    let enabledLanguages = []
    let defaultLanguage = null
    if (settings && settings.i18n) {

        if (settings.i18n.defaultLanguage) {
            defaultLanguage = settings.i18n.defaultLanguage;
        }
        if (settings.i18n.languages && settings.i18n.languages.length > 0) {
            for (let language of settings.i18n.languages) {
                enabledLanguages.push(language);
            }

            if (!defaultLanguage || !enabledLanguages.includes(defaultLanguage)) {
                defaultLanguage = enabledLanguages[0];
            }

        }
    }    
    setAvailableLanguages(enabledLanguages)
    setDefaultLanguage(defaultLanguage)


    if (!state.selectedLanguage || !enabledLanguages.includes(state.selectedLanguage)) {
        let userLanguage = navigator.language ? navigator.language.split('-')[0] : null;
        selectLanguage(userLanguage && enabledLanguages.includes(userLanguage) ? userLanguage : defaultLanguage);                
    }
}



export default function () {

    if (!state.selectedLanguage) {
        state.selectedLanguage = null;
    }
    if (!state.defaultLanguage) {
        state.defaultLanguage = null;
    }

    if (!state.initialized && !state.initializing) {

        state.initializing = true;


        //company is already loaded
        if (companyService.company && companyService.company.value && companyService.company.value.i18n) {

            settingsChanged();
        }
        watch(companyService.company, (settingsRef) => {

            settingsChanged();
        })

        state.initialized = true
        state.initializing = false
    }


    return {
        ...toRefs(state),
        translation2Text,
        selectLanguage,
        setAvailableLanguages,
        setDefaultLanguage,
    }


}