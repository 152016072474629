<template>
  <ion-fab class="fab-translation-selector" :horizontal="horizontal" :vertical="vertical" slot="fixed"  v-show="availableLanguages && availableLanguages.length > 1">
    <ion-fab-button size="small">
      <ion-label v-text="selectedLanguage"></ion-label>      
    </ion-fab-button>
    <ion-fab-list :side="side">      
      <ion-fab-button
        color="light"
        v-for="language of availableLanguages"
        :key="language"
        :value="language"
        @click="selectLanguage(language)"
      >
        <ion-label v-text="language"></ion-label>      
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
</template>

<script >
import translationService from "../composables/translationService";
import { IonFab, IonFabButton, IonFabList, IonLabel } from "@ionic/vue";

//DE 🇩🇪 EN 🇬🇧 ES 🇪🇸
export default {  
  props: ["value", "horizontal", "vertical", "side"],
  components: { IonFab, IonFabButton, IonFabList, IonLabel },
  data() {
    return {};
  },
  watch: {},
  async unmounted() {},
  async mounted() {

  },

  computed: {
    selectedLanguage() {
      return this.translationService.selectedLanguage ? this.translationService.selectedLanguage.value : null;
    },
  }
  ,
  methods: {
    selectLanguage(language) {      
      this.translationService.selectLanguage(language)
    },
  },
  setup() {
    let translationServiceInstance =  translationService();
    
    return {
      translationService: translationServiceInstance,      
      availableLanguages: translationServiceInstance.availableLanguages,
      
    };
  },
};
</script>
<style scoped>
</style>>
