import config from '../../config'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence,connectAuthEmulator } from 'firebase/auth';



// let firebaseConfig = (await import(`../../../firebase-config.${config.environment}.js`)).default;

//because vite got no working require
//and import( is async and top level awaits are only implemented since 2021 and eslint doesnt validate  when they are present
import  firebaseConfigProduction  from "../../../firebase-config.production.js";
import  firebaseConfigDev  from "../../../firebase-config.dev.js";
import  firebaseConfigTestumgebung  from "../../../firebase-config.testumgebung.js";


var firebaseApp = null

export default function () {
    var auth = null;

    if (!firebaseApp) {

        let firebaseConfig = firebaseConfigDev
        if(config.environment == 'production'){
            firebaseConfig = firebaseConfigProduction
        }
        if(config.environment == 'testumgebung'){
            firebaseConfig = firebaseConfigTestumgebung
        }

        if(config.environment == "dev"){
            firebaseApp = firebase.initializeApp(firebaseConfig);
        }else{
            firebaseApp = firebase.initializeApp(firebaseConfig, "oido-frontend");
        }
        
        auth = initializeAuth(firebaseApp, {
            persistence: [indexedDBLocalPersistence, browserLocalPersistence]
        });
        
        // if (location.hostname === 'localhost'||location.hostname === '127.0.0.1'){
        //     connectAuthEmulator(auth, "http://localhost:9099");
        //     console.log('using firebase emulators')
        //     firebase.firestore().useEmulator('localhost', 8081);
        //     firebase.functions().useEmulator("localhost", 5001);            
        //     firebase.auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
        //     firebase.storage().useEmulator("localhost", 9199);
        // }        
    }

    return firebaseApp;
}