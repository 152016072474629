

let options = {
  environment: 'production',
  url: "https://www.oido.me",
  apiUrl: "https://www.oido.me",
  cdnUrl: "https://www.oido.me",
  region: "europe-west1",
  translations: {
    languages: {
      available: ['de', 'en', 'es', 'ca', 'fr', 'nl'],//,'zh'],
      default: 'en',
    }
  }
};

if (location.hostname === 'oidowithbugs.web.app') {
  options.environment = 'dev'
  options.url = "https://oidowithbugs.web.app";
  options.apiUrl = "https://oidowithbugs.web.app";
}
if (location.hostname === "demo.oido.me") {
  options.environment = 'testumgebung'
  options.url = "https://demo.oido.me";
  options.apiUrl = "https://demo.oido.me";
}

if (location.hostname.endsWith('bugsandcrashes.com') ||location.hostname.endsWith('recify.eu') || location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '169.254.247.121') {
  options.environment = 'dev'
  options.url = "https://oidowithbugs.web.app";
  options.apiUrl = "https://oidowithbugs.web.app";
  
  // options.environment = 'production'
  // options.url = "https://www.oido.me";
  // options.apiUrl = "https://www.oido.me";

  // options.environment = 'local'
  // options.url = "http://localhost:5002";
  // options.apiUrl = "http://localhost:5002";

  console.log('using localhost as apiUrl and url', options.apiUrl, options.url)
}


export default options;
