const firebaseConfig = {
  apiKey: "AIzaSyCQbzLbyXuyp4lO06kaoGhc50mSsI1vomA",
  authDomain: "oido-cd7de.firebaseapp.com",
  projectId: "oido-cd7de",
  storageBucket: "oido-cd7de.appspot.com",
  messagingSenderId: "385586123812",
  appId: "1:385586123812:web:0ee8ee2fa91c0dbd82b4b1"
};

export default firebaseConfig;

