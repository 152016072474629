import { toRefs, watch, reactive, computed } from "vue";
import { v4 as uuidv4 } from 'uuid';
import companyService from "./companyService";
import config from "../../config"
let pathParts = document.location.pathname.split('/');
import getCustomDomain from '../helpers/getCustomDomain'
import dayjs from "dayjs";

import slotsServiceModule from './slotsService';
const slotsService = slotsServiceModule()


import { useTimestamp } from '@vueuse/core'
const now = useTimestamp({ interval: 60000 })

let storageKeyExtension = ''

//on a custom domain we dont need no extension, because localstorage is bound to domain
if (!getCustomDomain()) {
    //old system
    if (pathParts.length > 2 && pathParts[1] == 'company') {
        storageKeyExtension = pathParts[2];
    }
    if (pathParts.length > 1 && pathParts[1] != 'company') {
        storageKeyExtension = pathParts[1];
    }
    if (!storageKeyExtension) {
        storageKeyExtension = uuidv4();
    }
    storageKeyExtension = '-' + storageKeyExtension
}

let localStorageKey = 'oido-store-v9' + storageKeyExtension;

function writeToLocalStorage(data) {
    // console.log('writeToLocalStorage', data, JSON.stringify(data))
    localStorage.setItem(localStorageKey, JSON.stringify(data))
}
function readFromLocalStorage() {
    let data = JSON.parse(localStorage.getItem(localStorageKey));
    // console.log('readFromLocalStorage', data)
    return data;
}

var stateTimer = null;

const companyServiceInstance = companyService();

const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    clear();
}


const state = reactive({
    // const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    items: {},
    address: {},
    itemsByCourse: {},
    tipPercentage: 10,
    deliveryType: 'takeaway',
    pickupTimeMode: 'assoonaspossible',
    paymentType: 'online',
    pickupTime: null,
    phone: null,
    note: null,
    voucher: null,
    voucherDiscount: null,
    discounts: [],
    activeTags: [],
    errors: {}
});


function clear() {
    state.items = {};
    state.tipPercentage = 10;
    state.itemsByCourse = {};
    state.deliveryType = 'takeaway';
    state.pickupTimeMode = 'assoonaspossible';
    state.pickupTime = null;
    state.paymentType = 'online';
    state.note = null;
    state.voucher = null;
    state.discounts = [];
    state.phone = null;
    state.address = {}
    initItemsByCourse();
}

function emptyTheCart() {
    localStorage.removeItem(localStorageKey)
    if (stateTimer) {
        clearTimeout(stateTimer)
    }
    stateTimer = 'dont'
    clear();

    stateTimer = setTimeout(() => {
        localStorage.removeItem(localStorageKey)
        stateTimer = 'dont'
        clear();
        stateTimer = null;
    }, 800)

}


function changeNote(product, note) {
    if (!product || !product.id) {
        console.log('changeNote no product given', product)
        return
    }
    if (!state.items[product.id]) {
        console.log('changeNote no product found', product)
        return
    }
    state.items[product.id].note = note ? note : null;
}

function getQuantityForProduct(product) {
    if (!product || !product.id) {
        console.warn('getQuantityForProduct no product given', product)
        return 0
    }

    if (!state.items[product.id]) {
        // console.warn('getQuantityForProduct  product not found', product, state.items)
        return 0
    }
    return state.items[product.id].quantity ? Number.parseInt(state.items[product.id].quantity) : 0;
}

function addProductToBasket(product, chosenItems, addons) {

    if (!product || !product.id) {
        console.log('addProductToBasket no product given', product)
        return 0
    }



    let itemId = product.id + '-||-' + uuidv4()
    if (!state.items[itemId]) {
        state.items[itemId] = { id: itemId }
    }

    state.items[itemId].quantity = state.items[itemId].quantity ? state.items[itemId].quantity + 1 : 1;
    state.items[itemId].chosen = chosenItems;
    state.items[itemId].addons = addons && Object.keys(addons).length > 0 ? addons : null;
    state.items[itemId].productId = product.id;

    if (product.type == 'combined') {
        for (let slotId in chosenItems) {
            let chosenProductId = chosenItems[slotId];
            let chosenProduct = companyServiceInstance.getProductById(chosenProductId)

            if (!state.itemsByCourse[chosenProduct.defaultCourseId]) {
                state.itemsByCourse[chosenProduct.defaultCourseId] = [];
            }
            state.itemsByCourse[chosenProduct.defaultCourseId].push({ productId: chosenProduct.id, parentId: product.id, cartItemId: itemId, note: null, slotId: slotId, key: itemId + slotId + 1 });
        }
    } else {
        state.itemsByCourse[product.defaultCourseId].push({ productId: product.id, cartItemId: itemId, note: null, key: itemId + 1 });
    }
}

function deleteItemFromCart(item) {

    if (!item || !item.id) {
        console.log('deleteItemFromCart no item given', item)
        return
    }

    if (!state.items[item.id]) {
        return
    }

    for (let courseId in state.itemsByCourse) {
        state.itemsByCourse[courseId] = state.itemsByCourse[courseId].filter((itemInCourse) => !itemInCourse.cartItemId || itemInCourse.cartItemId != item.id)
    }

    delete state.items[item.id];
}


function countCartItemDown(item) {

    if (!item || !item.id) {
        console.log('countCartItemDown no item given', item)
        return
    }
    if (!state.items[item.id]) {
        return
    }

    let itemsToDelete = null


    if (item.product.type == 'combined') {
        itemsToDelete = Object.values(item.chosen)
    } else {
        itemsToDelete = [item.productId]
    }


    state.items[item.id].quantity = state.items[item.id].quantity && state.items[item.id].quantity > 1 ? state.items[item.id].quantity - 1 : 0;


    if (state.items[item.id].quantity <= 0) {
        delete state.items[item.id];
    }

    let newItemsByCourse = {}

    for (let courseId in state.itemsByCourse) {
        newItemsByCourse[courseId] = [];
        let courses = state.itemsByCourse[courseId];
        for (let itemInCourse of courses) {

            if (itemsToDelete.includes(itemInCourse.productId) && itemInCourse.cartItemId == item.id) {
                let index = itemsToDelete.indexOf(itemInCourse.productId);
                itemsToDelete.splice(index, 1);
            } else {
                newItemsByCourse[courseId].push(itemInCourse);
            }
        }
    }

    state.itemsByCourse = newItemsByCourse;
}

function removeCartItem(itemId) {
    if (!itemId) {
        console.log('removeCartItem no itemId given', itemId)
        return
    }
    if (!state.items[itemId]) {
        return
    }
    delete state.items[itemId];

    let newItemsByCourse = {}

    for (let courseId in state.itemsByCourse) {

        newItemsByCourse[courseId] = [];
        let courses = state.itemsByCourse[courseId];
        for (let itemInCourse of courses) {
            if (itemInCourse.cartItemId != itemId) {
                newItemsByCourse[courseId].push(itemInCourse);
            }
        }
    }
    state.itemsByCourse = newItemsByCourse;
}

function countCartItemUp(item) {
    if (!item || !item.id) {
        console.log('countCartItemUp no item given', item)
        return 0
    }

    let itemId = item.id;
    if (!state.items[itemId]) {
        state.items[itemId] = {}
    }
    state.items[itemId].quantity = state.items[itemId].quantity ? state.items[itemId].quantity + 1 : 1;
    state.items[itemId].productId = item.productId;

    if (!state.itemsByCourse[item.product.defaultCourseId]) {
        state.itemsByCourse[item.product.defaultCourseId] = [];
    }

    if (item.product.type == 'combined') {
        for (let slotId in item.chosen) {
            let chosenProductId = item.chosen[slotId];
            let product = companyServiceInstance.getProductById(chosenProductId)
            if (!product) {
                continue;
            }
            state.itemsByCourse[product.defaultCourseId].push({ productId: product.id, parentId: item.productId, cartItemId: item.id, note: null, slotId: slotId, key: item.id + slotId + state.items[itemId].quantity });
        }
    } else {
        state.itemsByCourse[item.product.defaultCourseId].push({ productId: item.productId, cartItemId: item.id, note: null, key: item.id + state.items[itemId].quantity });
    }
}

function totalNumberOfItems() {
    let total = 0;
    for (let productId in state.items) {
        let item = state.items[productId];
        if (item.quantity) {
            total = total + item.quantity;
        }
    }
    return total;
}

function getCartBreakdown() {
    let subtotal = 0;
    let total = 0;
    let netAmount = 0;
    let grossAmount = 0;
    let deliveryCosts = null;
    let rebates = {}
    let vatByRate = {};

    let company = companyServiceInstance.company.value;

    for (let productId in state.items) {
        let item = state.items[productId];
        let product = companyServiceInstance.getProductById(item.productId)

        if (!product) {
            continue;
        }
        let productPriceWithAddonsAndCorrections = Number.parseFloat(product.price)


        if (item.addons && Object.values(item.addons).length > 0) {
            for (let addonId in item.addons) {

                if (!item.addons[addonId]) {
                    continue
                }

                let addon = null;
                for (let addonItem of product.addons) {
                    if (addonItem.id == addonId) {
                        addon = addonItem;
                        break;
                    }
                }

                if (!addon) {
                    continue
                }
                if (addon.price && Number.parseFloat(addon.price)) {
                    productPriceWithAddonsAndCorrections += Number.parseFloat(addon.price);
                }
            }
        }


        if (product.type == 'combined' && product.slots && product.slots.length > 0) {
            for (let slot of product.slots) {
                // console.log("item.chosen", item, item.chosen)
                let productId = item.chosen[slot.id] ? item.chosen[slot.id] : null;
                if (productId && slot.availableProducts[productId] && slot.availableProducts[productId].correction) {
                    if (slot.availableProducts[productId].correction) {
                        productPriceWithAddonsAndCorrections += Number.parseFloat(slot.availableProducts[productId].correction)
                    }
                }
            }
        }

        let vatRate = Number.parseInt(companyServiceInstance.getTaxForProduct(item.productId));
        netAmount += (item.quantity * productPriceWithAddonsAndCorrections) / ((100 + vatRate) / 100)

        if (vatRate > 0) {
            if (!vatByRate[vatRate]) {
                vatByRate[vatRate] = 0.0;
            }
            vatByRate[vatRate] += ((item.quantity * productPriceWithAddonsAndCorrections) / (100 + vatRate)) * vatRate;
        }

        if (item && item.quantity && product && productPriceWithAddonsAndCorrections) {
            subtotal = subtotal + (item.quantity * productPriceWithAddonsAndCorrections);
        }

        // console.log('netAmount', netAmount)
        // console.log('subtotal', subtotal)
    }



    if (applicableDiscounts.value && applicableDiscounts.value.length > 0) {

        for (let discount of applicableDiscounts.value) {


            let rebate = Number.parseFloat(subtotal) / 100 * Number.parseFloat(discount.percentage);
            subtotal = subtotal - rebate

            let netChange = Number.parseFloat(netAmount) / 100 * Number.parseFloat(discount.percentage);
            netAmount = netAmount - netChange

            for (let vatRate in vatByRate) {
                vatByRate[vatRate] = vatByRate[vatRate] / 100 * (100 - Number.parseFloat(discount.percentage))
            }
            rebates[discount.id] = rebate
        }
    }

    if (
        state.deliveryType == 'delivery' &&
        applicableDeliveryZone.value
    ) {
        deliveryCosts = applicableDeliveryZone.value.price

        let vatRateForDelivery = Number.parseFloat(company.vat);
        let vatForDelivery = (deliveryCosts / (100 + vatRateForDelivery)) * vatRateForDelivery;

        // add vat to the correct tax bracket
        if (!vatByRate[vatRateForDelivery]) {
            vatByRate[vatRateForDelivery] = 0;
        }
        vatByRate[vatRateForDelivery] += vatForDelivery;

        netAmount += (deliveryCosts - vatForDelivery);

        grossAmount = subtotal = subtotal + deliveryCosts;

        total = grossAmount;

    }


    let tip = Number.parseFloat(subtotal) / 100 * state.tipPercentage;
    if (company && company.tipIsEnabled) {
        if (company.tipIsTaxed && company.vatForTips) {
            let vatForTip = 0;
            let vatRateForTip = Number.parseFloat(company.vatForTips);
            vatForTip = (tip / (100 + vatRateForTip)) * vatRateForTip;
            if (!vatByRate[vatRateForTip]) {
                vatByRate[vatRateForTip] = 0;
            }
            vatByRate[vatRateForTip] += vatForTip;
            netAmount += (tip - vatForTip);
            grossAmount = subtotal + tip;
            total = grossAmount;
        } else {//tips enabled but not taxed 
            grossAmount = subtotal;
            total = grossAmount + tip;
        }
    } else {//tips not enabled
        grossAmount = subtotal;
        total = grossAmount;
    }

    return {
        grossAmount,
        netAmount,
        vatByRate,
        deliveryCosts,
        tip: tip,
        subtotal,
        total,
        rebates
    }
}

function productsInCart() {
    let products = [];
    for (let itemId in state.items) {
        let item = state.items[itemId]
        let product = companyServiceInstance.getProductById(item.productId);
        if (state.items[itemId] && product) {
            products.push(product);
        }
    }
    return products;
}

function itemsInCart() {
    let itemsWithProduct = [];
    for (let itemId in state.items) {
        let item = state.items[itemId]
        item.id = itemId;
        let product = companyServiceInstance.getProductById(item.productId);
        if (product) {
            item.product = product
        }
        itemsWithProduct.push(item)
    }
    return itemsWithProduct;
}


function initItemsByCourse() {

    // console.log('initItemsByCourse!!!!!!!!!', companyServiceInstance.company.value);

    if (companyServiceInstance.company && companyServiceInstance.company.value && companyServiceInstance.company.value.id) {

        let company = companyServiceInstance.company.value;
        state.tipPercentage = company.defaultTipPercentage;

        let activePointofsale = companyServiceInstance.activePointofsale && companyServiceInstance.activePointofsale.value ? companyServiceInstance.activePointofsale.value : null



        let hasOnlinePaymentsEnabled = activePointofsale && activePointofsale.enableOnlinePayment;
        //default to online payments, only choose deleviery if takeaway is not enabled (there should always be at least one of them enabled)
        state.paymentType = !hasOnlinePaymentsEnabled ? "offline" : "online";


        //check if allproducts used by cartitems are still valid (exist and are visible)
        //if not simply delete them
        let copiedItemsByCourse = JSON.parse(JSON.stringify(state.itemsByCourse));
        for (let courseId in copiedItemsByCourse) {
            let courses = copiedItemsByCourse[courseId];
            for (let itemInCourse of courses) {
                let cartItem = getCartItemById(itemInCourse.cartItemId)
                if (!cartItem) {
                    continue
                }

                let product = companyServiceInstance.getProductById(itemInCourse.productId);
                if (!product) {
                    removeCartItem(itemInCourse.cartItemId)
                    continue;
                }


                if (cartItem.product && cartItem.product.type == 'combined') {
                    //check if the parentproduct is still available
                    let parentProduct = companyServiceInstance.getProductById(itemInCourse.parentId);

                    if (!parentProduct) {
                        removeCartItem(itemInCourse.cartItemId)
                        continue;
                    }



                    if (itemInCourse.slotId && cartItem.product.slots) {
                        // console.log('cartItem.product', cartItem.product)
                        for (let slot of parentProduct.slots) {
                            // console.log('slot', slot, itemInCourse)
                            if (slot.id == itemInCourse.slotId && slot.availableProducts[itemInCourse.productId]) {
                                // console.log('slot.availableProducts[itemInCourse.productId]', slot.availableProducts[itemInCourse.productId], parentProduct)
                                if (slot.availableProducts[itemInCourse.productId].hidden) {
                                    // console.log('found hidden product',slot.availableProducts[itemInCourse.productId])
                                    removeCartItem(itemInCourse.cartItemId)
                                    continue;
                                }
                            }
                        }
                    }
                }
            }
        }




        let availableDeliveryOptions = [];
        if (activePointofsale.enableTableService) {
            availableDeliveryOptions.push('table')
        }

        if (activePointofsale.enablePickup) {
            availableDeliveryOptions.push('takeaway')
        }
        if (activePointofsale.enableDelivery) {
            availableDeliveryOptions.push('delivery')
        }

        if (availableDeliveryOptions.length > 0 && !availableDeliveryOptions.includes(state.deliveryType)) {
            state.deliveryType = availableDeliveryOptions[0]
        }
    }


    if (Object.keys(state.itemsByCourse).length > 0) {
        // console.log('items already initialized');
        return;
    }


    state.itemsByCourse = {}

    if (companyServiceInstance.company && companyServiceInstance.company.value) {

        let company = companyServiceInstance.company.value;
        state.tipPercentage = company.defaultTipPercentage;

        if (!company.courses) {
            return
        }
        for (let course of company.courses) {
            state.itemsByCourse[course.id] = []
        }
    }


    for (let itemId in state.items) {
        let item = state.items[itemId]
        let product = companyServiceInstance.getProductById(itemId);
        if (!state.itemsByCourse[product.defaultCourseId]) {
            console.log('wrong courseId', product.defaultCourseId)
        }
        for (let i = 0; i < item.quantity; i++) {
            let line = { productId: product.id, cartItemId: itemId, note: null }
            state.itemsByCourse[product.defaultCourseId].push(line)
        }
    }
}

function getCartItemById(cartItemId) {
    if (!cartItemId || !state.items[cartItemId]) {
        return null
    }
    return state.items[cartItemId];
}

function validateCart() {
    state.errors = {}
    if (state.deliveryType == 'takeaway' && state.pickupTimeMode == 'custom') {
        if (!state.pickupTime) {
            state.errors.pickupTime = ['required']
        }
        if (state.pickupTime && dayjs(state.pickupTime).isBefore(dayjs(firstAllowedPickupOrDeliveryDate.value))) {
            state.errors.pickupTime = ['required']
        }
    }
}

const applicableDiscounts = computed(() => {
    let applicableDiscounts = []

    if (state.voucherDiscount) {
        applicableDiscounts.push(state.voucherDiscount)
    }

    if (state.discounts && state.discounts.length > 0) {
        for (let discount of companyServiceInstance.company.value.discounts) {
            let isAlreadyIncluded = applicableDiscounts.filter((elem) => elem.id == discount.id).length > 0

            if (state.discounts.includes(discount.id) && !isAlreadyIncluded) {
                applicableDiscounts.push(discount)
            }
        }
    }
    return applicableDiscounts;
})

function percentageOfAllowedOrderForSlot(numberOfOrders) {
    let company = companyServiceInstance.company.value;
    if (!company || !company.enableSlotLimits) {
        return 0
    }
    if (!numberOfOrders) {
        return 0
    }
    if (!company.allowedNumberOfOrdersPerSlot) {
        return 0
    }

    let percentage = numberOfOrders / company.allowedNumberOfOrdersPerSlot * 100

    return percentage
}

function getSlotsForDay(date) {
    if (!date) {
        return []
    }
    let slots = []
    let slotWidth = 15
    let isDelivery = state.deliveryType == 'delivery'
    let openingHours = isDelivery ? companyServiceInstance.getDeliveryHoursForDay(date) : companyServiceInstance.getOpeningHoursForDay(date)
    let minDate = firstAllowedPickupOrDeliveryDate.value

    
    if (openingHours) {
        for (let range of openingHours) {
            let slotStartTime = dayjs(range.startDate)

            while (slotStartTime.add(slotWidth, 'minutes').isSameOrBefore(range.endDate)) {
                //only push slots that are after the minumum startdate                

                let numberOfOrders = slotsService.getNumberOfOrdersForSlot(slotStartTime)
                let percentage = percentageOfAllowedOrderForSlot(numberOfOrders)
                slots.push({
                    start: slotStartTime,
                    end: slotStartTime.add(slotWidth, 'minutes'),
                    openingHours: range,
                    isInThePast: slotStartTime.isBefore(dayjs()),
                    isSelectable: (!minDate || slotStartTime.isSameOrAfter(minDate, 'minute')) && (percentage < 100),
                    numberOfOrders: numberOfOrders,
                    allocationPercentage: percentage
                })
                slotStartTime = slotStartTime.add(slotWidth, 'minutes')
            }
        }
    }
    return slots
}



function firstAllowedPickupOrDeliveryDateForItem(item) {

    let product = companyServiceInstance.getProductById(item.productId);
    if (!product) {
        console.error('firstAllowedPickupOrDeliveryDateForItem: product not found', item)
        return null
    }


    let productionTime = product.productionTime && product.productionTime.value ? product.productionTime.value : 0
    let productionTimeUnit = product.productionTime && product.productionTime.unit ? product.productionTime.unit : 'days'
    let productionTimeInMinutes = productionTime * ((productionTimeUnit == 'days') ? 24 * 60 : (productionTimeUnit == 'hours') ? 60 : 1)
    // console.log('productionTimeInMinutes', productionTimeInMinutes)

    let firstPossibleDate = null

    // console.log('product.productionTimeIsConstrainedToOpeningHours', product.productionTimeIsConstrainedToOpeningHours)


    // if we have to fit the production time into opening hours
    if (product.productionTimeIsConstrainedToOpeningHours) {

        let loopedDate = dayjs()
        let accountedForMinutes = 0


        // thats a security measure if we are not allowed to split the production time into several opening hour ranges
        // but there is not one big enough to accommodate the whole production time
        let numberOfDaysTried = 0
        let maxDaysToTry = 75

        // loop day per day and look for the first opening hours block which is big enough to accommodate the production Time
        // either the complete production time or if its allowed slice it down
        hoursLoop: while (numberOfDaysTried < maxDaysToTry) {
            let openingHours = companyServiceInstance.getOpeningHoursForDay(loopedDate)

            if (openingHours && openingHours.length > 0) {
                for (let range of openingHours) {

                    let startTime = dayjs(range.startDate)
                    let endTime = dayjs(range.endDate)

                    // as we are getting the opening hours of the whole day, which might be today
                    // we have to check if the end and/or start of the opening hour range is in the past
                    if (startTime.isBefore(now.value)) {
                        if (endTime.isBefore(now.value)) {
                            continue
                        } else {
                            startTime = dayjs(now.value)
                        }
                    }
                    //the amount of available time in the opening slot
                    let availableMinutes = endTime.diff(startTime, 'minutes')

                    //the amount of production time we still need to fit somewhere in
                    let stillNeededMinutes = productionTimeInMinutes - accountedForMinutes;

                    if (availableMinutes > stillNeededMinutes) {
                        firstPossibleDate = startTime.add(stillNeededMinutes, 'minutes')
                        break hoursLoop
                    }


                    // if we are allowed to split the time into different production time ranges
                    if (product.productionTimeIsSplittable) {
                        accountedForMinutes += availableMinutes
                    }
                }
            }

            numberOfDaysTried++
            loopedDate = loopedDate.add(1, 'day')
        }

    } else {

        //we don't have to fit it into opening hours: just add the required time to now
        firstPossibleDate = dayjs().add(productionTimeInMinutes, 'minutes');
    }

    // console.log('firstPossibleDate', firstPossibleDate)

    return firstPossibleDate || null
}



/** 
 * returns the first possible date for pickup or delivery (depending on the state)
 * 
 * at first it calculates the first pickupdate for each item. 
 * the farthest individual pickupdate of all items is the earliest pickupdate of the order
 * 
 * If the user selected delivery it adds the expected delivery time of the selected delivery zone.
 * 
 */
const firstAllowedPickupOrDeliveryDate = computed(() => {
    let items = []
    let copiedItemsByCourse = JSON.parse(JSON.stringify(state.itemsByCourse));

    for (let courseId in copiedItemsByCourse) {
        items = items.concat(copiedItemsByCourse[courseId]);
    }
    if (!items || items.length == 0) {
        return null
    }
    let pickupDates = {}

    for (let itemId in items) {
        let item = items[itemId]
        let firstPickupDate = firstAllowedPickupOrDeliveryDateForItem(item)
        if (firstPickupDate) {
            pickupDates[itemId] = firstPickupDate
        } else {
            console.error("Couldn't find a pickupdate for item", item)
            return null
        }
    }

    // console.log('pickupDates',pickupDates)
    let dates = Object.values(pickupDates)
    dates.sort((a, b) => b - a)

    let date = dayjs(dates[0])

    if (
        state.deliveryType == 'delivery' &&
        applicableDeliveryZone.value
    ) {

        let deliveryTimeConfig = applicableDeliveryZone.value && applicableDeliveryZone.value.zone && applicableDeliveryZone.value.zone.averageDeliveryTime ? applicableDeliveryZone.value.zone.averageDeliveryTime : null
        let deliveryTimeUnit = deliveryTimeConfig ? deliveryTimeConfig.unit : 'days'
        let deliveryTime = deliveryTimeConfig && deliveryTimeConfig.value ? Number.parseFloat(deliveryTimeConfig.value) : 0
        let deliveryTimeInMinutes = deliveryTime ? (deliveryTime * ((deliveryTimeUnit == 'days') ? 24 * 60 : (deliveryTimeUnit == 'hours') ? 60 : 1)) : null

        if (deliveryTimeInMinutes && deliveryTimeInMinutes > 0) {
            date = date.add(deliveryTimeInMinutes, 'minutes')
        }
    }


    //look for the first opening/delivery timerange
    let isDelivery = state.deliveryType == 'delivery'
    let numberOfDaysTried = 0
    let maxDaysToTry = 75
    let loopedDate = dayjs(date)
    let firstPossibleDateThatsWithinOpeningHours = null


    //loop through future slots to determine the earliest date within the opening hours
    hoursLoop: while (numberOfDaysTried < maxDaysToTry) {        
        let availableSlots = getSlotsForDay(loopedDate)

        
        for(let slot of availableSlots){
            // console.log('slot.start', slot.start)
            // console.log('slot.end', slot.end)
            
            //the date is within opening hours: return the date
            if(date.isSameOrAfter(slot.start) && date.isBefore(slot.end)){
                // console.log('befoe after')
                firstPossibleDateThatsWithinOpeningHours = date
                break hoursLoop
            }
            
            //return the first slot start time that's after the date
            if(date.isSameOrBefore(slot.start)){
                // console.log(' after')
                firstPossibleDateThatsWithinOpeningHours = slot.start
                break hoursLoop
            }
        }        
        numberOfDaysTried++
        loopedDate = loopedDate.add(1, 'day')
    }





    return firstPossibleDateThatsWithinOpeningHours
})



const deliveryCosts = computed(() => {
    return applicableDeliveryZone.value && applicableDeliveryZone.value.price ? applicableDeliveryZone.value.price : 0.0
})

const totalCartWeight = computed(() => {


    let weight = 0.0
    for (let itemId in state.items) {
        let item = state.items[itemId];

        let product = companyServiceInstance.getProductById(item.productId)
        
        
        if (!product) {
            continue;
        }
        let productWeight = product.weight ? Number.parseFloat(product.weight) : 0.0
        let quantity = item.quantity ? Number.parseInt(item.quantity) : 0        
        weight += productWeight * quantity
    }
    return weight
})


const applicableDeliveryZone = computed(() => {
    if (state.deliveryType != 'delivery') {
        return null
    }

    if (!state.address || !state.address.country || !state.address.zip) {
        return null
    }
    let company = companyServiceInstance.company.value;
    if (!company || !company.deliveryZones) {
        console.error('applicableDeliveryZone: No Company or no deliveryzones found', company)
        return null
    }

    

    let country = state.address.country
    if (!country) {
        console.error('applicableDeliveryZone: No country code found', state.address)
        return null
    }
    let zip = state.address.zip
    if (!zip) {
        console.error('applicableDeliveryZone: No zip code found', state.address)
        return null
    }
    let applicableZonesForCountryAndZip = []
    

    //find all zones which match the country and zip settings
    for (let zone of company.deliveryZones) {
        if (zone.countries) {
            for (let countryRule of zone.countries) {
                if (countryRule.code == country) {

                    if (countryRule.validInZipCodesRule == 'all') {
                        applicableZonesForCountryAndZip.push(zone)
                        continue
                    }

                    let cleanupZip = (str) => {
                        return str.trim().replaceAll(' ', '')
                    }
                    let cleanedUpZips = null
                    if (countryRule.validInZipCodesConfig) {
                        cleanedUpZips = ('' + countryRule.validInZipCodesConfig).split(',').map(cleanupZip)
                    }
                    zip = cleanupZip(zip)

                    let configIncludesZip = false
                    for (let zipTemplate of cleanedUpZips) {


                        // console.log('zipTemplate', zipTemplate)

                        //definitly right
                        if (zipTemplate == zip) {
                            configIncludesZip = true
                            break
                        }
                        //definitly wrong
                        if (zipTemplate.length != zip.length) {
                            continue
                        }

                        let patternMatched = true
                        for (let i = 0; i < zipTemplate.length; i++) {
                            let zipCharAtThisPos = zip.charAt(i)
                            let templateCharAtThisPos = zipTemplate.charAt(i)
                            if (zipCharAtThisPos != templateCharAtThisPos && templateCharAtThisPos != '*') {
                                patternMatched = false
                                break
                            }
                        }
                        if (patternMatched) {
                            configIncludesZip = true
                            break
                        }
                    }

                    if (countryRule.validInZipCodesRule == 'only' && configIncludesZip) {
                        applicableZonesForCountryAndZip.push(zone)
                    }

                    if (countryRule.validInZipCodesRule == 'except' && !configIncludesZip) {
                        applicableZonesForCountryAndZip.push(zone)
                    }
                }
            }
        }
    }
    

    //if we found some delivery-zones select the cheapest
    let selectedZone = null
    let cheapestPrice = null

    for (let zone of applicableZonesForCountryAndZip) {
        let price = 0
        let priceOfZone = null
        let lowestWeightWhichCoversTheCart = null

        
        if (zone.prices) {
            for (let priceBracket of zone.prices) {                            

                //the pricebracket has no  valid price
                if((priceBracket.price == null ||   priceBracket.price == undefined || isNaN(Number.parseFloat(priceBracket.price)) )){
                    continue
                }
                                
                if(
                    (
                    priceBracket.maxWeight >= totalCartWeight.value ||
                    !priceBracket.maxWeight 
                    ) &&
                    (
                        lowestWeightWhichCoversTheCart == null ||                         
                        priceBracket.maxWeight < lowestWeightWhichCoversTheCart
                    )
                ){
                    lowestWeightWhichCoversTheCart = priceBracket.maxWeight
                    priceOfZone = Number.parseFloat(priceBracket.price)
                }

                // if ((!priceBracket.maxWeight || (priceBracket.maxWeight >= totalCartWeight.value)) && (priceOfZone == null || Number.parseFloat(priceBracket.price) < priceOfZone)) {
                //     priceOfZone = Number.parseFloat(priceBracket.price)
                // }
            }                        
        }

        //its the first zone
        if(selectedZone == null){
            selectedZone = zone
            cheapestPrice = priceOfZone
            continue
        }        

        if (priceOfZone < cheapestPrice) {
            cheapestPrice = priceOfZone
            selectedZone = zone
        }

    }
    console.log('{ zone: selectedZone, price: cheapestPrice }',{ zone: selectedZone, price: cheapestPrice })

    return selectedZone ? { zone: selectedZone, price: cheapestPrice } : null
})

export default function (reInit) {
    // console.log('cartService', state.initialized ,state.initializing)

    if (reInit) {
        state.initialized = false;
        state.initializing = false;
    }

    if (!state.address) {
        state.address = {}
    }


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        let savedData = readFromLocalStorage();
        for (let key in savedData) {
            state[key] = savedData[key]
        }

        initItemsByCourse()

        state.initialized = true
        state.initializing = false

    }


    watch(companyServiceInstance.company, (company) => {

        if (!company) {
            console.log('no company!!!!')
            clear();
            window.location.replace(config.url);
            return;
        }

        let existingTagIds = company.tags && company.tags.map((tag) => tag.id);
        let cleanedActiveTags = []
        for (let activeTagId of state.activeTags) {
            if (existingTagIds && existingTagIds.includes(activeTagId)) {
                cleanedActiveTags.push(activeTagId)
            }
        }
        state.activeTags = cleanedActiveTags;


        //add any new courses to itemsByCourse
        if (company.courses) {
            for (let course of company.courses) {
                if (!state.itemsByCourse[course.id]) {
                    state.itemsByCourse[course.id] = []
                }
            }
        }

        //remove any deleted courses        
        let existingCourseIds = company.courses && company.courses.map((course) => course.id);
        if (existingCourseIds) {
            for (let courseId in state.itemsByCourse) {
                if (!existingCourseIds.includes(courseId)) {

                    //deleted couse has items in it -> move those to their respective default course
                    if (state.itemsByCourse[courseId].length > 0) {
                        for (let item of state.itemsByCourse[courseId]) {
                            let product = companyServiceInstance.getProductById(item.productId);

                            if (product && product.defaultCourseId && product.defaultCourseId != courseId && state.itemsByCourse[product.defaultCourseId]) {
                                state.itemsByCourse[product.defaultCourseId].push(item)
                            }
                        }
                    }
                    // deleted course has no items -> simple remove it
                    delete state.itemsByCourse[courseId]

                }
            }
        }


        initItemsByCourse();
    })

    watch(state, async () => {
        if (!stateTimer) {
            stateTimer = setTimeout(() => {
                writeToLocalStorage({
                    items: state.items,
                    address: state.address,
                    itemsByCourse: state.itemsByCourse,
                    tipPercentage: state.tipPercentage,
                    deliveryType: state.deliveryType,
                    pickupTimeMode: state.pickupTimeMode,
                    pickupTime: state.pickupTime,
                    note: state.note,
                    // voucher: state.voucher,
                    phone: state.phone
                })

                stateTimer = null;
            }, 500)
        }
    }, { deep: true })





    return {
        ...toRefs(state),
        changeNote,
        getQuantityForProduct,
        totalNumberOfItems,
        productsInCart,
        itemsInCart,
        clear,
        emptyTheCart,
        getCartBreakdown,
        deleteItemFromCart,
        addProductToBasket,
        getCartItemById,
        countCartItemUp,
        countCartItemDown,
        applicableDiscounts,
        localStorageKey,
        validateCart,
        getSlotsForDay,
        firstAllowedPickupOrDeliveryDate,
        applicableDeliveryZone,
        deliveryCosts,
        totalCartWeight,
    }


}