<template>
  <div class="checkout-flow-item cart-overview">
    <div class="checkout-flow-item-header">
      <h2 v-text="$t('shopping-cart-step-summary-title')"></h2>
    </div>
    <div class="checkout-flow-item-content">


      <div class="no-products-notice" v-if="totalNumberOfItems == 0" v-text="$t('shopping-cart-no-products')"></div>
      <div class="product-list">
        <cart-list-item v-for="item of itemsInCart" :key="item.id" :item="item" :show-total="true" :withNote="false"
          :hasDescription="false" :readonly="true"></cart-list-item>
      </div>

      <cost-breakdown-view :show-estimate="!pointofsale.enableCheckout" v-if="totalNumberOfItems > 0" :readonly="true"
        show-taxes="true"></cost-breakdown-view>

      <div class="summary-info general-note" v-if="note">
        <h4>{{ $t('checkout-flow-summary-note-heading') }}</h4>
        <div class="note" v-text="note"></div>
      </div>

      <div class="summary-info delivery-address" v-if="deliveryType == 'delivery' && address">
        <h4>{{ $t('checkout-flow-summary-address-heading') }}</h4>
        <div class="name" v-text="address.name"></div>
        <div class="street" v-text="address.street"></div>
        <div class="zip-city">
          <span class="zip" v-text="address.zip"></span>
          <span class="city" v-text="address.city"></span>
        </div>
        <div class="country" v-if="address.country" v-text="getCountryNameFromCountryCode(address.country)"></div>
        
      </div>

      <div class="summary-info pickup-data" v-if="deliveryType == 'takeaway' && pickupTime">
        <h4>{{ $t('checkout-flow-summary-pickup-heading') }}</h4>
        <div class="pickupTime" v-text="pickupTime"></div>
      </div>

      <div class="summary-info pickup-data" v-if="deliveryType == 'delivery' && pickupTimeMode == 'custom' && pickupTime">
        <h4>{{ $t('checkout-flow-summary-selected-delivery-time-heading') }}</h4>
        <div class="pickupTime" v-text="pickupTime"></div>
      </div>

      <div class="summary-info phone" v-if="phone && (company.sendSMSOnOrderReady || pointofsale.collectPhoneNumber)">
        <h4>{{ $t('checkout-flow-summary-phone-heading') }}</h4>
        <div class="phone" v-text="phone"></div>
      </div>
      
    </div>
    <div class="checkout-flow-item-footer" v-if="pointofsale.enableCheckout">
      <div class="flow-navigation-buttons">
        <ion-button class="back-button" color="medium" expand="block" @click="gotoPreviousStep"
          :size="frameWidth < 350 ? 'small' : 'default'">
          <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
          <span class="label">{{ $t("step-flow-back-button-label") }}</span>
        </ion-button>
        <ion-button class="next-button" :disabled="totalNumberOfItems == 0" expand="block" @click="gotoPayment"
          :size="frameWidth < 350 ? 'small' : 'default'">
          <ion-spinner v-if="creatingPaymentIntent" slot="icon-only"></ion-spinner>
          <span v-if="!creatingPaymentIntent" class="label">{{ $t("step-flow-cart-details-next-button-label") }}</span>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import CartListItem from "./CartListItem.vue";
import CostBreakdownView from "./CostBreakdownView.vue";
import { IonButton, IonIcon, IonSpinner } from "@ionic/vue";
import { chevronBackOutline } from "ionicons/icons";
import dayjs from "dayjs";
import {
  getCountryNameFromCountryCode
} from "country-codes-flags-phone-codes";


</script>
<script>
export default {
  props: ['step'],
  data() {
    return {
      creatingPaymentIntent: false
    };
  },
  computed: {
    pickupTime() {
      if (!this.services.cartService.pickupTime || !this.services.cartService.pickupTime.value) {
        return null
      }      
      
      return this.services.cartService.pickupTime ? dayjs(this.services.cartService.pickupTime.value).tz(this.company.timezone).format("DD.MM.YYYY - HH:mm") : null
    },
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    itemsInCart() {
      return this.services.cartService.itemsInCart();
    },
    deliveryType() {
      return this.services.cartService.deliveryType ? this.services.cartService.deliveryType.value : null
    },
    pickupTimeMode() {
      return this.services.cartService.pickupTimeMode ? this.services.cartService.pickupTimeMode.value : null
    },
    address() {
      return this.services.cartService.address ? this.services.cartService.address.value : null
    },
    note() {
      return this.services.cartService.note ? this.services.cartService.note.value : null
    },
    phone() {
      return this.services.cartService.phone ? this.services.cartService.phone.value : null
    }
  },
  methods: {
    gotoPreviousStep() {
      this.$emit("previous");
    },
    async gotoPayment() {

      this.services.cartService.validateCart();
      if (this.services.cartService.errors.value.pickupTime) {
        alert(this.$t('order-validation-pickup-time-outdated-alert'))
        this.services.cartService.pickupTime.value = null

      }
      
      let checkoutFlowMap = [
        {
          name: 'cart-details',
          fields: 'pickupTime'
        }
      ]

      if (this.services.cartService.errors.value && Object.keys(this.services.cartService.errors.value).length > 0) {

        let firstStepWithError = null
        for (let step of checkoutFlowMap) {
          for (let field in this.services.cartService.errors.value) {
            if (step.fields.includes(field)) {
              firstStepWithError = step
              break
            }
          }
          if (firstStepWithError) {
            break
          }
        }        
        let targetStepName = firstStepWithError ? firstStepWithError.name : 'cart-overview'
        this.$router.push({ name: "menu-cart", params: { ...this.$route.params, step: targetStepName } });
        
        return
      }
      
      try {
        this.creatingPaymentIntent = true;
        let stripeURL = await this.services.payments.createPaymentIntent(this.pointofsale.id);
        this.creatingPaymentIntent = false;
        if (stripeURL) {
          window.location.replace(stripeURL);
        }
      } catch (error) {
        this.creatingPaymentIntent = false;
        console.log(error);
        if (error.message == 'company-is-closed') {
          const startpageRoute = this.$router.resolve({ name: "menu", params: this.$route.params });
          window.location.replace(startpageRoute.fullPath);
        }
      }

    }
  },
};
</script>
<style scoped>
.product-list-item:last-child {
  border-bottom: 1px solid var(--ion-color-dark-shade);
  margin-bottom: 1rem;
}

.summary-info h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.15rem;
}

.summary-info {
  text-align: right;
}

.summary-info.delivery-address .city {
  margin-left: 0.3rem;
}
</style>