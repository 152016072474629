<template>
  <div class="cost-breakdown-view " :class="{ 'show-taxes': showTaxes }">
    <div class="taxes-and-totals delivery-costs" v-if="services.cartService.deliveryType.value == 'delivery' && cartBreakdown.deliveryCosts">
      <div class="line">
        <span class="label"
          v-text="$t('shopping-cart-delivery-costs-label') + cartWeightAsString + ':'"></span>
          <span class="value">
          {{ cartBreakdown.deliveryCosts ?  Number.parseFloat(cartBreakdown.deliveryCosts).toFixed(2) : '' }}
          <span v-if="cartBreakdown.deliveryCosts" class="currency-symbol" v-text="currencySymbol"></span>
          </span>
      </div>

    </div>
    <div class="taxes-and-totals" v-if="showEstimate">
      <div class="total-line line">
        <span class="label" v-text="$t('shopping-cart-estimated-total-label') + ':'"></span><span class="value">{{
          Number.parseFloat(cartBreakdown.subtotal).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>
    </div>


    <div class="taxes-and-totals" v-if="!showEstimate">

      <div class="discount-selector-line line"
        v-if="pointofsale && pointofsale.showDiscounts && company.discounts && company.discounts.length > 0 && !readonly">
        <discount-selector v-model="services.cartService.discounts.value"></discount-selector>
      </div>
      <div class="voucher-line line" v-if="!readonly && pointofsale && pointofsale.enableVouchers && !voucherDiscount">
        <span class="label" v-text="$t('shopping-cart-voucher-label') + ':'"></span>
        <span class="value">
          <input type="text" name="voucher" ref="voucherInput"
            :placeholder="$t('step-flow-cart-details-voucher-placeholder')" v-model="voucherCode">
          <ion-icon v-if="!checkingVoucher" :class="{ disabled: !Boolean(voucherCode) }" class="add-voucher-button"
            @click="checkVoucher" :icon="addCircleOutline"></ion-icon>
          <ion-spinner v-if="checkingVoucher"></ion-spinner>
        </span>

        <div class="error" v-if="errors && errors.voucher" v-text="errors.voucher"></div>
      </div>
      <div class="discounts-line line" v-if="applicableDiscounts && applicableDiscounts.length > 0">

        <div class="discount" v-for="discount of applicableDiscounts" :key="discount">

          <span class="label discount"
            v-text="services.translations.translation2Text(discount.name) + ' (-' + discount.percentage + '%):'"></span>
          <span class="value discount" v-if="discount">- {{
            Number.parseFloat(cartBreakdown.rebates[discount.id]).toFixed(2) }}<span class="currency-symbol"
              v-text="currencySymbol"></span></span>
        </div>

      </div>



      <div class="subtotal-line line" v-if="showTaxes">
        <span class="label" v-text="$t('shopping-cart-subtotal-label') + ':'"></span><span class="value">{{
          Number.parseFloat(cartBreakdown.subtotal).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>

      <div class="tip-percentage-line  above line"
        v-if="!readonly && company && company.tipIsEnabled && company.tipIsTaxed">
        <span class="label" v-text="$t('shopping-cart-tip-label') + ':'"></span>
        <span class="value">
          <ion-select :selectedText="tipPercentage + '%'" :value="tipPercentage" @ionChange="tipPercentageChanged"
            interface="default">
            <ion-select-option v-for="percentage of tipPercentageRecommandations" :key="percentage" :value="percentage">
              <span class="percentage">{{ percentage }}%</span>:
              <span class="value">{{ Number.parseFloat((Number.parseFloat(cartBreakdown.subtotal) / 100) *
                percentage).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span></span>
            </ion-select-option>
          </ion-select>
        </span>
      </div>
      <div class="tip-amount-line above line" v-if="company && company.tipIsEnabled && company.tipIsTaxed">
        <span class="label" v-text="$t('shopping-cart-tipamount-label') + ':'"></span><span class="value">{{
          Number.parseFloat(cartBreakdown.tip).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>

      <div class="line" v-if="showTaxes">
        <span class="label" v-text="$t('shopping-cart-overview-net-amount-label') + ':'"></span>
        <span class="value">{{ Number.parseFloat(cartBreakdown.netAmount).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>
      <div class="vat-breakdown" v-if="showTaxes">

        <div class="vat-line line" v-for="(value, rate) in cartBreakdown.vatByRate" :key="rate">
          <span class="label" v-text="$t('shopping-cart-overview-vat-label') + ' (' + rate + '%):'"></span>
          <span class="value">{{ Number.parseFloat(value).toFixed(2) }}<span class="currency-symbol"
              v-text="currencySymbol"></span></span>
        </div>
      </div>
      <div class="total-line line" v-if="company && company.tipIsEnabled && company.tipIsTaxed">
        <span class="label" v-text="$t('shopping-cart-overview-total-label') + ':'"></span>
        <span class="value">{{ Number.parseFloat(cartBreakdown.grossAmount).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>


      <div class="tip-percentage-line below line"
        v-if="!readonly && company && company.tipIsEnabled && !company.tipIsTaxed">
        <span class="label" v-text="$t('shopping-cart-tip-label') + ':'"></span>
        <span class="value">
          <ion-select :selectedText="tipPercentage + '%'" :value="tipPercentage" @ionChange="tipPercentageChanged"
            interface="default">
            <ion-select-option v-for="percentage of tipPercentageRecommandations" :key="percentage" :value="percentage">
              <span class="percentage">{{ percentage }}%</span>:
              <span class="value">{{ Number.parseFloat((Number.parseFloat(cartBreakdown.subtotal) / 100) *
                percentage).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span></span>
            </ion-select-option>
          </ion-select>
        </span>
      </div>


      <div class="tip-amount-line below line" v-if="company && company.tipIsEnabled && !company.tipIsTaxed">
        <span class="label" v-text="$t('shopping-cart-tipamount-label') + ':'"></span><span class="value">{{
          Number.parseFloat(cartBreakdown.tip).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>



      <div class="total-line line" v-if="company && company.tipIsEnabled && !company.tipIsTaxed">
        <span class="label" v-text="$t('shopping-cart-overview-total-with-tip-label') + ':'"></span><span
          class="value">{{
            Number.parseFloat(cartBreakdown.total).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>

      <div class="total-line line" v-if="company && !company.tipIsEnabled">
        <span class="label" v-text="$t('shopping-cart-overview-total-label') + ':'"></span><span class="value">{{
          Number.parseFloat(cartBreakdown.total).toFixed(2) }}<span class="currency-symbol"
            v-text="currencySymbol"></span></span>
      </div>

    </div>
  </div>
</template>

<script setup>

import { IonSelect, IonSelectOption, IonIcon, IonSpinner } from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import axios from "axios";
import config from "../../config"
import DiscountSelector from "./DiscountSelector.vue"
</script>
<script>
export default {
  props: ["readonly", "showEstimate", "showTaxes"],
  data() {
    return {
      voucherCode: null,
      checkingVoucher: false,
      errors: {},
    };
  },
  computed: {
    cartWeightAsString(){
      let weight = this.services.cartService.totalCartWeight.value  ? this.services.cartService.totalCartWeight.value : 0       
      return weight > 0 ?  ' (' + Number.parseFloat(weight).toFixed(1) + ' kg)' : ''
    },
    tipPercentageRecommandations() {
      return [0, 5, 10, 15, 20];
    },
    tipPercentage() {
      return this.services.cartService.tipPercentage ? this.services.cartService.tipPercentage.value : 0;
    },
    voucherDiscount() {
      return this.services.cartService.voucherDiscount ? this.services.cartService.voucherDiscount.value : null;
    },
    discounts() {
      return this.services.cartService.discounts ? this.services.cartService.discounts.value : [];
    },
    applicableDiscounts() {
      return this.services.cartService.applicableDiscounts ? this.services.cartService.applicableDiscounts.value : []
    },
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    productsInCart() {
      return this.services.cartService.productsInCart();
    },
    itemsInCart() {
      return this.services.cartService.itemsInCart();
    },
    cartBreakdown() {
      return this.services.cartService.getCartBreakdown();
    },
  },
  async mounted() { },
  methods: {
    tipPercentageChanged(e) {
      let value = e.detail && e.detail.value ? e.detail.value : 0;
      if (this.tipPercentage != value) {
        this.services.cartService.tipPercentage.value = value;
      }
    },
    async checkVoucher() {
      if (!this.voucherCode) {
        return
      }
      this.services.cartService.voucher.value = null
      this.errors = {}
      this.checkingVoucher = true;
      let data = {
        voucherCode: this.voucherCode,
        zoneId: this.pointofsale.zoneId
      }
      try {
        let response = await axios.post(config.apiUrl + "/api/vouchers/checkVoucherCode", data, {});
        this.services.cartService.voucher.value = this.voucherCode
        this.services.cartService.voucherDiscount.value = response.data.discount

      } catch (error) {
        console.log(error)
        this.errors.voucher = this.$t('error-invalid-voucher')
      }
      this.checkingVoucher = false;

      //services.cartService.voucher.value

    }
  },
};
</script>
<style scoped>
.taxes-and-totals {
  display: flex;
  align-items: flex-end;
  margin-top: -0.5rem;
  flex-direction: column;
}

.taxes-and-totals .line {
  margin-bottom: 0.5rem;
  text-align: right;
  min-width: 18rem;
}

@media (max-width: 450px) {
  .taxes-and-totals .line {
    min-width: auto;
    width: 100%;
  }
}

.taxes-and-totals .value {
  margin-left: 0.5rem;
  min-width: 6rem;
  display: inline-block;
}

.label.discount,
.value.discount {
  color: var(--ion-color-success-shade);
  font-weight: 599;
}

.taxes-and-totals .total-line {
  font-weight: 800;
  border-top: 1px solid var(--ion-color-dark);
  border-bottom: 2px solid var(--ion-color-dark);
  margin-top: 0.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
}

/* .taxes-and-totals .total-without-tip{
  font-weight: 800;
  border-top: 1px solid var(--ion-color-dark);
  
  margin-top: 0.2rem;
  padding-top: 0.3rem;
  margin-bottom: 0.2rem;
  
} */
.taxes-and-totals .subtotal-line {
  border-bottom: 1px solid var(--ion-color-dark);
  border-top: none;
  font-weight: 500;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.discount-selector-line+.subtotal-line {
  border-top: 1px solid var(--ion-color-dark);
  padding-top: 0.5rem;

}


.taxes-and-totals .tip-percentage-line {
  margin-bottom: -0.2rem;
  margin-top: -0.3rem;
}

.show-taxes .taxes-and-totals .tip-percentage-line.below {
  border-top: 1px solid var(--ion-color-dark);
  margin-top: 0.3rem;
}

.taxes-and-totals .tip-amount-line {
  border-top: none;
  border-bottom: none;
  font-weight: 500;
}

.taxes-and-totals .tip-amount-line.above {
  border-bottom: 1px solid var(--ion-color-dark);
  border-top: none;
  font-weight: 500;
  padding-bottom: 0.7rem;
  margin-bottom: 0.7rem;
}

.taxes-and-totals.delivery-costs .line {
  border-top: 1px solid var(--ion-color-dark) !important;
  border-bottom: 1px solid var(--ion-color-dark);
  border-top: none;
  font-weight: 500;
  padding-top: 0.6rem;
  padding-bottom: 0.65rem;
  margin-bottom: 1.1rem;
}

.ion-select-option {
  text-align: right;
}

.voucher-line input {
  width: 4rem;
  text-align: right;
  border: 1px solid var(--ion-color-medium);
  margin-right: 0.3rem;
  font-size: 0.8rem;
  background-color: white;
}


.discount-selector-line {
  border-top: 1px solid var(--ion-color-dark);
  padding-top: 0.5rem;
}

.discounts-line {
  border-top: 1px solid var(--ion-color-dark);
  border-bottom: 1px solid var(--ion-color-dark);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.discounts-line .discount {
  margin-top: 0.1rem;
}

.discounts-line .discount:first-child {
  margin-top: 0;
}

.voucher-line+.discounts-line {
  border-top: 0;
  padding-top: 0;
}

.voucher-line {
  border-top: 1px solid var(--ion-color-dark);
  border-bottom: 1px solid var(--ion-color-dark);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.voucher-line .add-voucher-button {
  font-size: 1.4rem;
  margin-bottom: -0.35rem;
  margin-right: 0.1rem;
}

.voucher-line .add-voucher-button.disabled {
  color: var(--ion-color-medium)
}

.voucher-line ion-spinner {
  width: 1rem;
  margin-left: 0.4rem;
  margin-bottom: -0.5rem;
  margin-right: 0.1rem;
}

.voucher-line .error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
</style>