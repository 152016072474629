<template>
  <div class="checkout-flow-item order-details">
    <div class="checkout-flow-item-header">
      <h2 v-text="$t('step-flow-cart-details-title')"></h2>
    </div>
    <div class="checkout-flow-item-content">
      <div class="order-options-notice rtf-note" v-if="orderOptionsNotice && orderOptionsNotice.length > 0"
        v-html="orderOptionsNotice"></div>
      <section class="contact-details step-section"
        v-if="company.sendSMSOnOrderReady || pointofsale.collectPhoneNumber">
        <h2 class="step-section-header" v-if="false" v-text="$t('step-flow-cart-details-contact-details-header')"></h2>
        <oido-phone-input :label="$t('step-flow-cart-details-phone-input-label')"
          v-model="services.cartService.phone.value" :errors="errors.phone"
          :default-country="company && company.country ? ('' + company.country).toUpperCase() : 'ES'"></oido-phone-input>
      </section>


      <section class="choose-details step-section"
        v-if="availableDeliveryOptions && availableDeliveryOptions.length > 1">

        <ion-radio-group class="delivery-type-group" v-model="services.cartService.deliveryType.value">
          <ion-list-header>
            <ion-label v-text="$t('step-flow-cart-details-delivery-type-label')"></ion-label>
          </ion-list-header>

          <ion-item lines="full" v-if="pointofsale.enableTableService">
            <ion-radio mode="md" slot="start" value="table" label-placement="end" :alignment="'center'" justify="start"
              v-text="$t('step-flow-cart-details-is-table-label')"></ion-radio>
          </ion-item>

          <ion-item lines="full" v-if="pointofsale.enablePickup">
            <ion-radio mode="md" slot="start" value="takeaway" label-placement="end" :alignment="'center'"
              justify="start" v-text="$t('step-flow-cart-details-is-takeaway-label')"></ion-radio>
          </ion-item>

          <ion-item lines="full" v-if="pointofsale.enableDelivery">
            <ion-radio mode="md" slot="start" value="delivery" label-placement="end" :alignment="'center'"
              justify="start" v-text="$t('step-flow-cart-details-is-delivery-label')"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </section>


      <section v-if="services.cartService.deliveryType.value == 'delivery'"
        class="details-section delivery  step-section address-section  ">
        <h2 class="step-section-header" v-text="$t('step-flow-cart-details-address-header')"></h2>

        <div class="input-line">

          <div class="validation-errors" v-if="errors && errors.country">
            <span class="validation-error" v-for="error of errors.country" v-text="error" :key="error"></span>
          </div>

          <OidoCountrySelector v-if="services.cartService.address.value"
            v-model="services.cartService.address.value.country"
            :class="{ 'has-errors': errors && (errors.country || errors.global) }"
            :label="$t('step-flow-cart-details-address-country-label')"></OidoCountrySelector>

        </div>
        <div class="input-line">
          <div class="validation-errors" v-if="errors && errors.name">
            <span class="validation-error" v-for="error of errors.name" v-text="error" :key="error"></span>
          </div>

          <ion-input :label="$t('step-flow-cart-details-address-name-label')" label-placement="floating"
            v-if="services.cartService.address.value" name="name" type="text"
            :placeholder="$t('step-flow-cart-details-address-name-placeholder')"
            v-model="services.cartService.address.value.name" :class="{
              'has-errors': errors && (errors.name || errors.global),
            }" />

        </div>

        <div class="input-line">

          <div class="validation-errors" v-if="errors && errors.street">
            <span class="validation-error" v-for="error of errors.street" v-text="error" :key="error"></span>
          </div>

          <ion-input :label="$t('step-flow-cart-details-address-street-label')" label-placement="floating"
            v-if="services.cartService.address.value" name="street" type="text"
            :placeholder="$t('step-flow-cart-details-address-street-placeholder')"
            v-model="services.cartService.address.value.street" :class="{
              'has-errors': errors && (errors.street || errors.global),
            }" />

        </div>

        <div class="input-line">
          <div class="validation-errors" v-if="errors && errors.zip">
            <span class="validation-error" v-for="error of errors.zip" v-text="error" :key="error"></span>
          </div>
          <ion-input :label="$t('step-flow-cart-details-address-zip-label')" label-placement="floating"
            v-if="services.cartService.address.value" name="zip" type="text"
            :placeholder="$t('step-flow-cart-details-address-zip-placeholder')"
            v-model="services.cartService.address.value.zip" :class="{
              'has-errors': errors && (errors.zip || errors.global),
            }" />
        </div>

        <div class="input-line">
          <div class="validation-errors" v-if="errors && errors.city">
            <span class="validation-error" v-for="error of errors.city" v-text="error" :key="error"></span>
          </div>
          <ion-input :label="$t('step-flow-cart-details-address-city-label')" label-placement="floating"
            v-if="services.cartService.address.value" name="city" type="text"
            :placeholder="$t('step-flow-cart-details-address-city-placeholder')"
            v-model="services.cartService.address.value.city" :class="{
              'has-errors': errors && (errors.zip || errors.global),
            }" />
        </div>


      </section>

      <div class="no-delivery-possible-warning" v-if="addressIsComplete && isDelivery && !deliveryIsPossible"
        v-text="$t('step-flow-cart-details-delivery-not-possible-error')"></div>


      <section v-if="
        (services.cartService.deliveryType.value == 'takeaway' && pointofsale.allowCustomPickupTime) ||
        (services.cartService.deliveryType.value == 'delivery' && allowCustomDeliveryTime)"
        class="details-section takeaway step-section">

        <h2 class="step-section-header" v-if="services.cartService.deliveryType.value == 'takeaway'"
          v-text="$t('step-flow-cart-details-takeaway-header')"></h2>
        <h2 class="step-section-header" v-if="services.cartService.deliveryType.value == 'delivery'"
          v-text="$t('step-flow-cart-details-delivery-header')"></h2>


        <ion-radio-group v-model="services.cartService.pickupTimeMode.value">

          <ion-item :lines="showCustomPickupTimeSelector ? 'full' : 'none'"
            :disabled="!firstAllowedPickupOrDeliveryDate">

            <ion-radio mode="md" slot="start"
              :disabled="!firstAllowedPickupOrDeliveryDate || (isDelivery && !deliveryIsPossible)"
              value="assoonaspossible" label-placement="end" :alignment="'center'" justify="start"
              v-text="$t('step-flow-cart-details-takeaway-time-assoonaspossible-label') + (durationInMinutes > 0 ? ` ( ${$t(services.cartService.deliveryType.value == 'delivery' ? 'checkout-details-delivery-expect-in' : 'checkout-details-pickup-ready-in')} ${manufacturingTimeLabel} )` : '')"></ion-radio>

          </ion-item>

          <ion-item lines="full" v-if="showCustomPickupTimeSelector">
            <ion-radio mode="md" slot="start" value="custom" label-placement="end" :alignment="'center'" justify="start"
              :disabled="isDelivery && !deliveryIsPossible">
              <span v-text="$t('step-flow-cart-details-takeaway-time-custom-label')"></span>
              <span class="selected-pickup-date" v-text="formattedPickupTime"></span>
              <span class="validation-errors" v-if="errors && errors.pickupTime">
                <span class="validation-error" v-for="error of errors.pickupTime" v-text="error" :key="error"></span>
              </span>
            </ion-radio>
          </ion-item>
        </ion-radio-group>

        <div class="custom-pickup--time" v-if="services.cartService.pickupTimeMode.value == 'custom'">
          <oido-slotted-date-picker v-model="services.cartService.pickupTime.value"
            :disabled="isDelivery && !deliveryIsPossible"></oido-slotted-date-picker>
        </div>


      </section>




      <ion-item lines="full" v-if="pointofsale.enableGeneralNote">

        <ion-textarea class="note" :label="$t('step-flow-cart-details-note-label')" label-placement="floating"
          v-model="services.cartService.note.value"></ion-textarea>

      </ion-item>
    </div>

    <div class="checkout-flow-item-footer">
      <div class="flow-navigation-buttons">
        <ion-button class="back-button" color="medium" expand="block" @click="gotoPreviousStep"
          :size="frameWidth < 350 ? 'small' : 'default'">
          <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
          <span class="label">{{ $t("step-flow-back-button-label") }}</span>
        </ion-button>
        <ion-button class="next-button"
          :disabled="totalNumberOfItems == 0 || (isDelivery && (!addressIsComplete || !deliveryIsPossible))"
          expand="block" @click="gotoNextStep" :size="frameWidth < 350 ? 'small' : 'default'">
          <span class="label">{{ $t("step-flow-cart-notes-next-button-label") }}</span>
          <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  IonButton,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonListHeader,
  IonTextarea,
  IonInput
} from "@ionic/vue";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import dayjs from "dayjs";
import OidoPhoneInput from "../components/OidoPhoneInput.vue";
import OidoSlottedDatePicker from "../components/OidoSlottedDatePicker.vue"
import Validator from "validatorjs";
import parsePhoneNumber from 'libphonenumber-js'
import OidoCountrySelector from "../components/OidoCountrySelector.vue";
</script>

<script>

export default {
  props: ['step'],
  data() {
    return {
      errors: {}
    };
  },
  watch: {
    $route: function () {
      this.cleanupState()
    },
    pickupTimeMode: function () {
      //clear pickuptime when we switch to as soon as possible
      if (this.pickupTimeMode == 'assoonaspossible') {
        this.services.cartService.pickupTime.value = null
      }
    },
    deliveryType: function () {
      //clear pickuptime when we change delivery type      
      this.services.cartService.pickupTime.value = null
      this.services.cartService.pickupTimeMode.value = 'assoonaspossible'
    },
    formattedPickupTime() {
      this.errors.pickupTime = null
    },
    firstAllowedPickupOrDeliveryDate() {
      this.cleanupState()
    }
  },
  computed: {
    pickupTimeMode() {
      return this.services.cartService.pickupTimeMode ? this.services.cartService.pickupTimeMode.value : 'assoonaspossible'
    },
    formattedPickupTime() {
      let pickupTime = this.services.cartService.pickupTime ? this.services.cartService.pickupTime.value : null
      if (!pickupTime) {
        return ''
      }
      return dayjs(pickupTime).format('DD. MM. YYYY HH:mm')
    },
    firstAllowedPickupOrDeliveryDate() {
      return this.services.cartService.firstAllowedPickupOrDeliveryDate.value
    },
    selectedPickupDate() {
      return this.services.cartService.pickupTime.value
    },
    durationInMinutes() {
      return this.firstAllowedPickupOrDeliveryDate ? this.firstAllowedPickupOrDeliveryDate.diff(dayjs(), 'minutes') + 1 : null
    },
    manufacturingTimeLabel() {
      if (!this.durationInMinutes) {
        return ''
      }
      if (this.durationInMinutes < 48 * 60) {
        let label = ''
        let days = Math.floor(this.durationInMinutes / (24 * 60))
        let hours = Math.floor(this.durationInMinutes / 60) % 24
        let minutes = Math.floor(this.durationInMinutes % 60)

        if (days > 0) {
          label = label + days + ' ' + (days == 1 ? this.$t('order-item-manufacturing-time-day') : this.$t('order-item-manufacturing-time-days')) + ' '
        }
        if (hours > 0) {
          label = label + hours + ' ' + (hours == 1 ? this.$t('order-item-manufacturing-time-hour') : this.$t('order-item-manufacturing-time-hours')) + ' '
        }
        if (minutes > 0) {
          label = label + minutes + ' ' + (minutes == 1 ? this.$t('order-item-manufacturing-time-minute') : this.$t('order-item-manufacturing-time-minutes')) + ' '
        }
        return label
      }

      return Math.ceil(this.durationInMinutes / (24 * 60)) + ' ' + this.$t('order-item-manufacturing-time-days')
    },
    deliveryType() {
      return this.services.cartService.deliveryType ? this.services.cartService.deliveryType.value : null
    },
    orderOptionsNotice() {
      if (this.pointofsale && this.pointofsale.orderOptionsNotice) {
        let text = this.services.translations.translation2Text(this.pointofsale.orderOptionsNotice);
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      return null
    },
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    pointofsaleId() {
      return this.$route.params["pointofsaleId"] ? this.$route.params["pointofsaleId"] : null;
    },
    showCustomPickupTimeSelector() {
      return this.pointofsale.allowCustomPickupTime
    },
    allowCustomDeliveryTime() {
      return Boolean(this.services.cartService.applicableDeliveryZone.value &&
        this.services.cartService.applicableDeliveryZone.value.zone &&
        this.services.cartService.applicableDeliveryZone.value.zone.allowCustomDeliveryTime)
    },
    deliveryIsPossible() {
      return Boolean(this.services.cartService.applicableDeliveryZone.value &&
        this.services.cartService.deliveryType.value == 'delivery')
    },
    isDelivery() {
      return this.services.cartService.deliveryType.value == 'delivery'
    },
    addressIsComplete() {

      let address = this.services.cartService.address.value;
      console.log('address', address)
      let rules = {}
      if (this.services.cartService.deliveryType.value == 'delivery') {
        rules.name = "required"
        rules.street = "required"
        rules.city = "required"
        rules.zip = "required"
        rules.country = "required"
      }

      let validation = new Validator(address, rules);

      let passed = validation.passes()
      if (!passed) {
        console.error('Validating Adress failed', validation.errors)
      }
      return passed
    }
  },
  async mounted() {
    this.cleanupState()
  },
  methods: {
    cleanupState() {
      //a very sloppy action when no possible pickupdate is found
      if (!this.firstAllowedPickupOrDeliveryDate) {
        this.pickupTime = null
        this.services.cartService.pickupTimeMode.value = 'custom'
      }

      if (!this.showCustomPickupTimeSelector) {
        this.services.cartService.pickupTimeMode.value = 'assoonaspossible'
      }

      //if an old pickup-date is present  which is before the firat possible date , delete it
      if (
        this.selectedPickupDate &&
        dayjs(this.selectedPickupDate).isBefore(dayjs(this.firstAllowedPickupOrDeliveryDate))
      ) {
        this.services.cartService.pickupTime.value = null
      }
    },

    gotoPreviousStep() {
      this.$emit("previous");
    },
    async gotoNextStep() {

      this.errors = {};

      let rules = {};
      let data = {};

      if (this.company.sendSMSOnOrderReady || this.pointofsale.collectPhoneNumber) {
        rules.phone = "required";
        data.phone = this.services.cartService.phone.value ? this.services.cartService.phone.value : null;
      }

      if (this.services.cartService.deliveryType.value == 'delivery') {
        rules.name = "required|min:3"
        rules.street = "required"
        rules.city = "required"
        rules.zip = "required"
        rules.country = "required"
        data.name = this.services.cartService.address.value && this.services.cartService.address.value.name ? this.services.cartService.address.value.name : null
        data.street = this.services.cartService.address.value && this.services.cartService.address.value.street ? this.services.cartService.address.value.street : null
        data.city = this.services.cartService.address.value && this.services.cartService.address.value.city ? this.services.cartService.address.value.city : null
        data.zip = this.services.cartService.address.value && this.services.cartService.address.value.zip ? this.services.cartService.address.value.zip : null
        data.country = this.services.cartService.address.value && this.services.cartService.address.value.country ? this.services.cartService.address.value.country : null
      }

      if (this.services.cartService.deliveryType.value == 'takeaway' && this.services.cartService.pickupTimeMode.value == 'custom') {
        rules.pickupTime = "required"
        data.pickupTime = this.services.cartService.pickupTime.value
      }


      let validation = new Validator(data, rules);
      validation.setAttributeNames({
        name: this.$t("step-flow-cart-details-address-name-label"),
        street: this.$t("step-flow-cart-details-address-street-label"),
        city: this.$t("step-flow-cart-details-address-city-label"),
        zip: this.$t("step-flow-cart-details-address-zip-label"),
        pickupTime: this.$t("step-flow-cart-details-pickup-time-label"),
      });

      if (validation.fails()) {
        this.errors = validation.errors.errors;
        return
      }

      if (this.company.sendSMSOnOrderReady || this.pointofsale.collectPhoneNumber) {
        const parsedPhoneNumber = parsePhoneNumber(data.phone)
        if (!parsedPhoneNumber || !parsedPhoneNumber.isPossible() || !parsedPhoneNumber.isValid()) {
          this.errors.phone = [this.$t("step-flow-cart-details-invalid-phone-number-error")]
        }
      }

      if (this.errors && Object.keys(this.errors).length > 0) {
        return;
      }

      // only for testing the pickup time check before paying
      // this.services.cartService.pickupTime.value = dayjs().startOf('day').toDate()


      this.$emit("next");
      return;


    },
  },
};
</script>
<style scoped>
ion-radio-group {
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.oido-phone-input {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.oido-country-selector {
  margin: 0.5rem 0.8rem 1rem;
}

ion-list-header {
  min-height: auto;
  margin-bottom: 0.3rem;
}

ion-list-header ion-label {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}


.order-options-notice {
  padding-bottom: 1rem;
}

.step-section {
  padding-bottom: 1.5rem;
}

.details-section.takeaway {
  margin-bottom: 1rem;

}

.step-section .step-section-header {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 200;
}

.note {
  margin-top: 1rem;
}

.oido-slotted-date-picker {
  margin-top: 0rem;
}

.selected-pickup-date {
  font-weight: 599;
  margin-left: 0.5rem;
  color: var(--ion-color-primary)
}

ion-radio .validation-errors,
ion-radio .validation-error {
  display: inline;
}

ion-radio .validation-error {
  font-size: 1rem;
  margin-left: 1rem;
}

.no-delivery-possible-warning {
  padding: 2rem 2rem 4rem;
  color: var(--ion-color-primary);
  font-style: italic;
  text-align: center;

}
</style>
<style>
.checkout-flow-item.order-details .details-section .input-line .validation-errors {
  text-align: left;
  margin-bottom: 0;
}

.checkout-flow-item.order-details .details-section .input-line {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  margin-left: 0.1rem;
  margin-bottom: 0.5rem;
}

.checkout-flow-item.order-details .details-section ion-input {
  --padding-start: 0.9rem;
}

.checkout-flow-item.order-details .details-section ion-input label {
  text-align: left !important;
}

.checkout-flow-item.order-details .oido-country-selector {
  margin: 0.5rem 0.8rem 1rem;
}

.oido-country-selector ion-label {
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
}
</style>
