<template>
  
  <span class="oido-help-icon" :class="{['position-'+position]: true}" v-if="helpText" @click.stop="toggleHelpText">    
    <oido-icon name="help" stroke-width="10" stroke="#555" v-if="helpText"></oido-icon>
    <span class="help-text" v-if="showHelpText" >
      <span class="close-icon" >x</span>
      <span class="help-text-content" v-html="helpText" ></span>

    </span>    
  </span>
</template>


<script setup>
  import OidoIcon from './OidoIcon.vue';
</script>
<script >
import { v4 as uuidv4 } from 'uuid';

var timeOutRef = null;
export default {
  data() {
    return {
      showHelpText: false
    };
  },
  mounted() {
    this.emitter.on("oido-help-icon:active", (data) => {
      if (data.componentId != this.componentId) {
        this.showHelpText = false;
        if (timeOutRef) {
          clearTimeout(timeOutRef);
        }
      }      
    });

  },
  computed: {
    componentId() {
      return "oido-help-icon" + '-' + uuidv4()
    }
  },

  props: {
    helpText: String,
    position: String,
  },
  methods: {
    toggleHelpText() {
      
      if (timeOutRef) {
        clearTimeout(timeOutRef);
      }
      if (this.showHelpText) {
        this.showHelpText = false
      } else {
        this.showHelpText = true;
        this.emitter.emit("oido-help-icon:active", { componentId: this.componentId });

        timeOutRef = setTimeout(() => {
          this.showHelpText = false;
        }, 20000)
        return false
      }


    }
  }



};
</script>


<style scoped>
.oido-help-icon {
  display: inline-block;
  cursor: pointer;
}

.oido-icon {
  position: relative;
  top: 0.15rem;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.oido-help-icon .help-text {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  font-weight: 300;
  font-size: 0.9rem;
  
  border: 1px solid #f0f0f0;
  box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.05);
  line-height: 1.1rem;
  bottom: 2rem;
  left: -0rem;
  width: 20rem;
  z-index: 500;
  border-radius: 0.3rem;
}

.oido-help-icon.position-bottom .help-text {
  bottom: inherit;
  top:1.5rem;
}
.oido-help-icon.position-bottom-left .help-text {
  bottom: inherit;
  top:1.5rem;
  margin-left: -18rem;
}
.oido-help-icon.position-top-left .help-text {
  margin-left: -18rem;
}

@media (max-width: 500px) {
  .oido-help-icon .help-text {
    width: 14rem;
  }

.oido-help-icon.position-bottom-left .help-text {
  margin-left: -13rem;
}
.oido-help-icon.position-top-left .help-text {
  margin-left: -13rem;
}

}


.close-icon{
  position: absolute;
  right: 0.5rem;
  top: 0.1rem;
  color:#999;
}
</style>


