import { createRouter, createWebHistory } from '@ionic/vue-router';
import getCustomDomain from './helpers/getCustomDomain'

let hasCustomDomain = Boolean(getCustomDomain())



let companyIdPrefix = hasCustomDomain ? '' : '/:companyId?'


const routes = [  
  {
    path: "/",
    name: "startpage",
    component: ()  => import('@/views/MenuPage.vue')
  },  
  {
    path: '/company/:companyId/:companySalt/:pointofsaleId/:pointofsaleSalt/menu/:categoryId?',
    name: 'menu-old',
    component: ()  => import('@/views/MenuPage.vue')
  },      
  {
    path: '/company/:companyId/:companySalt/:pointofsaleId/:pointofsaleSalt/menu/:categoryId?/cart/:step?',
    name: 'menu-cart-old',
    component: ()  => import('@/views/MenuPage.vue')
  },  
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PrivacyPage.vue')
  },      
  {
    path: '/termsAndConditions',
    name: 'termsAndConditions',
    component: () => import('@/views/TermsAndConditionsPage.vue')
  },      
  {
    path: companyIdPrefix + '/:pointofsaleId?/menu/:categoryId?/cart/:step?',
    name: 'menu-cart',
    component: ()  => import('@/views/MenuPage.vue')
  },  
  {
    path: companyIdPrefix + '/:pointofsaleId?/menu/:categoryId?',
    name: 'menu',
    component: ()  => import('@/views/MenuPage.vue')
  },      
  {
    path: companyIdPrefix + '/:pointofsaleId?/stories/',
    name: "stories-page",
    component: ()  => import('@/views/StoriesPage.vue')
  },      
  {
    path: companyIdPrefix + '/:pointofsaleId?/pages/:pageId',
    name: "content-page",
    component: ()  => import('@/views/ContentPage.vue')
  },      
  {
    path: companyIdPrefix + '/:pointofsaleId?/stories/:storyId',
    name: "story",
    component: ()  => import('@/views/StoryPage.vue')
  },      
  {
    path: companyIdPrefix + '/:pointofsaleId?/menu/:categoryId?/contact',
    name: 'contact',
    component: ()  => import('@/views/MenuPage.vue')
  },      
  {
    path: companyIdPrefix ,
    name: 'default-menu',
    component: ()  => import('@/views/MenuPage.vue')
  },      
  {
    path: companyIdPrefix + '/translator/',
    name: "translator",
    component: ()  => import('@/views/TranslatorTool.vue')
  },
]



let baseUrl = typeof process != 'undefined'  ? process.env.BASE_URL : ""
const router = createRouter({
  history: createWebHistory(baseUrl),
  routes
})


// router.beforeEach((to, from, next) => {
//     if(window.opener){
//       window.opener.open(window.document.documentURI, "_self", "noopener")
//       window.close()      
//     }
//     next();  
// })

export default router
