import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';
import { IonicVue } from '@ionic/vue';
import { createI18n } from 'vue-i18n'
import packageInfo from "../package.json"
import VueSelect from "vue-select";
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import config from "../config"

import mediaService from "./composables/mediaService";

import firebaseService from "./composables/firebaseService";
import customerService from "./composables/customerService";
import translationService from "./composables/translationService";
import localeService from "./composables/localeService";
import companyService from "./composables/companyService";
import cartService from "./composables/cartService";
import statisticsService from "./composables/statisticsService";
import paymentService from "./composables/paymentService";


let translationServiceInstance = translationService();

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore);

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter);


import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
dayjs.extend(customParseFormat);



import { useTimestamp } from '@vueuse/core'
const now = useTimestamp({ interval: 60000 })


import combinedTranslations from "./translations/translations-frontend";
let availableLanguages = Object.keys(Object.values(combinedTranslations)[0]);
let translations = {}
for (let language of availableLanguages) {
  translations[language] = {};
}
for (let key in combinedTranslations) {
  for (let language of availableLanguages) {
    if (
      typeof combinedTranslations[key][language] !== 'undefined'
      && (language == translationServiceInstance.defaultLanguage || combinedTranslations[key][language].length > 0)
      // skip empty translations except for the default language (which might be empty to hide the element)
    ) {
      translations[language][key] = combinedTranslations[key][language];
    }
  }
}
let i18n = createI18n({
  locale: translationServiceInstance.selectedLanguage.value,
  fallbackLocale: translationServiceInstance.defaultLanguage.value,
  messages: translations,
  silentFallbackWarn: true,
  silentTranslationWarn: true
})




import Validator from "validatorjs";
import en from 'validatorjs/src/lang/en';
import de from 'validatorjs/src/lang/de';
import es from 'validatorjs/src/lang/es';
import fr from 'validatorjs/src/lang/fr';
import nl from 'validatorjs/src/lang/nl';
import ca from 'validatorjs/src/lang/ca';
Validator.setMessages('en', en);
Validator.setMessages('de', de);
Validator.setMessages('nl', nl);
Validator.setMessages('es', es);
Validator.setMessages('ca', ca);
Validator.setMessages('fr', fr);

Validator.useLang(translationServiceInstance.selectedLanguage && translationServiceInstance.selectedLanguage.value ? translationServiceInstance.selectedLanguage.value : "en");

// function setI18nLanguage(i18n, locale) {
//   if (i18n.mode === 'legacy') {
//     i18n.global.locale = locale
//   } else {
//     i18n.global.locale.value = locale
//   }
//   document.querySelector('html').setAttribute('lang', locale)
// }

const firebase = firebaseService();
const customerServiceInstance = customerService();


let services = {
  company: companyService(),
  locale: localeService(),
  translations: translationServiceInstance,
  statistics: statisticsService(),
  cartService: cartService(true),
  payments: paymentService(),
  mediaService: mediaService()
}

const app = createApp(App)
  .component("v-select", VueSelect)
  .use(IonicVue)
  .use(i18n)
  .use(router);


app.mixin(
  {
    computed: {
      services() { return services },
      version() { return packageInfo.version },
      currencySymbol() {
        let currencyCode = services.company.company && services.company.company.value && services.company.company.value.currency ? services.company.company.value.currency : "EUR";
        let currency = services.locale.getCurrencyInfo(currencyCode);
        return currency && currency.symbol ? currency.symbol : "€"
      },
      companyService() {
        return services.company;
      },
      company() {
        return services.company.company ? services.company.company.value : null;
      },
      visibleCategories() {
        return services.company.visibleCategories ? services.company.visibleCategories.value : null;
      },
      visibleCategoryIds() {
        return services.company.visibleCategoryIds ? services.company.visibleCategoryIds.value : [];
      },
      hasAnActiveSubscription() {
        if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
          return true;
        }
        // if (config.environment == "dev") {
        //   return true;
        // }
        if (config.environment == "testumgebung") {
          return true;
        }
        return services.company.company && services.company.company.value && services.company.company.value.hasAnActiveSubscription;        
      },
      pointofsale() {
        return services.company.activePointofsale ? services.company.activePointofsale.value : null;
      },
      visibleStories() {
        let visibleStories = []
  
        if (this.company && this.company.stories && this.company.stories.length > 0) {
          for (let story of this.company.stories) {            
            if (this.pointofsale && this.pointofsale.storiesVisibilityRule == 'except') {
              let storiesToIgnore = this.pointofsale.storiesVisibilityConfig && this.pointofsale.storiesVisibilityConfig.length > 0 ? this.pointofsale.storiesVisibilityConfig : []
              console.log('storiesToIgnore',storiesToIgnore)
              if (storiesToIgnore.includes(story.id)) {
                continue
              }
            }
            if (this.pointofsale && this.pointofsale.storiesVisibilityRule == 'only') {
              let storiesToInclude = this.pointofsale.storiesVisibilityConfig && this.pointofsale.storiesVisibilityConfig.length > 0 ? this.pointofsale.storiesVisibilityConfig : []
              if (!storiesToInclude.includes(story.id)) {
                continue
              }
            }
            visibleStories.push(story)
          }
        }
  
        return visibleStories
      },
      availableDeliveryOptions(){
        let availableDeliveryOptions = [];
        if(this.pointofsale.enableDelivery ){
          availableDeliveryOptions.push('delivery')
        }
        if(this.pointofsale.enablePickup ){
          availableDeliveryOptions.push('takeaway')
        }
        if(this.pointofsale.enableTableService ){
          availableDeliveryOptions.push('table')
        }

        return availableDeliveryOptions;
      },
      isInIframe(){
        return window !== window.parent;
      },
      frameWidth(){
        return window.innerWidth
      },
      now() {
        return now.value;
      }
    }
  }
)


router.beforeEach((to, from, next) => {    
  return next()
})
router.isReady().then(() => {
  app.mount('#app');
  if(location.hostname == 'www.oido.me'){    
    addPlausibleScript() 
  }
  
});



function addPlausibleScript() {
  var s = document.createElement( "script" );
  s.setAttribute( "src", "https://plausible.io/js/script.js" );
  s.setAttribute( "data-domain", "oido.me/clients" );
  document.body.appendChild( s );
}
