const firebaseConfig = {
  apiKey: "AIzaSyDsV7O8xy7Js15wBeU3aU_yPjehOsccwm8",
  authDomain: "oidowithbugs.firebaseapp.com",
  projectId: "oidowithbugs",
  storageBucket: "oidowithbugs.appspot.com",
  messagingSenderId: "658192305666",
  appId: "1:658192305666:web:ec02a981840872ee98a11b"
};


export default firebaseConfig;