<template>
  <div class="checkout-flow-item courses">
    <div class="checkout-flow-item-header">
      <h2 v-text="$t('step-flow-courses-title')"></h2>
    </div>
    <div class="checkout-flow-item-content">
      <div class="course-wrapper course" v-for="course of company.courses" :key="'course-'+course.id" :data-course-id="course.id">
        <h2 class="course-header" v-text="services.translations.translation2Text(course.name)"></h2>

        <draggable
          :list="itemsByCourse[course.id]"
          group="items"
          @end="draggingEnds"
          item-key="key"
          ghost-class="ghost"
          drag-class="dragging"
          handle=".drag-handle"
          class="course-container"
        >
          <template #item="{ element }">
            <meal-list-item :item="element" @notechanged="changeNote(element, $event)" :enable-notes="pointofsale && pointofsale.allowNotesOnIndividualDishes"></meal-list-item>
          </template>
        </draggable>
      </div>
    </div>
    <div class="checkout-flow-item-footer">
      <div class="flow-navigation-buttons">
        <ion-button class="back-button" color="medium" expand="block" @click="gotoPreviousStep" :size="frameWidth < 350 ? 'small': 'default'">
          <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
          <span class="label">{{ $t("step-flow-back-button-label") }}</span>
        </ion-button>

        <ion-button class="next-button" 
          :disabled="totalNumberOfItems == 0"
          expand="block"
           @click="$emit('next')" 
           :size="frameWidth < 350 ? 'small': 'default'">
          <span class="label">{{ $t("step-flow-cart-overview-next-button-label") }}</span>
          <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>

      </div>
    </div>
  </div>
</template>

<script setup>
import {
  IonButton,
  IonIcon,
} from "@ionic/vue";

import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import draggable from "vuedraggable";
import MealListItem from "./MealListItem.vue";

</script>


<script>


export default {  
  props: ['step'],
  data() {
    return {
      items: {},
    };
  },    
  computed: {
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    itemsByCourse() {
      return this.services.cartService.itemsByCourse ? this.services.cartService.itemsByCourse.value : null;
    },
    cartItems() {
      return this.services.cartService.items ? this.services.cartService.items.value : null;
    },
    pointofsaleId() {
      return this.$route.params["pointofsaleId"] ? this.$route.params["pointofsaleId"] : null;
    },
    pointofsale() {
      if (!this.pointofsaleId) {
        return null;
      }
      return this.companyService.getPointofsaleById(this.pointofsaleId);
    },
  },
  async mounted() {},
  methods: {
    async draggingEnds(e) {
      // let fromCourseNode = e.from.closest(".course");
      // let fromCourseId = fromCourseNode && fromCourseNode.attributes["data-course-id"] ? fromCourseNode.attributes["data-course-id"].value : null;

      // let toCourseNode = e.to.closest(".course");
      // let toCourseId = toCourseNode && toCourseNode.attributes["data-course-id"] ? toCourseNode.attributes["data-course-id"].value : null;

      // let oldIndex = e.oldIndex;
      // let newIndex = e.newIndex;
      
    },
    getNameOfProduct(productId) {
      let product = this.companyService.getProductById(productId);
      if (!product) {
        return null;
      }
      return this.services.translations.translation2Text(product.name);
    },    
    changeNote(element, data) {
      element.note = data;
    },
    gotoPreviousStep() {
      this.$emit("previous");
    },
  },
};
</script>
<style scoped>
.course-header {
  text-align: center;
  font-weight: 200;
  font-size: 1.4rem;
}
.course-container {
  min-height: 2rem;
}
.meal-item:first-child {
  border-top: 1px solid #ddd;  
}
</style>