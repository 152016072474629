import { toRefs, reactive, watch } from "vue";

import { v4 as uuidv4 } from 'uuid';

const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.customerId = {};
}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    customerId: null
});

function checkOrCreateCustomerId(){      
    let customerId = localStorage.getItem('customerId')
    if(!customerId){
        customerId = uuidv4();
        localStorage.setItem('customerId', customerId)
    }
    state.customerId = customerId;

}

export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        state.initialized = true
        state.initializing = false
        checkOrCreateCustomerId();

    }


    return {
        ...toRefs(state),
    
    }


}