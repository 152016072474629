<template>
  <div class="legal-modal">
    <div class="content" v-html="legalText"></div>
    <div class="buttons">
      <ion-button class="note-button negative" color="dark" fill="clear" @click="no" >{{noLabel}} </ion-button>
        <ion-button class="note-button remove" fill="clear" @click="yes">{{yesLabel}}</ion-button>
    </div>
    
  </div>



</template>

<script setup>

import {
  IonButton,  
  IonIcon,
  modalController,
  
} from "@ionic/vue";


</script>
<script>
export default {
  props: [],
  computed:{
    noLabel(){
      if (this.company && this.company.legalAgePopupNegativeButtonLabel) {
        return this.services.translations.translation2Text(this.company.legalAgePopupNegativeButtonLabel)
      }
      return 'No'
    },
    yesLabel(){
      if (this.company && this.company.legalAgePopupPositiveButtonLabel) {
        return this.services.translations.translation2Text(this.company.legalAgePopupPositiveButtonLabel)
      }
      return 'Yes'
    },
    legalText(){
      if (this.company && this.company.enableLegalAgePopup && this.company.legalAgePopupContent) {
        let text = this.services.translations.translation2Text(this.company.legalAgePopupContent);
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      } else {
        return ""
      }
    }
  },
  data() {
    return {
    };
  },

  methods: {
    no(){
      
      modalController.dismiss(false);      
    },
    yes(){
      modalController.dismiss(true);
    },    
  },
};
</script>
<style scoped>
.legal-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: center;
  align-items: center;  
  position: relative;  
  background-color: white;
 padding: 2rem 3rem;
}
.legal-modal .content{
  /* flex:1; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 300;
  text-align: center;
}
.legal-modal .buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.legal-modal .buttons ion-button{
  margin: 0 0.5rem;
  
}

</style>
