<template>
  <span class="oido-icon" v-html="svg" ></span>
</template>


<script >
import icons from './icons';

export default {
  props: {
    name: String,
    stroke: String,
    strokeWidth: String,
    size: String,
  },
  computed: {
    svg() {
      let svg = null
      if (icons[this.name]) {
        svg = icons[this.name]
      }
      if(svg){
        let styles = '';
        if(this.stroke){
          styles += `stroke: ${this.stroke}!important;`;
        }
        if(this.size){
          styles += `width: ${this.size}!important;`;
        }
        if(this.strokeWidth){
          styles += `stroke-width: ${this.strokeWidth}!important;`;
        }
        svg = svg.replace('<svg ', `<svg style="${styles}" ` );
      }
      return svg;
    }

  }
}

</script>


<style scoped>

</style>
<style>
.oido-icon svg {  
  stroke: black;
  fill: transparent;
  stroke-width: 50px;
}
</style>


