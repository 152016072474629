<template>
  <div class="checkout-flow-item final-report">
    <div class="checkout-flow-item-header">
      <h2 v-text="statusMessage"></h2>
    </div>
    <div class="checkout-flow-item-content">
      <div class="loading-indicator" v-if="!order">
        <ion-spinner color="primary" name="dots"></ion-spinner>
        <div class="label">{{ $t("shopping-cart-step-final-loading-indicator-label") }}</div>
      </div>


      <div class="qrcode-section section top" v-if="order && showQRCodeOnTop">

        <div class="qrcode">
          <div class="gobo left"></div>
          <div class="gobo top"></div>
          <div class="gobo right"></div>
          <div class="gobo bottom"></div>
          <div class="svg-container" v-html="qrcode"></div>
        </div>        
        <div class="label" v-text="$t('final-report-qrcode-label')"></div>
      </div>

      <div class="order-id-section section top" v-if="order && showQRCodeOnTop">
        
        <div class="order-id line">
          <span class="label" v-text="$t('shopping-cart-step-final-order-id-label') + ':'"></span>
          <span class="value" v-text="order.id"></span>
        </div>
        <div class="customer-name line" v-if="order.name || order.email">
          <span class="label" v-text="$t('shopping-cart-step-final-customer-name-label') + ':'"></span>
          <span class="value" v-text="order.name || order.email"></span>          
        </div>
        <div class="order-date line">
          <span class="label" v-text="$t('shopping-cart-step-final-date-label') + ':'"></span>
          <span class="value" v-text="orderDate"></span>
        </div>

      </div>


      <div class="progress-indicator-section section" v-if="order && showProgressIndicator && !showQRCodeOnTop">
        <order-progress-indicator :order="order" v-if="showProgressIndicator"></order-progress-indicator>
      </div>


      <div class="delivery-notes section" v-if="order && showDeliveryNotes">
        <div class="expected-delivery-message  rtf-note" v-html="pointofsaleCheckoutNotice" v-if="pointofsaleCheckoutNotice"></div>
        <div class="expected-delivery-message" v-html="expectedDeliveryNotice"
          v-if="expectedDeliveryNotice && !pointofsaleCheckoutNotice"></div>
      </div>

      <div class="breakdown-section section" v-if="order">
        <div class="receipt-items-container">
          <div class="order-items-list" v-if="orderItems">
            <order-item-list-item v-for="(item, index) of orderItems" :key="index" :item="item">
            </order-item-list-item>
          </div>
                    
          <div class="taxes-and-totals" v-if="orderItems && orderItems.length > 0">
            <div class="delivery-costs-line line" v-if="order.deliveryCosts">
              <span class="label" v-text="$t('shopping-cart-delivery-costs-label')"></span>
              <span class="value" >{{ Number.parseFloat(order.deliveryCosts).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span></span>
            </div>
            <div class="discount-amount-line line" v-for="discount of order.discounts" :key="discount.id">
              <span class="label" v-text="services.translations.translation2Text(discount.name)"></span>
              <span class="value" >- {{ Number.parseFloat(discount.amount).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span></span>
            </div>

            <div class="tip-amount-line line" v-if="company.tipIsEnabled">
              <span class="label" v-text="$t('shopping-cart-tipamount-label')"></span>
              <span class="value" >{{ Number.parseFloat(order.tip).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span></span>
            </div>
            
            <div class="total-line line">
              <span class="label" v-text="$t('shopping-cart-overview-total-label')"></span>
              <span class="value" >{{ Number.parseFloat(order.cartTotal).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span></span>
            </div>
          </div>
        </div>
      </div>



      <div class="receipt-section section" v-if="order && order.paymentType != 'offline'">
        <h2>
          <span v-text="$t('shopping-cart-step-final-receipt-heading')"></span>
        </h2>
        <div class="notice" v-html="$t('shopping-cart-step-final-receipt-notice')"></div>

        <ion-button color="primary" @click="downloadReceipt" >
          <ion-icon v-if="!downloadingReceipt" slot="start" :icon="cloudDownloadOutline"></ion-icon>
          <ion-spinner v-if="downloadingReceipt" slot="icon-only"></ion-spinner>
          <span class="label" >{{ $t("shopping-cart-step-final-receipt-download-button-label")
          }}</span>          
        </ion-button>
      </div>

      <div class="order-id-section section" v-if="order && !showQRCodeOnTop">
        
        <div class="order-id line">
          <span class="label" v-text="$t('shopping-cart-step-final-order-id-label') + ':'"></span>
          <span class="value" v-text="order.id"></span>
        </div>
        <div class="customer-name line" v-if="order.name || order.email">
          <span class="label" v-text="$t('shopping-cart-step-final-customer-name-label') + ':'"></span>
          <span class="value" v-text="order.name || order.email"></span>          
        </div>
        <div class="order-date line">
          <span class="label" v-text="$t('shopping-cart-step-final-date-label') + ':'"></span>
          <span class="value" v-text="orderDate"></span>
        </div>

      </div>

      <div class="qrcode-section section" v-if="order && !showQRCodeOnTop">

        <div class="qrcode">
          <div class="gobo left"></div>
          <div class="gobo top"></div>
          <div class="gobo right"></div>
          <div class="gobo bottom"></div>
          <div class="svg-container" v-html="qrcode"></div>
        </div>
        <div class="label" v-text="$t('final-report-qrcode-label')"></div>

      </div>
      

    </div>
  </div>
</template>

<script setup>
import { IonButton, IonIcon, IonSpinner} from "@ionic/vue";
import { cloudDownloadOutline } from "ionicons/icons";
import OrderItemListItem from "./OrderItemListItem.vue";
import OrderProgressIndicator from "./OrderProgressIndicator.vue";
import dayjs from "dayjs";
import config from "../../config"
import axios from "axios";
import QRCode from "qrcode-svg";

</script>
<script>

export default {
  data() {
    return {
      downloadingReceipt: false,
    };
  },  
  watch: {
    $route: function () {
      this.checkOrderState();
    },
  },
  computed: {
    statusMessage() {
      if (!this.order) {
        return ''
      }
      if (!this.showProgressIndicator && this.order.status != 'done') {
        return this.$t("order-status-message-without-progress-indicator")
      }
      if (this.order.status == 'ready') {
        return this.$t('order-status-message-' + this.order.status + '-' + this.order.deliveryType)
      }
      return this.$t('order-status-message-' + this.order.status)
    },
    expectedDeliveryNotice() {
      let notice = this.company && this.company.expectedDeliveryNotice ? this.company.expectedDeliveryNotice : null
      let translated = this.services.translations.translation2Text(notice)
      return translated ? ('<p>' + translated + '</p>').replace("\n", '</p><p>') : ''
    },
    pointofsaleCheckoutNotice() {
      let notice = this.pointofsale && this.pointofsale.checkoutNotice ? this.pointofsale.checkoutNotice : null
      let translated = this.services.translations.translation2Text(notice);
      return translated ? ('<p>' + translated + '</p>').replace("\n", '</p><p>') : ''
    },
    showProgressIndicator() {
      // console.log('showProgressIndicator', this.pointofsale)
      return this.pointofsale && this.pointofsale.showProgressIndicator && this.order && this.order.status != 'done'
    },
    qrcode() {
      if (!this.order) {
        return null;
      }
      return new QRCode({
        content: JSON.stringify({ id: this.order.id, salt: this.order.salt }),
        container: "svg-viewbox",
      }).svg();
    },
    orderId() {
      return this.$route.query["order_id"] ? this.$route.query["order_id"] : null;
    },
    order() {
      return this.orderId && this.services.payments.orders ? this.services.payments.orders.value[this.orderId] : null;
    },
    showQRCodeOnTop() {
      if (!this.order || !this.pointofsale) {
        return false;
      }
      return this.pointofsale.enablePickup && this.order.deliveryType == "takeaway" && this.order.status == 'ready';
    },
    showDeliveryNotes() {
      if (!this.order || !this.pointofsale) {
        return false;
      }
      return   this.order.status == 'new' || this.order.status == 'wip';
    },    
    receiptUrl() {
      if (!this.order) {
        return null;
      }
      return config.apiUrl + '/api/orders/receipts/' + this.order.id + '.pdf'
    },
    orderDate() {
      if (!this.order || !this.order.dateOrdered) {
        return "";
      }
      let date = this.order.dateOrdered.seconds ? new Date(this.order.dateOrdered.seconds * 1000) : this.order.dateOrdered;
      return dayjs(String(date)).tz(this.company.timezone).format("DD.MM.YYYY HH:mm");
    },
    pickupTime() {
      if (!this.order) {
        return null;
      }
      if (this.order.pickupTimeMode == "assoonaspossible") {
        return this.$t("step-flow-cart-details-takeaway-time-assoonaspossible-label");
      }
      if (this.order.pickupTime) {
        let date = this.order.pickupTime.seconds ? new Date(this.order.pickupTime.seconds * 1000) : this.order.pickupTime;
        return dayjs(String(date)).tz(this.company.timezone).format("DD.MM.YYYY HH:mm");
      }
      return null;
    },
    pointofsale() {
      if (!this.order || !this.order.pointofsaleId) {
        return null;
      }

      return this.companyService.getPointofsaleById(this.order.pointofsaleId);
    },
    orderItems() {
      if (!this.order || (!this.order.items && !this.order.receiptItems)) {
        return null;
      }

      let items = [];
      //keep it compatibvle with earlier versions which lacked receiptItems
      let source = this.order.receiptItems ? this.order.receiptItems : this.order.items
      console.log('source', source, this.order.receiptItems)
      for (let item of source) {
        items.push(item);
      }
      return items;
    },
  },
  async mounted() {    
    this.checkOrderState();
  },
  methods: {
    async checkOrderState() {
      if (this.orderId) {
        await this.services.payments.checkOrderState(this.orderId);        
      }
    },
    async downloadReceipt() {
      if(this.downloadingReceipt){
        return
      }
      this.downloadingReceipt = true;

      let response = await axios.post(this.receiptUrl, {}, {        
            responseType: "arraybuffer",
        });
      
      
      let filename =  this.order.id + '.pdf';      
      
      this.downloadingReceipt = false;
      const link = document.createElement("a");
      const blob = new Blob([response.data]);
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }    
  },
};
</script>
<style scoped>
.loading-indicator {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-indicator ion-spinner {
  width: 100px !important;
  height: 90px !important;
}

.loading-indicator .label {
  margin-top: -1rem;
  color: var(--ion-color-medium);
  font-size: 0.8rem;
}



.section {
  border-top: 1px solid #eee;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: block;
  text-align: center;
  max-width: 500px;
}


.receipt-section.section,
.breakdown-section.section,
.status-section.section {
  border-top: none;
}
.progress-indicator-section{
  margin-top: 3rem;
}

.final-report {
  color: #333;
  font-weight: 300;
}

.final-report h2 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  letter-spacing: -0.02rem;
  margin-top: 0rem;
}

.taxes-and-totals {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.taxes-and-totals .line {
  display: flex;
  text-align: right;
  width: 100%;
  border-top: 1px solid #eee;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.taxes-and-totals .label {
  text-align: left;
  flex: 1;
}

.taxes-and-totals .value {
  margin-left: 0.5rem;
  min-width: 6rem;
  font-weight: 500;
  display: inline-block;
}

.taxes-and-totals .total-line {
  font-weight: 600;
  padding-top: 0.7rem;

}

.taxes-and-totals .total-line .value {
  font-weight: 600;
}

.order-meta-section {
  color: #999;
  margin-bottom: 2.5rem;
}

.order-meta-section .line .label {
  margin-right: 0.3rem;
}

.order-meta-section .line .value {
  font-weight: 400;
}


.receipt-section h2 {
  margin-bottom: 1rem;
}


.qrcode-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3rem;
}
.qrcode-section.top{
  border-top: none;
}

.qrcode-section .label {  
  margin-top: 2rem;
 
}


.qrcode-section .order-id{
  color: var(--ion-color-primary)!important;
  margin-bottom: 0.3rem;
}
.qrcode-section .order-id,
.qrcode-section .customer-name{
  /* font-size: 0.8rem;   */
  font-weight: 200;
  
}

.qrcode-section .qrcode {

  position: relative;
  line-height: 0;

  border: 3px solid var(--ion-color-primary);
  border-radius: 12px;
  padding: 3px;
  margin-bottom: 1rem;
}

.qrcode-section .qrcode .svg-container {
  width: 180px;
  height: 180px;
  display: flex;

}

.status-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.9rem;
  padding-bottom: 2rem;
}

.status-icon {
  font-size: 2rem;
}

.status-icon.done {
  color: var(--ion-color-success);
  font-size: 2rem;
}



.expected-delivery-message {
  padding-top: 0em;
  padding-bottom: 1em;
  color: var(--ion-color-primary);
  max-width: 18rem;  
  margin: 0 auto;
}

.checkout-flow-item-content {
  padding-top: 0rem;
  margin-bottom: 6px;
}


.order-id-section.top{
  border-bottom: 1px solid #eee;
  margin-bottom: 3rem;
}
.order-id-section .line{
  margin-bottom: 0.3rem;
}
.order-id-section .order-id {
  color: var(--ion-color-primary)
}

.order-id-section .label {
  margin-right: 0.3rem;
  white-space: nowrap;
}

.receipt-section {
  padding-bottom: 4rem;
}

.receipt-section .notice {
  max-width: 15rem;
  text-align: center;
  margin: 0 auto 1.5rem;

}

.gobo {
  background-color: white;
  ;

  position: absolute;

}

.gobo.top,
.gobo.bottom {
  width: 70%;
  left: 15%;
  height: 12px;
}

.gobo.left,
.gobo.right {
  height: 70%;
  width: 12px;
  top: 15%;
}

.gobo.top {
  top: -8px
}

.gobo.bottom {
  bottom: -8px;
}

.gobo.left {
  left: -8px;
}

.gobo.right {
  right: -8px;
}
</style>

<style>

.final-report .list-item {
  padding-bottom: 0.3rem;
}

.final-report .list-item .list-item-name {
  font-size: 1rem;
  font-weight: normal;
  text-align: left;

}
ion-button  ion-spinner{
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

</style>