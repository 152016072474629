<template>
  <div class="checkout-flow">
    <div class="checkout-flow-header" v-if="checkoutIsEnabled">
      <div class="flow-stations-indicator-bar">
        <div class="flow-stations-indicator-station" v-for="step of stepsConfig" :key="step.name"
          :class="{ active: step.name == actualStepName, 'has-icon': iconForStep(step.name) }">
          <div class="label" v-text="$t('checkout-flow-station-label-' + step.name)"></div>
          <ion-icon class="icon" v-if="iconForStep(step.name)" :icon="iconForStep(step.name)"></ion-icon>
        </div>
      </div>
      <div class="warning-sign" v-if="showNoSubscriptionNotice" v-html="$t('checkout-testmode-notice')"></div>
    </div>
    <component v-for="step of stepsConfig" :key="step.name" v-show="step.name == actualStepName" :is="step.component"
      :class="{ [step.name]: true }" :step="step" @next="gotoNext" @previous="gotoPrevious"></component>
  </div>
</template>

<script setup>
import CheckOutFlowCartOverview from "./CheckOutFlowCartOverview.vue";
import CheckOutFlowFinalReport from "./CheckOutFlowFinalReport.vue";
import CheckOutFlowPayment from "./CheckOutFlowPayment.vue";
import CheckOutFlowOrderDetails from "./CheckOutFlowOrderDetails.vue";
import CheckOutFlowCoursesView from "./CheckOutFlowCoursesView.vue";
import CheckOutFlowSummaryView from "./CheckOutFlowSummaryView.vue";
import CheckOutFlowNotes from "./CheckOutFlowNotes.vue";
import config from "../../config"
import { cartOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
</script>


<script>

export default {
  components: {
    CheckOutFlowCartOverview,
    CheckOutFlowFinalReport,
    CheckOutFlowPayment,
    CheckOutFlowOrderDetails,
    CheckOutFlowCoursesView,
    CheckOutFlowNotes,
    CheckOutFlowSummaryView
  },
  watch: {
    actualStepName() {
      this.goToActualStep();
    },
  },
  computed: {

    showNoSubscriptionNotice() {
      if (config && config.environment == "testumgebung") {
        return false
      }
      return false;
      //return this.company && this.checkoutIsEnabled && !this.hasAnActiveSubscription
      //return this.company && this.checkoutIsEnabled && (!this.hasAnActiveSubscription || !this.company.hasAValidStripeConnection) 
    },
    checkoutIsEnabled() {
      return this.pointofsale && this.pointofsale.enableCheckout
    },
    tableServiceIsEnabled() {
      return this.pointofsale && this.pointofsale.enableTableService
    },
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    stepsConfig() {
      if (!this.pointofsale) {
        return [];
      }

      let steps = [
        {
          name: "cart-overview",
          component: "CheckOutFlowCartOverview",
        },
      ];
      let offlinePaymentIsActive = this.pointofsale.enableOfflinePayment && this.company.enableOfflinePayments
      let hasAValidPaymentType = this.pointofsale.enableOnlinePayment || offlinePaymentIsActive
      if (!this.pointofsale.enableCheckout || !hasAValidPaymentType) {
        return steps
      }


      if (this.availableDeliveryOptions.length > 1 || this.availableDeliveryOptions.includes('delivery')  || (this.availableDeliveryOptions.includes('takeaway') && this.pointofsale.allowCustomPickupTime ) || this.company.sendSMSOnOrderReady || this.pointofsale.collectPhoneNumber || this.pointofsale.enableGeneralNote) {
        steps.push({
          name: "cart-details",
          component: "CheckOutFlowOrderDetails",
        });
      }


      //only show the order screen if there are at least 2 courses
      if (this.tableServiceIsEnabled && this.services.cartService.deliveryType.value == "table" && this.pointofsale.showCourses && this.company.courses && this.company.courses.length > 1) {
        steps.push({
          name: "cart-courses",
          component: "CheckOutFlowCoursesView",
        });
      }

      if ((!this.pointofsale.showCourses || (this.company.courses && this.company.courses.length == 1) || !this.tableServiceIsEnabled || this.services.cartService.deliveryType.value != "table" ) && this.pointofsale.allowNotesOnIndividualDishes) {
        steps.push({
          name: "cart-notes",
          component: "CheckOutFlowNotes",
        });
      }



      // offline Payments are enabled if: 
      //  The zone allows offline payments
      //  AND the company checked the consent in the settings
      //  AND if its a delivery invoices have to be allowed 
      let offlinePaymentsAreEnabled = 
        this.company.enableOfflinePayments && 
        this.pointofsale.enableOfflinePayment &&
        (this.services.cartService.deliveryType.value != "delivery" || this.pointofsale.allowDeliveryOnInvoice)

        



      if ( offlinePaymentsAreEnabled && this.pointofsale.enableOnlinePayment ) {
        steps.push({
          name: "cart-payment",
          component: "CheckOutFlowPayment",
        });
      }      
      steps.push({
        name: "cart-summary",
        component: "CheckOutFlowSummaryView",
      });
      steps.push({
        name: "cart-success",
        component: "CheckOutFlowFinalReport",
      });

      return steps;
    },
    actualStepName() {
      return this.$route.params["step"] ? this.$route.params["step"] : "cart-overview";
    },
  },
  async mounted() {

    this.goToActualStep();
  },
  methods: {
    iconForStep(stepName) {
      if (stepName == "cart-overview") {
        return cartOutline
      }
      return null
    },
    goToActualStep() { },
    gotoNext() {
      let currentIndex = 0;
      let indexToSwipeTo = 0;
      for (let step of this.stepsConfig) {
        if (step.name == this.actualStepName) {
          currentIndex = this.stepsConfig.indexOf(step);
          break;
        }
      }
      if (this.stepsConfig.length > currentIndex + 1) {
        indexToSwipeTo = currentIndex + 1;
      } else {
        this.$router.push({ name: "menu", params: { ...this.$route.params } });
        return;
      }
      let targetStepName = this.stepsConfig[indexToSwipeTo].name;
      this.$router.push({ name: "menu-cart", params: { ...this.$route.params, step: targetStepName } });
    },
    gotoPrevious() {

      let currentIndex = 0;
      let indexToSwipeTo = 0;
      for (let step of this.stepsConfig) {
        if (step.name == this.actualStepName) {
          currentIndex = this.stepsConfig.indexOf(step);
          break;
        }
      }
      if (currentIndex > 0) {
        indexToSwipeTo = currentIndex - 1;
      } else {
        this.$router.push({ name: "menu", params: { ...this.$route.params } });
        return;
      }
      let targetStepName = this.stepsConfig[indexToSwipeTo].name;
      this.$router.push({ name: "menu-cart", params: { ...this.$route.params, step: targetStepName } });
    },
  },
};
</script>
<style scoped>
.checkout-flow {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.checkout-flow-item {
  display: flex;
  height: 100%;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
}

ion-slides {
  height: 100%;
}
</style>

<style>
.checkout-flow-item-header {
  text-align: center;
  padding: 0 1.5rem;
}

.checkout-flow-item-content>* {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.checkout-flow-item-content {
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
  padding: 1rem 2rem;
  text-align: center;
}


.checkout-flow-item-footer {
  padding: 0.3rem 1rem 0.7rem;
  display: flex;
  justify-content: center;
}

.checkout-flow-item-footer>* {
  max-width: 600px;
  display: block;
  flex: 1;
}

.checkout-flow-item-footer .flow-navigation-buttons {
  display: flex;
}


.checkout-flow-item-footer .flow-navigation-buttons .next-button {
  flex: 1;
}

.flow-stations-indicator-bar {
  display: flex;
  max-width: 600px;
  margin: 1rem auto 0.8rem;
  justify-content: center;
}

.flow-stations-indicator-station {
  font-weight: 300;
  color: var(--ion-color-medium);
  text-align: center;
  padding: 0.2rem 1rem;
  position: relative;
  font-size: 0.8rem;
  height: 20px;
  line-height: 1rem;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flow-stations-indicator-station .icon {
  display: none;
}

@media (max-width: 500px) {
  .flow-stations-indicator-station {
    padding: 0.2rem 0.6rem;
  }

  .flow-stations-indicator-station .icon {
    display: block;
  }

  .flow-stations-indicator-station.has-icon .label {
    display: none;
  }

}


.flow-stations-indicator-station:not(:last-child) {
  border-right: none;

}

.flow-stations-indicator-station:not(:last-child)::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  line-height: 0;
  z-index: 30000;
  border-left: 5px solid var(--ion-color-light-shade);
  position: absolute;
  right: -5px;
  top: 6px;

}

.flow-stations-indicator-station.active {
  color: var(--ion-color-primary);

}


@media (max-width: 500px) {
  .checkout-flow-item-content {
    padding: 1rem 1.3rem;
  }

  .checkout-flow-item-header h2 {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .flow-stations-indicator-bar {
    margin-top: 0.5rem;
  }

}
</style>