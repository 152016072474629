<template>
  <div class="closed-notice-panel" :class="{ open: open }">
    <div class="panel-header" @click="togglePanel">
      <div class="close-background" v-if="open"></div>
      <div class="cart-icon">
        <ion-icon class="toggle-icon" v-if="open" :icon="chevronDownOutline"></ion-icon>
        <ion-icon class="toggle-icon" v-if="!open" :icon="alertOutline"></ion-icon>
      </div>
      <div class="content">
        <div class="closed-message" v-html="$t('company-is-closed-header')"></div>
      </div>
    </div>
    <div class="body" v-if="open">
      <div class="closed-message" v-html="isClosedMessage"></div>
    </div>
  </div>
</template>

<script setup >
import { IonIcon } from "@ionic/vue";
import { chevronDownOutline, alertOutline } from "ionicons/icons";

</script>

<script>
export default {
  data() {
    return {
      open: false
    };
  },
  watch: {
    open() {
      this.updateBodyClass()
    },
  },
  computed: {
    isClosedMessage() {
      if (this.company && this.company.isClosedMessage) {
        let text = this.services.translations.translation2Text(this.company.isClosedMessage);
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      } else {
        return this.$t("default-is-closed-message");
      }
    },
  },
  async mounted() {

    this.updateBodyClass()
  },
  methods: {
    updateBodyClass() {
      if (this.open) {
        document.body.classList.add("open-closed-notice-panel");
      } else {
        document.body.classList.remove("open-closed-notice-panel");
      }
    },
    togglePanel() {
      this.open = !this.open;

    },
  },
};
</script>
<style scoped>
.closed-notice-panel {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.closed-notice-panel.open {
  height: calc(100%);
}

.closed-notice-panel .panel-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closed-notice-panel .panel-header .cart-icon {
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.3);
  margin-top: -2rem;
  background-color: var(--ion-color-warning);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.closed-notice-panel .panel-header .cart-icon .toggle-icon {
  color: white;
  font-size: 2rem;
}

.closed-notice-panel .close-background {
  height: 30vh;
  min-height: 5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  position: relative;
}

.closed-notice-panel .body {
  background-color: white;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  overflow: hidden;
  overflow-y: auto;

}

.closed-notice-panel .body .closed-message {
  text-align: center;
  font-weight: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

.closed-notice-panel .panel-header .content {
  width: 100%;
  padding: 2rem 2rem 1rem 1.5rem;
  align-items: center;
  background-color: white;
  margin-top: -2rem;
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.2);
  text-align: center;
}

.closed-notice-panel.open .panel-header .content {
  padding-top: 3.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
</style>

<style>
.open-closed-notice-panel ion-content::part(scroll) {
  overflow: hidden;
}
</style>