<template>
  <div class="tabs-view-tab">
    <router-link class="tab" :to="to"  :exact="true">      
        <span class="label" v-text="label"></span>      
    </router-link>
  </div>
</template>

<script>

export default {
  name: "tabs-view-tab",
  props: ["label", "to"],
};
</script>
<style scoped>

.tabs-view-tab{
  display: flex;
}
.tab {
  margin: 0 0.1rem;
  position: relative;
  display: flex;
  padding: 1rem 0.5rem 0.7rem;
  align-items: center;
  color: var(--dark-gray);
  color: #444;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  text-transform: capitalize;
}

.tabs-view-tab.active .tab a,
.tabs-view-tab:not(.parent) .tab.router-link-active a{
  color: var(--ion-color-primary);
  font-weight: 400;
}
.tabs-view-tab.active .tab::after,
.tabs-view-tab:not(.parent) .tab.router-link-exact-active::after {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 20%;
  right: 20%;
  height: 1px;
  border-bottom: 2px solid var(--ion-color-primary);
}

@media (max-width: 600px) {
  .tab a {
    padding: 0.5rem 0.5rem 0.5rem;
    font-size: 0.8rem;
  }
}
@media (max-width: 600px) {
  .tab a {
    padding: 0.5rem 0.25rem 0.5rem;
    font-size: 0.87rem;
  }
}

</style>