import { toRefs, reactive, watch } from "vue";
import dayjs from "dayjs";
import companyService from "./companyService";
const companyServiceInstance = companyService();

import firebaseService from "./firebaseService";
const firebase = firebaseService();

let alreadyCounted = false;
const state = reactive({});

async function countScan() {
    
    if(alreadyCounted){
        return;
    }    
    let companyId = companyServiceInstance.company && companyServiceInstance.company.value ? companyServiceInstance.company.value.id : null;
    if(!companyId){
        return;
    }
    let timezone = companyServiceInstance.company.value.timezone ? companyServiceInstance.company.value.timezone : null;
    let now = dayjs().tz(timezone)
    

    let dailyKey = companyId + '-' + now.format('DD-MM-YYYY')

    firebase.firestore().collection("dailyStatistics").doc(dailyKey).set({
        scans: firebase.firebase.firestore.FieldValue.increment(1)
    }, { merge: true })
    alreadyCounted = true;   
    
}


export default function () {

    return {
        ...toRefs(state),
        countScan
    }


}