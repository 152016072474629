<template>
  <div class="product-list-item list-item" :class="{
    'zero-quantity': quantity <= 0,
    'positive-quantity': quantity > 0,
    'shows-total': showTotal,
  }" v-if="product">

    <div class="list-item-content">
      <h3 class="list-item-name">{{ services.translations.translation2Text(product.name) }}</h3>
      <div class="list-item-description"
        v-if="services.translations.translation2Text(product.description) && hasDescription == true">
        {{ services.translations.translation2Text(product.description) }}
      </div>

      <aside class="combined" v-if="isCombinedProduct">
        <div class="combined-products">
          <div class="preview">
            <div class="slot" v-for="slot of slots" :key="slot.id">
              <span class="slotname" v-text="services.translations.translation2Text(slot.name) + ':'"></span>
              <div class="product">
                <span class="productname" v-text="translatedProductName(item.chosen[slot.id])"></span>
              </div>
            </div>
          </div>

        </div>
      </aside>


      <aside class=" addons" v-if="hasAddonsSelected">

        <div class="addons">
          <div class="preview">
            <span class="cart-addons-label" v-text="$t('shopping-cart-addons-label')"
              v-if="selectedAddons.length > 0"></span>
            <div class="addon" v-for="addon, addonIndex of selectedAddons" :key="addon.id">
              <span class="addonname" v-text="services.translations.translation2Text(addon.name)"></span>
              <span class="seperator" v-if="addonIndex < selectedAddons.length - 1">,</span>


            </div>
          </div>

        </div>
      </aside>

      <div class="tags" v-if="tags && tags.length > 0">
        <div class="tag" v-for="tag of tags" :key="tag" v-text="services.translations.translation2Text(tag.name)"
          :style="{ 'background-color': tag.color + 'dd' }"></div>
      </div>
      <div class="note-buttons" v-if="withNote && !readonly">
        <ion-button class="note-button add" fill="clear" @click="addNote" v-if="!showNote"> {{ $t('order-item-add-note')
          }} </ion-button>
        <ion-button class="note-button remove" fill="clear" @click="removeNote" v-if="showNote"> {{
          $t('order-item-remove-note') }}
        </ion-button>
      </div>


    </div>

    <aside class="list-item-actions quantity" v-if="readonly">
      <div class="list-item-quantity" >
        <span class="quantity" v-text="quantity"></span>
        <span class="times">x</span>
      </div>      
    </aside>

    <aside class="list-item-actions price" v-if="readonly && price">
      <div class="list-item-price">{{ Number.parseFloat(price).toFixed(2) }}<span class="currency-symbol"
          v-text="currencySymbol"></span></div>
    </aside>
    
    
    <aside class="list-item-actions price-quantity" v-if="!readonly">
      <div class="list-item-price" v-if="price">{{ Number.parseFloat(price).toFixed(2) }}<span class="currency-symbol"
          v-text="currencySymbol"></span></div>


      <div class="quantity-input" v-if="company.isOpen && !pointofsale.closed">
        <ion-button class="count-button down" fill="clear" @click="countCartItemDown">
          <ion-icon slot="icon-only" :icon="removeOutline"></ion-icon>
        </ion-button>
        <span class="quantity" v-text="quantity"></span>
        <ion-button class="count-button up" fill="clear" @click="countCartItemUp">
          <ion-icon slot="icon-only" :icon="addOutline"></ion-icon>
        </ion-button>
      </div>
    </aside>
    <aside class="list-item-actions total" v-if="showTotal">
      <div class="list-item-total" v-if="price">
        {{ Number.parseFloat(price * quantity).toFixed(2) }}<span class="currency-symbol"
          v-text="currencySymbol"></span>
      </div>
    </aside>
    <aside class="list-item-actions note below" v-if="showNote">
      <textarea auto-grow outlined rows="1" :label="$t('shopping-cart-input-label-item-note')"
        v-model="comment"></textarea>
    </aside>

  </div>
</template>

<script setup>
import { IonButton, IonIcon } from "@ionic/vue";
import { addOutline, removeOutline } from "ionicons/icons";

</script>

<script>
export default {
  props: ["item", "table", "showTotal", "withNote", "hasDescription", "cartItem", "readonly"],
  data() {
    return {
      showNote: false,
      comment: "",
      chosenProducts: {}
    };
  },
  watch: {
    comment: function () {
      this.services.cartService.changeNote(this.product, this.comment);
    },
  },
  computed: {
    price() {
      if (!this.product) {
        return 0
      }
      let productPriceWithAddonsAndCorrections = Number.parseFloat(this.product.price)

      if (this.item.addons && Object.values(this.item.addons).length > 0) {
        for (let addonId in this.item.addons) {
          let addon = null;
          if (!this.item.addons[addonId]) {
            continue
          }

          for (let item of this.product.addons) {
            if (item.id == addonId) {
              addon = item;
              break;
            }
          }
          if (!addon) {
            continue
          }
          if (addon.price && Number.parseFloat(addon.price)) {
            productPriceWithAddonsAndCorrections += Number.parseFloat(addon.price);
          }
        }
      }

      if (this.product.type == 'combined' && this.product.slots && this.product.slots.length > 0) {
        for (let slot of this.product.slots) {
          let productId = this.item.chosen[slot.id] ? this.item.chosen[slot.id] : null;
          if (productId && slot.availableProducts[productId] && slot.availableProducts[productId].correction) {
            if (slot.availableProducts[productId].correction) {
              productPriceWithAddonsAndCorrections += Number.parseFloat(slot.availableProducts[productId].correction)
            }
          }
        }
      }



      return Number.parseFloat(productPriceWithAddonsAndCorrections)
    },
    isCombinedProduct() {
      return this.product && (this.product.type == 'combined')
    },
    hasDetails() {
      return this.isCombinedProduct || this.hasAddons
    },
    hasAddons() {
      return this.product && this.product.addons && Object.keys(this.product.addons).length > 0
    },
    hasAddonsSelected() {
      return this.item && this.item.addons && Object.keys(this.item.addons).length > 0
    },
    selectedAddons() {
      if (!this.hasAddonsSelected) {
        return []
      }
      let addons = []
      for (let addon of this.product.addons) {
        if (this.item.addons[addon.id]) {
          addons.push(addon);
        }
      }
      return addons

    },
    slots() {
      if (!this.isCombinedProduct) {
        return [];
      }

      let slots = JSON.parse(JSON.stringify(this.product.slots));

      let validSlots = [];
      for (let slot of slots) {
        if (!slot.availableProducts) {
          continue
        }
        let products = [];
        for (let productLine of Object.values(slot.availableProducts)) {
          if (productLine.hidden) { continue }

          let product = productLine.productId ? this.services.company.getProductById(productLine.productId) : null;
          if (!product) { continue }

          products.push(product);
        }
        slot.products = products;

        if (slot.products.length < 1) {
          continue
        }
        validSlots.push(slot);
      }
      return validSlots;
    },
    quantity() {
      if (!this.product) {
        return 0;
      }
      return this.services.cartService.getQuantityForProduct(this.item);
    },
    product() {
      if (!this.item || !this.item.productId) {
        return null;
      }
      return this.services.company.getProductById(this.item.productId)
    },


    tags() {
      let tags = [];
      if (!this.product.tags || this.product.tags.length <= 0) {
        return tags;
      }
      for (let tag of this.company.tags) {
        if (this.product.tags.includes(tag.id)) {
          tags.push(tag);
        }
      }
      return tags;
    },
  },
  async mounted() {
  },
  methods: {
    translatedProductName(productId) {
      if (!productId) {
        return ''
      }
      let product = this.services.company.getProductById(productId);
      if (!product) {
        return ''
      }
      return this.services.translations.translation2Text(product.name)
    },
    countCartItemDown() {
      this.services.cartService.countCartItemDown(this.item);
    },
    countCartItemUp() {
      this.services.cartService.countCartItemUp(this.item);
    },
    addNote() {
      this.showNote = true;
      this.comment = "";
    },
    removeNote() {
      this.showNote = false;
      this.comment = "";
    },
  },
};
</script>
<style scoped>
.product-list .list-item {
  padding-bottom: 1rem;
  display: flex;
  margin: 1.5rem 0rem 0.5rem;
  border-bottom: 1px solid var(--ion-color-light-shade);
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.product-list .list-item:last-child {
  border-bottom: none;
}

.product-list .list-item .list-item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.product-list .list-item .list-item-name {
  font-size: 1.1rem;
  font-weight: 500;
  padding-bottom: 0em;
  margin-bottom: 0em;
  margin-top: 0;
  text-align: left;
}


.product-list .list-item .list-item-description {
  font-size: 0.9rem;
  color: var(--ion-color-dark);
  margin-top: 0.5rem;
  display: block;
}

.product-list .list-item .list-item-description::first-letter {
  text-transform: uppercase;
}

.product-list .list-item.price-quantity .list-item-price {
  font-size: 1.1rem;
  font-weight: 500;
  padding-bottom: 0.4em;
  text-align: center;
}

.product-list .list-item .list-item-actions {
  margin-left: 1rem;
}

.product-list .list-item .quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-list .list-item .quantity-input ion-button {
  margin: 0;
  display: flex;
  align-items: center;
}

.product-list .list-item .quantity-input ion-button::part(native) {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 2rem;
  background-color: var(--ion-color-light);
  border: none;
  border: 5px solid white;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.1rem;
  font-size: 0.7rem;
  padding-bottom: 0.15rem;
}

.product-list .list-item .quantity-input .button-view.up .label {
  position: relative;
  top: 1px;
}

.product-list .list-item .quantity-input .quantity {
  font-size: 1.1rem;
  position: relative;
  bottom: 0.06rem;
  margin: 0.1rem -2px;
  min-width: 1.5rem;
  text-align: center;
}

.product-list .list-item.positive-quantity .quantity-input .quantity {
  font-weight: 500;
  color: var(--ion-color-primary);
}

.product-list .list-item.shows-total .list-item-price {
  font-size: 1rem;
  font-weight: normal;
}

.product-list .list-item.shows-total .list-item-actions.total {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  justify-content: flex-end;
  min-width: 6rem;
}

.product-list .list-item.shows-total .list-item-total {
  font-size: 1.2rem;
  font-weight: 500;
}

.product-list .list-item .list-item-actions.price {
  
}

.product-list .list-item.shows-total .list-item-actions.note.below {
  display: block;
  flex-basis: 100%;
  margin-top: 0.4rem;
  margin-left: 0rem;
  margin-bottom: 0.6rem;
}

.product-list .list-item.shows-total .list-item-actions.note textarea {
  width: 100%;
  min-height: 3rem;
}

.note-button {
  margin-left: -0.8rem;
  font-size: 0.8rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.3rem;
}

.tags .tag {
  border-radius: 1rem;
  font-size: 0.7rem;
  color: var(--ion-color-primary);
  padding: 0.1rem 0.4rem 0.2rem 0;
  text-align: center;
  font-weight: 599;
  margin-right: 0.3rem;
}

@media (max-width: 500px) {
  .product-list .list-item .list-item-name {
    font-size: 1rem;
  }

  .product-list .list-item.shows-total .list-item-total {
    font-size: 1rem;


  }

}


@media (max-width: 450px) {
  .product-list .list-item.shows-total .list-item-actions.total {
    flex-basis: 100%;
    margin-top: 0.7rem;
    margin-top: 0.7rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

.combined {
  flex-basis: 100%;
  width: 100%;
  margin-left: 0 !important;
}

.combined-products .preview {
  margin-top: 0.4rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  text-align: left;
}

.combined-products .preview .slotname {
  font-weight: 500;
  font-size: 0.8rem;
  display: inline;
  text-transform: capitalize;
  display: inline-block;
}

.combined-products .preview .slotname::first-letter {
  text-transform: uppercase;
}

.combined-products .preview .product {
  display: inline-block;
  padding-left: 0.2rem;
}

.combined-products .preview .productname {
  font-size: 0.8rem;
}

.combined-products .preview .seperator.lastone {
  font-size: 0.8rem;
  display: inline-block;
  padding-left: 0.2rem;
}

.addons .preview {
  margin-top: 0.4rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  text-align: left;

}


.addons .preview .addon {
  display: inline-block;
  padding-left: 0.2rem;
}

.addons .preview .addonname {
  font-size: 0.8rem;
}



.combined-products .details {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;

}

.combined-products .details .slot {
  margin-bottom: 1rem;
}

.combined-products .details .slotname {
  margin-top: 1rem;

  padding-bottom: 0.2rem;
  display: block;
  font-weight: 300;
  font-size: 1.1rem;
}

.combined-products .details .product {

  padding-left: 0.2rem;
}

.combined-products .details .productname {
  font-size: 0.8rem;
}

.combined-products .details .seperator.lastone {
  font-size: 0.8rem;
  display: inline-block;
  padding-left: 0.2rem;
}

.cart-addons-label {
  font-size: 0.8rem;
}
</style>
