<template>
  <div class="checkout-flow-item cart-overview">
    <div class="checkout-flow-item-header">
      <h2 v-if="pointofsale && pointofsale.enableCheckout" v-text="$t('shopping-cart-step-overview-title')"></h2>
      <h2 v-if="pointofsale && !pointofsale.enableCheckout" v-text="$t('shopping-cart-step-whishlist-title')"></h2>
    </div>    
    <div class="checkout-flow-item-content">
      <div class="no-products-notice" v-if="totalNumberOfItems == 0" v-text="$t('shopping-cart-no-products')"></div>

      <div class="product-list">
        <cart-list-item v-for="item of itemsInCart" :key="item.id" :item="item" :show-total="true" :withNote="false"
          :hasDescription="false"></cart-list-item>
      </div>

      <cost-breakdown-view :show-estimate="!pointofsale.enableCheckout"
        v-if="totalNumberOfItems > 0"></cost-breakdown-view>
    </div>
    <div class="checkout-flow-item-footer" v-if="pointofsale.enableCheckout">
      <div class="flow-navigation-buttons">
        <ion-button class="back-button" color="primary" expand="block" @click="closePanel" :size="frameWidth < 350 ? 'small': 'default'">
          <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
          <span class="label">{{ $t("step-flow-close-button-label") }}</span>
        </ion-button>
        <ion-button class="next-button" :disabled="totalNumberOfItems == 0"
          expand="block"
           @click="$emit('next')" :size="frameWidth < 350 ? 'small': 'default'">
          <span class="label">{{ $t("step-flow-cart-overview-next-button-label") }}</span>
          <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import cartService from "../composables/cartService";
import CartListItem from "./CartListItem.vue";
import CostBreakdownView from "./CostBreakdownView.vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";

</script>
<script>
export default {
  props: ['step'],
  computed: {
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    itemsInCart() {
      return this.services.cartService.itemsInCart();
    }    
  },
  methods: {
    closePanel() {
      this.$router.push({
          name: this.$route.name.replace("-cart", ""),
          params: this.$route.params,
        });
    },  
  },
};
</script>
<style scoped>
.product-list-item:last-child {
  border-bottom: 1px solid var(--ion-color-dark-shade);
  margin-bottom: 1rem;
}
</style>