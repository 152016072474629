<template>
  <div class="checkout-flow-item final-report">
    <div class="checkout-flow-item-header">
      <h2 v-text="$t('step-flow-cart-payment-title')"></h2>
    </div>
    <div class="checkout-flow-item-content">
      <ion-radio-group class="payment-type-group" v-model="services.cartService.paymentType.value">
        <ion-list-header>
          <ion-label v-text="$t('step-flow-payment-options-label')"></ion-label>
        </ion-list-header>
        <ion-item lines="full">          
          <ion-radio mode="md" slot="start" value="online" 
          label-placement="end" :alignment="'center'" justify="start"
          v-text="$t('step-flow-payment-options-online-label')"
          ></ion-radio>
        </ion-item>
        <ion-item lines="full"
          v-if="services.cartService.deliveryType.value != 'delivery' || pointofsale.allowDeliveryOnInvoice"
        >
          <ion-radio mode="md" slot="start" value="offline"
          label-placement="end" :alignment="'center'" justify="start"
          v-text="services.cartService.deliveryType.value == 'delivery' ? $t('step-flow-payment-options-invoice-label') :  $t('step-flow-payment-options-offline-label')"></ion-radio>
        </ion-item>
      </ion-radio-group>    
    </div>
    <div class="checkout-flow-item-footer">
      <div class="flow-navigation-buttons">        
        <ion-button class="back-button" color="medium" expand="block" @click="gotoPreviousStep" :size="frameWidth < 350 ? 'small': 'default'">
          <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
          <span class="label">{{ $t("step-flow-back-button-label") }}</span>
        </ion-button>        
        <ion-button class="next-button" :disabled="totalNumberOfItems == 0" expand="block" @click="$emit('next')"
          :size="frameWidth < 350 ? 'small' : 'default'">
          <span class="label">{{ $t("step-flow-cart-notes-next-button-label") }}</span>
          <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  IonButton,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonListHeader
} from "@ionic/vue";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";


</script>

<script>

export default {
  props:['step'],
  data() {
    return {
      errors: {}
    };
  },
  watch: {
    $route: function () { },
  },
  computed: {
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },

    pointofsaleId() {
      return this.$route.params["pointofsaleId"] ? this.$route.params["pointofsaleId"] : null;
    },
  },
  async mounted() { },
  methods: {
    gotoPreviousStep() {
      this.$emit("previous");
    }    
  },
};
</script>
<style scoped>

ion-radio-group{
  margin-top: 0rem;
  margin-bottom: 2rem;
}
ion-list-header {
  min-height: auto;
  margin-bottom: 0.3rem;
}
ion-list-header ion-label {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;

}


</style>