<template>
  <div class="oido-slotted-date-picker"
    :class="{ 'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled }">

    <slot name="before"></slot>
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>
    <label :for="componentId">
      <span class="label-text" v-text="label"></span>
      <span class="required-marker" v-if="required"> *</span>
      <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText"
        :position="helpTextPosition"></oido-help-icon>
      <span class="spacer"></span>
      <oido-button icon="delete" @click="clear" class="clear-button" v-if="nullable"
        :label="$t('oido-date-picker-clear-label')" :disabled="!hasValue"></oido-button>
    </label>


    <div class="input-content" v-if="!disabled">      
      <nav class="day-selector">
        <oido-button class="prev selector-nav-button" icon="chevron-back" @click="showPreviousDays"></oido-button>
        <div class="days" ref="vertical-scroll-container">
          <div class="day" v-for="day of days" :key="day.date.toISOString()"
            :class="{ 'isToday': day.isToday, 'isSelectable': day.isSelectable, 'selected': day.selected }"
            @click="selectDay(day)">
            <div class="weekday" v-text="day.date.format('ddd')"></div>
            <div class="day-label" v-text="day.date.format('DD')"></div>
            <div class="month" v-text="day.date.format('MMM')"></div>
          </div>
        </div>
        <oido-button class="next selector-nav-button" icon="chevron-forward" @click="showNextDays"></oido-button>
      </nav>

      <div class="slots ">
        <div class="slot" v-for="slot of slotsForThatDay" :key="slot.start.toISOString()"          
          :class="{ 'selected': selectedDate && selectedDate.isSame(slot.start),'isInThePast':slot.isInThePast ,'isSelectable': slot.isSelectable }"
          @click="selectSlot(slot)">
          <div class="label" v-text="slot.start.tz(company.timezone).format('HH:mm')"></div>
          <div class="percentage" v-if="slot.allocationPercentage && slot.allocationPercentage > 0" :style="'width:' + slot.allocationPercentage + '%;'"></div>
        </div>
      </div>
      <span v-if="false">
        {{ firstAllowedPickupOrDeliveryDate.tz(company.timezone).format("DD.MM.YYYY HH:mm") }}
      </span>

    </div>
    <slot name="after"></slot>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';

import dayjs from "dayjs";

import OidoHelpIcon from './OidoHelpIcon.vue';
import OidoButton from './OidoButton.vue';


</script>
<script>


export default {

  data() {
    return {
      localValue: null
    };
  },
  mounted() {
    this.updateLocalValue()
    this.$nextTick(() => {
      this.scrollToActiveOrFirstSelectableDay()
    })
  },
  props: {
    modelValue: String,
    errors: [Object],
    name: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    nullable: Boolean,
    helpText: String,
    helpTextPosition: String,
    isDateEnabled: Function
  },
  watch: {
    modelValue() {
      this.updateLocalValue();
    },
    localValue() {
      if (this.localValue != this.modelValue) {
        this.valueChanged()
      }
    },
    // days:{
    //   deep:true,
    //   handler:  function (newValue, oldValue) {
    //     console.log('newValue',newValue)
    //     console.log('oldValue',oldValue)
    //     this.$nextTick(() => {
    //       this.scrollToActiveOrFirstSelectableDay()

    //     })        
    //   }
    // }

  },
  computed: {
    
    allowedNumberOfOrdersPerSlot() {
      return this.company.allowedNumberOfOrdersPerSlot
    },
    firstAllowedPickupOrDeliveryDate() {
      return this.services.cartService.firstAllowedPickupOrDeliveryDate.value
    },
    selectedDate() {
      return this.localValue ? dayjs(this.localValue) : null;
    },
    days() {
      let days = []

      let now = dayjs();
      for (let i = 0; i < 100; i++) {

        let date = now.add(i, 'days').startOf('day')

        let slots = this.services.cartService.getSlotsForDay(date)

        // console.log('slots',slots)

        let selectableSlots = slots.filter((slot) => Boolean(slot.isSelectable))
        days.push({
          selected: this.selectedDate && this.selectedDate.isSame(date, 'day'),
          isToday: date.isSame(now, 'day'),
          date: date,
          label: date.format('DD.MM.YYYY'),          
          isSelectable: selectableSlots.length > 0 && date.isSameOrAfter(this.firstAllowedPickupOrDeliveryDate, 'day'),
          slots: slots,
          hours: []
        })
      }
      return days
    },
    componentId() {
      return this.name + '-' + uuidv4()
    },
    hasValue() {
      return this.localValue !== null
    },
    slotsForThatDay() {
      return this.services.cartService.getSlotsForDay(this.localValue)
    }
  },

  methods: {
    
    scrollToActiveOrFirstSelectableDay() {      
      let scrollContainer = this.$refs['vertical-scroll-container']
      let activeSelectableDay = scrollContainer.querySelector('.day.isSelectable.selected')
      let firstSelectableDay = scrollContainer.querySelector('.day.isSelectable')

      let dayImInterestedIn = activeSelectableDay || firstSelectableDay

      let previousDay = dayImInterestedIn ? dayImInterestedIn.previousSibling : null

      let elementToScrollTo = previousDay || dayImInterestedIn

      if (elementToScrollTo != null) {
        scrollContainer.scrollLeft = elementToScrollTo.offsetLeft - scrollContainer.offsetLeft - 3
      }
    },
    showNextDays() {
      let scrollContainer = this.$refs['vertical-scroll-container']
      let foldPosition = { x: scrollContainer.getBoundingClientRect().x + scrollContainer.clientWidth - 1, y: scrollContainer.getBoundingClientRect().y + scrollContainer.clientHeight / 2 }
      let elementAtTheFold = document.elementFromPoint(foldPosition.x, foldPosition.y)
      let dayElement = elementAtTheFold.closest('.day')
      if (dayElement != null) {
        scrollContainer.scrollLeft = dayElement.offsetLeft - scrollContainer.offsetLeft - 3
      } else {
        scrollContainer.scrollLeft = scrollContainer.scrollLeft + scrollContainer.clientWidth
      }

    },
    showPreviousDays() {
      let scrollContainer = this.$refs['vertical-scroll-container']
      scrollContainer.scrollLeft = scrollContainer.scrollLeft - scrollContainer.clientWidth
      let foldPosition = { x: scrollContainer.getBoundingClientRect().x + 1, y: scrollContainer.getBoundingClientRect().y + scrollContainer.clientHeight / 2 }
      let elementAtTheFold = document.elementFromPoint(foldPosition.x, foldPosition.y)
      let dayElement = elementAtTheFold.closest('.day')

      //this time we scroll to the first not cropped element
      let nextDayElement = dayElement ? dayElement.nextSibling : null
      if (nextDayElement != null) {
        scrollContainer.scrollLeft = nextDayElement.offsetLeft - scrollContainer.offsetLeft - 3
      }
    },
    selectDay(day) {
      if (!day || !day.isSelectable) {
        return
      }
      if (!day.slots || day.slots.length == 0) {
        console.error('no slot found', day.date)
        return
      }
      //look for the first selectable slot and use it as 
      for (let slot of day.slots) {
        if (slot.isSelectable) {
          this.localValue = slot.start
          break
        }

      }
    },
    selectSlot(slot) {
      if (!slot.isSelectable) {
        return
      }
      this.localValue = slot.start
    },
    clear() {
      this.localValue = null
    },
    updateLocalValue() {
      let formattedModelValue = this.modelValue ? dayjs(this.modelValue).format("YYYY-MM-DDTHH:mm:ss") : null
      if (this.localValue !== formattedModelValue) {
        this.localValue = formattedModelValue
      }
    },
    valueChanged() {
      let isoLocalValue = this.localValue ? dayjs(this.localValue).tz(this.company.timezone).toISOString() : null
      this.$emit("update:modelValue", isoLocalValue);
    }
  }
};
</script>


<style scoped>
.oido-slotted-date-picker {
  width: 100%;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  font-size: 0.9rem;
}

label {
  font-weight: 590;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #222;
  margin-bottom: 0.2rem;
}

.readonly label {
  font-weight: 400;
}

label .oido-help-icon {
  white-space: initial
}


.input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-content .input-section {
  flex-basis: 50%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.3rem;
}

.validation-errors {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.validation-errors {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  text-align: right;
}

@media (max-width: 500px) {
  .validation-errors {
    text-align: left;
  }
}


label .spacer {
  flex: 1;
  display: inline-block;
}


.day-selector {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  display: block;
  width: 100%;

  height: 90px;
}

.day-selector .oido-button.selector-nav-button {
  width: 2rem;
  height: 100%;
  display: inline-block;
  line-height: 95px;
  font-size: 3rem;
  vertical-align: top;
  color: #333;
}





.day-selector .days {
  display: inline-block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;

  width: calc(100% - 4rem);

}

.day-selector .days .day {
  display: inline-block;
  margin: 10px 0.3rem;
  border: 1px solid #ddd;
  padding: 0.5rem;
  width: 50px;
  text-align: center;
  cursor: pointer;
}


.day-selector .days .day .weekday {
  text-transform: uppercase;
  font-size: 0.7rem;
}

.day-selector .days .day .month {
  color: #666;
  font-size: 0.7rem;
}

.day-selector .days .day .day-label {
  font-size: 1.2rem;
  font-weight: bold;
}


.day-selector .days .day:not(.isSelectable),
.day-selector .days .day:not(.isSelectable) * {

  cursor: default;
  -webkit-user-select: none;
  /* Safari */
  user-select: none;
}

.day-selector .days .day:not(.isSelectable) .day-label,
.day-selector .days .day:not(.isSelectable) .weekday,
.day-selector .days .day:not(.isSelectable) .month {
  color: #aaa;
}

.day-selector .days .day.isToday {
  border: 1px solid #bbb;
}

.day-selector .days .day.selected {
  border: 1px solid var(--ion-color-primary);
  color: white;
  background-color: var(--ion-color-primary);
}

.day-selector .days .day.selected .month {
  color: #eee;
}

.slots {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  align-content: space-between;

}

.slots .slot {
  position: relative;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #999;
  cursor: pointer;
  font-weight: 599;
  width: calc(20% - 1rem);
  min-width: 4rem !important;
  -webkit-user-select: none;
  /* Safari */
  user-select: none;

}

@media (max-width: 600px) {
  .slots .slot {
    width: calc(25% - 1rem);
  }
}

@media (max-width: 500px) {
  .slots .slot {
    width: calc(33.3% - 1rem);
  }
}

@media (max-width: 360px) {
  .slots .slot {
    width: calc(50% - 1rem);
  }
}

.slots .slot:not(.isSelectable){
  pointer-events: none;
  cursor: default;
  color: #aaa;
  border: 1px solid #ddd;
}

.slots .slot.isInThePast{
  display: none;
}

.slots .slot.selected {
  border: 1px solid var(--ion-color-primary);
  color: white;
  background-color: var(--ion-color-primary);
}


.slots .slot .percentage{
  position: absolute;
  height: 100%;
  background-color: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
}
</style>
