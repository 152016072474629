import { toRefs, reactive, watch } from "vue";

import cartService from "./cartService";
import customerService from "./customerService";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

const customerServiceInstance = customerService();
const cartServiceInstance = cartService();
import companyService from "./companyService";



const companyServiceInstance = companyService();

import axios from "axios";
import config from "../../config"


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    paymentIntentRequestSignal: null,
    sessionCheckRequestSignal: null,
    orders: {}
});

async function createPaymentIntent(pointofsaleId) {

    let customerId = customerServiceInstance.customerId ? customerServiceInstance.customerId.value : null;
    let items = cartServiceInstance.items ? cartServiceInstance.items.value : null
    if (!items || !customerId) {
        throw new Error("createPaymentIntent: no Customer and/or cartitems");
    }
    //make a copy of the items (we modify it before sending )
    items = JSON.parse(JSON.stringify(items))
    
    
    let itemsByCourse = cartServiceInstance.itemsByCourse ? cartServiceInstance.itemsByCourse.value : null
    if (!itemsByCourse) {
        throw new Error("createPaymentIntent: no itemsByCourse");
    }
    


    // delete the product info from the cartItem
    // delete any serviceInformation from it
    for (let itemId in items) {
        delete items[itemId].exploded
        delete items[itemId].product
        delete items[itemId].serviceInformation
    }


    for (let courseId in itemsByCourse) {
        let itemsOfCourse = itemsByCourse[courseId]

        for (let itemOfCourse of itemsOfCourse) {
            if (!itemOfCourse.cartItemId) {
                //use the old system
                //TODO: remove after some time
                // console.log('OLD SYSTEM')
                itemOfCourse.cartItemId = itemOfCourse.parentHash ? itemOfCourse.parentHash : itemOfCourse.productId;
            }

            let item = items[itemOfCourse.cartItemId]

            if (!item) {
                console.log("createPaymentIntent: no item for itemOfCourse", itemOfCourse);
                throw new Error("createPaymentIntent: no item for itemOfCourse", itemOfCourse);
            }

            if (!item.serviceInformation) {
                item.serviceInformation = {}
            }
            let key = itemOfCourse.parentId ? itemOfCourse.parentId + '-' + itemOfCourse.productId : itemOfCourse.productId;

            if (!item.serviceInformation[key]) {
                item.serviceInformation[key] = []
            }

            item.serviceInformation[key].push({
                note: itemOfCourse.note ? itemOfCourse.note : null,
                courseId: courseId,
            })
        }
    }

    let pointofsale = companyServiceInstance.getPointofsaleById(pointofsaleId);
    if (!pointofsale) {
        console.log("No point of sale found ", pointofsaleId)
        return
    }
    
    let company = companyServiceInstance.company ? companyServiceInstance.company.value : null;
    if (!company) {
        console.log("No company found ", company)
        return
    }
    
    
    let deliveryType = cartServiceInstance.deliveryType ? cartServiceInstance.deliveryType.value : "takeaway"    

    let address = deliveryType == "delivery" && cartServiceInstance.address ? cartServiceInstance.address.value : null
    let pickupTimeMode = deliveryType != "table" && cartServiceInstance.pickupTimeMode ? cartServiceInstance.pickupTimeMode.value : null
    let pickupTime = deliveryType != "table" && cartServiceInstance.pickupTime ? cartServiceInstance.pickupTime.value : null
    let note = cartServiceInstance.note ? cartServiceInstance.note.value : null
    let paymentType = cartServiceInstance.paymentType ? cartServiceInstance.paymentType.value : null


    let collectsPhoneNumber = company.sendSMSOnOrderReady || pointofsale.collectPhoneNumber
    let phone =  collectsPhoneNumber && cartServiceInstance.phone ? cartServiceInstance.phone.value : null
    

    let voucher = cartServiceInstance.voucher ? cartServiceInstance.voucher.value : null
    let discounts = cartServiceInstance.discounts ? cartServiceInstance.discounts.value : null

    if (paymentType == "online" && !pointofsale.enableOnlinePayment) {
        paymentType = null
    }

    if (paymentType == "offline" && !pointofsale.enableOfflinePayment || !company.enableOfflinePayments) {
        paymentType = null
    }

    if(deliveryType == "delivery" && !pointofsale.allowDeliveryOnInvoice ){
        paymentType = null
    }



    if (!paymentType) {
        if (pointofsale.enableOnlinePayment) {
            paymentType = "online";
        } else if (pointofsale.enableOfflinePayment && company.enableOfflinePayments) {
            paymentType = "offline";
        }
    }

    if(!paymentType){
        console.log('missing payment type');
        return;
    }

    if (state.paymentIntentRequestSignal) {
        console.log('already requesting paymentIntent');
        return;
    }

    
    let companyId = company ? company.id : null;
    let tipPercentage = cartServiceInstance.tipPercentage ? cartServiceInstance.tipPercentage.value : 0;

    let postData = {
        companyId,
        customerId,
        items: items,
        tipPercentage,
        pointofsaleId,
        zoneId: pointofsale.zoneId,
        deliveryType,
        pickupTimeMode,
        pickupTime,
        note,
        voucher,
        discounts,
        address,
        paymentType,
        phone: phone
    }

    let isInIframe = window !== window.parent;      
    //isInIframe = true

    if (isInIframe) {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", config.apiUrl + "/api/stripe/paymentIntent");
        postData['isInIframe'] = true
        for(let key in postData){
            console.log("postData",key, postData[key])
            var hiddenField = document.createElement("input");            
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", typeof postData[key] == "object" ? JSON.stringify(postData[key]) : postData[key]);            
            form.appendChild(hiddenField);
        }
        form.setAttribute("target", "oido-" + pointofsaleId);
        document.body.appendChild(form); // Not entirely sure if this is necessary          
        form.submit();
        return null
    }

    const abortController = new AbortController();
    state.paymentIntentRequestSignal = abortController.signal;

    let response = await axios.post(config.apiUrl + "/api/stripe/paymentIntent", postData, {});
    state.paymentIntentRequestSignal = null;

    if (!response || !response.data) {
        throw new Error("createPaymentIntent: invalid responsedata");
    }

    if (response.data.error) {
        console.log(response.data.error, response.data.error)
        throw new Error(response.data.error)
    }

    return response.data.url;
}

async function fillCartFromSession(sessionId) {
    console.log('sessionId',sessionId)
    let companyId = companyServiceInstance.company ? companyServiceInstance.company.value.id : null;
    if(!companyId){
        console.log("fillCartFromSession: Company not found")
        return 
    }
    if(!sessionId){
        console.log("fillCartFromSession: no session given")
        return 
    }
    try {
        let response = await axios.post(config.apiUrl + `/api/stripe/sessionid2paymentrequest/${companyId}/${sessionId}`, {}, {});        
        console.log("response",response)
        
    } catch (error) {
        console.log('fillCartFromSession',error)
        
    }
}
async function checkOrderState(sessionId) {

    console.log('loading order', sessionId);

    if (!state.orders || state.orders[sessionId]) {
        console.log('already loaded order', state);
        return;
    }

    if (!sessionId) {
        throw new Error("checkOrderState: no sessionId");
    }


    let orderUnsubscribeHandle = firebase
        .firestore()
        .collection("orderInfos")
        .doc(sessionId)
        .onSnapshot((snapshot) => {
            let data = snapshot.data()
            console.log('orderInfos data', data, snapshot, sessionId)
            if (data && snapshot.id) {
                //remove any cart Content
                console.log('emptyTheCart')
                cartServiceInstance.emptyTheCart();
                data.id = snapshot.id
                state.orders[snapshot.id] = data;
            }
        })
    unsubscribeHandles.push(orderUnsubscribeHandle);


}


export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        state.initialized = true
        state.initializing = false
    }


    return {
        ...toRefs(state),
        createPaymentIntent,
        checkOrderState,
        fillCartFromSession

    }


}