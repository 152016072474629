<template>
  <div class="order-item-list-item list-item">
    <div class="list-item-content">
      <span class="quantity" v-text="item.quantity"></span>
      <span class="times-sign">x</span>
      <span class="list-item-name" v-if="product">{{ services.translations.translation2Text(product.name) }}</span>
      <span class="list-item-name" v-if="!product && item.name">{{ services.translations.translation2Text(item.name) }}</span>
      <div class="list-item-total" v-if="item.subtotal">
        {{ Number.parseFloat(item.subtotal).toFixed(2) }}<span class="currency-symbol" v-text="currencySymbol"></span>
      </div>
      <div class="list-item-total" v-if="!item.subtotal && item.price">
        {{ Number.parseFloat(item.price).toFixed(2)  }}<span class="currency-symbol" v-text="currencySymbol"></span>
      </div>
    </div>

    <aside class="note" v-if="item.note" v-text="item.note"></aside>
  </div>
</template>

<script setup>

</script>
<script>
export default {
  props: ["item"],
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    product() {
      if (!this.item || !this.item.productId) {
        return null;
      }
      return this.companyService.getProductById(this.item.productId);
    },
  },
  async mounted() {},
  methods: {},
};
</script>
<style scoped>
.list-item {
  padding-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.list-item:last-child {
  border-bottom: none;
}

.list-item .list-item-content {
  flex: 1;
  display: flex;
  align-items: baseline;
}
.list-item .times-sign {
  margin-left: 0.1rem;
  margin-right: 0.5rem;
}
.list-item .list-item-name {
  font-size: 1.1rem;
  font-weight: 500;
  flex: 1;
}

.list-item .list-item-total {
  font-weight: 500;
}
.list-item .note {
  flex-basis: 100%;
  padding: 0.2rem 0 0.5rem;
  font-style: italic;
  font-weight: 200;
}
</style>
