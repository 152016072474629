<template>

  <div class="shopping-cart-panel" :class="{ empty: totalNumberOfItems <= 0, open: open }">
    <div class="close-background" v-if="open" @click="togglePanel"></div>
    <div class="panel-body">
      <div class="panel-header" @click="togglePanel">

        <div class="cart-icon">
          <span class="badge" v-if="totalNumberOfItems > 0 && !open" v-text="totalNumberOfItems"></span>
          <ion-icon v-if="open" class="toggle-icon" :icon="chevronDownOutline"></ion-icon>
          <ion-icon v-if="!open && pointofsale && pointofsale.enableCheckout" class="toggle-icon"
            :icon="cartOutline"></ion-icon>
          <img class=" toggle-icon svg-icon" v-if="!open && pointofsale && !pointofsale.enableCheckout"
            src="/assets/images/readonly-menu-icon.svg" />
        </div>

      </div>
      <check-out-flow class="body" v-if="open"></check-out-flow>
    </div>
    <div class="footer-bar">
      <div class="footer-links left">
        <a class="privacy footer-link" target="_blank" :href="privacyLink" v-text="$t('footer-link-privacy-label')"></a>
        <a class="terms footer-link" target="_blank" :href="termsLink" v-text="$t('footer-link-terms-label')"></a>
      </div>

      <div class="footer-links right">
        <span class="contact footer-link" target="_blank" @click.stop="showContactPanel"
          v-text="$t('footer-link-contact-label')"></span>
        <span class="version footer-link" v-text="'v' + version"></span>

      </div>

    </div>
  </div>

</template>

<script setup>
import { IonIcon } from "@ionic/vue";
import { cartOutline, chevronDownOutline } from "ionicons/icons";
import CheckOutFlow from "./CheckOutFlow.vue";
</script>


<script>


export default {
  watch: {
    open() {
      this.updateBodyClass()
    },
  },
  computed: {
    totalNumberOfItems() {
      return this.services.cartService.totalNumberOfItems();
    },
    open() {
      return this.$route.name.includes("-cart");
    },
    privacyLink() {
      let link = this.company.customPrivacyUrl ? this.company.customPrivacyUrl : this.$t("footer-link-privacy-default-url");            
      if(!link.startsWith('http')){
        link = 'https://' + link
      }
      return link
    },
    termsLink() {
      let link =  this.company.customTermsUrl ? this.company.customTermsUrl : this.$t("footer-link-terms-default-url");
      if(!link.startsWith('http')){
        link = 'https://' + link
      }
      return link
    },
  },
  async mounted() {
    this.updateBodyClass()
  },
  methods: {
    showContactPanel() {
      this.$router.push({
        name: "contact",
        params: { ...this.$route.params },
      });
    },
    updateBodyClass() {
      if (this.open) {
        document.body.classList.add("open-shopping-cart-panel");
      } else {
        document.body.classList.remove("open-shopping-cart-panel");
      }
    },
    togglePanel(e) {

      if (e.target.nodeName == "A") {
        return
      }

      if (this.$route.name.includes("-cart")) {
        this.$router.push({
          name: this.$route.name.replace("-cart", ""),
          params: this.$route.params,
        });
      } else {
        this.$router.push({
          name: "menu-cart",
          params: { ...this.$route.params, step: "cart-overview" },
        });
      }      
    },
  },
};
</script>
<style scoped>
.shopping-cart-panel {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.shopping-cart-panel.open {
  top: 0;
}


.shopping-cart-panel.open .panel-body {
  height: calc(100% - 100px);
  position: relative;
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.3);
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 100px auto 0;
}

.panel-body {
  z-index: 200;
  width: 100%;
  max-width: 800px;
  margin: 0px auto 0;

  position: relative;
  display: flex;
  flex-direction: column;
}

.shopping-cart-panel .panel-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.shopping-cart-panel .panel-header .cart-icon {
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.3);
  margin-top: -2rem;
  background-color: var(--ion-color-primary);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.shopping-cart-panel .panel-header .cart-icon .toggle-icon {
  color: white;
  font-size: 2rem;
}

.shopping-cart-panel:not(.open) .cart-icon {
  margin-bottom: 10px;
}




.shopping-cart-panel .panel-header .cart-icon .badge {
  color: white;
  background-color: var(--ion-color-primary);
  border: 1px solid white;
  position: absolute;
  top: -0.3rem;
  left: -0.3rem;
  font-size: 0.65rem;
  border-radius: 2rem;
  min-width: 1.4rem;
  height: 1.4rem;
  text-align: center;
  line-height: 1.2rem;
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.1);
}



@media (max-width: 350px) {
  .shopping-cart-panel .panel-header .cart-icon {
    height: 3rem;
    width: 3rem;
    margin-top: -1.5rem;
  }

  .shopping-cart-panel:not(.open) .cart-icon {
    margin-bottom: 1rem
  }

  .shopping-cart-panel .panel-header .cart-icon .badge {
    top: -0.4rem;
    left: -0.4rem;
    min-width: 1.2rem;
    height: 1.2rem;
    line-height: 1rem;


  }

}

.shopping-cart-panel .close-background {
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
}



.shopping-cart-panel .body {
  background-color: white;
  flex: 1;
  flex-direction: column;
}


.shopping-cart-panel .footer-bar {
  display: flex;
  width: 100%;
  padding: 1.5rem 2rem 1rem 1.5rem;
  align-items: center;
  background-color: white;
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.2);
  position: absolute;
  bottom: 0;
}

/** move footer links into the background if the panel is open */
.shopping-cart-panel.open .footer-bar {
  z-index: 100;
}

.footer-links {
  text-align: left;
  position: absolute;
  left: 1rem;
  margin-top: -0.5rem;
  z-index: 300;
}

.footer-links.right {
  left: inherit;
  right: 1rem;
}

.footer-links .footer-link {
  font-size: 0.7rem;
  color: #666;
  text-decoration: none;
  margin-right: 0.5rem;
  cursor: pointer;

}

.version-info .value {
  margin-left: 0.15rem;
}

.svg-icon {
  width: 40%;
  height: auto;
}
</style>

<style>
.open-shopping-cart-panel ion-content::part(scroll) {
  overflow: hidden;
}
</style>