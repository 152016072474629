<template>
  <div class="tabs-view" :class="{ collapsed: showCollapsed }">
    <div ref="tabs" class="tabs">
      <tabs-view-tab v-for="tab of tabs" :key="tab.key" :label="tab.label" :to="tab.to" :class="tab.class ? tab.class : {}"></tabs-view-tab>
    </div>
    <div class="collapsed" v-if="showCollapsed">
      <ion-item lines="none" >
        <div class="select-container" @click="openSelect">

          <ion-label v-if="label">{{ label }}</ion-label>
          <ion-select  id="nav-select" :value="activeTab.key" interface="default" @ionChange="selectChanged" :toggleIcon="chevronExpandOutline">
            
            <ion-select-option v-for="tab of tabs" :key="tab.key" :to="tab.to" :class="tab.class ? tab.class : {}" :value="tab.key">{{
              tab.label
            }}</ion-select-option>
        </ion-select>
      </div>
      </ion-item>
    </div>
  </div>
</template>

<script setup>
import { chevronExpandOutline} from "ionicons/icons";
import { IonSelect, IonSelectOption, IonLabel, IonItem } from "@ionic/vue";
import TabsViewTab from "./TabsViewTab.vue";
</script>
<script>
export default {
  
  name: "tabs-view",
  data() {
    return {
      showCollapsed: false,
    };
  },
  props: ["tabs", "label"],
  computed: {
    activeTab() {
      for (let tab of this.tabs) {
        let resolved = this.$router.resolve(tab.to);
        if (resolved && this.$route.fullPath == resolved.fullPath) {
          return tab;
        }
      }
      return this.tabs && this.tabs.length > 0 ? this.tabs[0] : null;
    },
  },
  mounted: function () {
    this.checkSize();
  },
  watch: {
    $route: function () {
      this.$nextTick(() => {
        this.checkSize();
      });
    },
  },
  methods: {
    openSelect(){
      let navSelect = document.getElementById("nav-select");
      // console.log('navSelect',navSelect)
      navSelect.open()

    },
    selectChanged(e) {
      //dont change if the current route is not pinting to the menu. fi shopping cart
      // if (this.$route.name != "menu") {        
      //   return;
      // }
      let value = e.detail && e.detail.value ? e.detail.value : "everything";
      
      for (let tab of this.tabs) {
        if (tab.key == value) {
          this.$router.push(tab.to);
          break;
        }
      }
    },
    checkSize() {
      if (!this.$refs["tabs"]) {
        console.log("no tabs");
        return;
      }

      let offsetOfFirstElement = this.$refs["tabs"].firstElementChild.offsetTop;
      let offsetOfLastElement = this.$refs["tabs"].lastElementChild.offsetTop;

      let isWrapped = offsetOfLastElement != offsetOfFirstElement;
      this.showCollapsed = isWrapped;
    },
  },

  created() {
    window.addEventListener("resize", this.checkSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkSize);
  },
};
</script>
<style scoped>
.tabs-view {
  margin-top: 0.3rem;

  border-bottom: 1px solid var(--ion-color-light-shade);
}

.tabs-view .tabs {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  overflow: hidden;
  margin-bottom: -1px;
  flex-wrap: wrap;
}
.tabs-view.collapsed {
  margin-top: 0;
}
.collapsed .tabs {
  position: absolute;
  height: 0;
  visibility: hidden;
}
.select-container{
    
    width: 100%;
    display: flex;
    justify-content: center;
}

.select-container ion-select{
  flex:0;
  margin-right:-10px ;
}
</style>