import { toRefs, watch, reactive, computed } from "vue";
import { v4 as uuidv4 } from 'uuid';
import companyService from "./companyService";
import config from "../../config"
import dayjs from "dayjs";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

const companyServiceInstance = companyService();

const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    clear();
}
function loadSlots() {
    teardown();
    if(!companyServiceInstance.company || !companyServiceInstance.company.value.id){
        console.error('loadSlots: Company not loaded')
        return
    }
    let unsubscribeHandle = firebase
    .firestore()
    .collection("slots")
    .doc(companyServiceInstance.company.value.id)
    .onSnapshot(async (snapshot) => {
            console.log('loadSlots snapshot',snapshot.data())
            let data = snapshot.data()
            state.slots = data ? data : {}
        })
    unsubscribeHandles.push(unsubscribeHandle);
}

const numberOfOrdersMap = computed(() => {
    let map = {}        
    for(let slotKey  in state.slots){                        
        let tzSlotKey = dayjs(slotKey).format()
        // console.log('tzSlotKey',tzSlotKey)
        map[tzSlotKey] = Object.keys(state.slots[slotKey]).length
    }
    return map
})
function getNumberOfOrdersForSlot(slotStartDate){
    
    if(!slotStartDate){
        console.error('getNumberOfOrdersForSlot: no slotStartDate given')
        return 0
    }
    let date = dayjs(slotStartDate)
    let slotKey = date.format()
    // console.log('slotKey',slotKey)
    // console.log('numberOfOrdersMapInLocalTime.value',numberOfOrdersMap.value)
        
    return  numberOfOrdersMap.value[slotKey] || 0
}

const state = reactive({
    // const state = reactive({
    initialized: false,
    initializing: false,
    slots: {}
});


function clear() {
    state.slots = {};

}



export default function (reInit) {

    if (reInit) {
        state.initialized = false;
        state.initializing = false;
    }


    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        loadSlots()
        state.initialized = true
        state.initializing = false
        
    }
    
    
    
    watch(companyServiceInstance.company, (company) => {
        
        if (company) {
            loadSlots()
        }
    })

    return {
        ...toRefs(state),
        getNumberOfOrdersForSlot
    }


}