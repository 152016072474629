<template>
    <div class="oido-phone-input">

        <ion-label v-text="label" v-if="label"></ion-label>
        
        <div class="validation-errors" v-if="errors">
            <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
        </div>

        <div class="input-section">
            <div class="country-code-section">

                <v-select v-model="localValue.country" :searchable="!useCompactSelector" :filterable="!useCompactSelector"
                    :reduce="country => country.code" label="label" key="code" :options="allCountries"
                    :clearable="false" :filterBy="filterMe" ref="select" :class="{compact: useCompactSelector}">
                    <template #option="{ flag, name, dialCode }">
                        <span class="flag">{{ flag }}</span>
                        <span class="name">{{ name }}</span>
                        <span class="dial-code">{{ dialCode }}</span>
                    </template>
                </v-select>

            </div>
            <div class="phone-number-section">
                <ion-item lines="full">
                    <ion-input name="number" type="text" :placeholder="$t('oido-phone-input-number-placeholder')"
                        v-model="localValue.number" :class="{
            'has-errors': errors && (errors.number || errors.global)
        }" />
                </ion-item>

            </div>
        </div>


    </div>
</template>

<script setup>
import {
    countries,
} from "country-codes-flags-phone-codes";


import {
    IonLabel,
    IonItem,
    IonInput
} from "@ionic/vue";


</script>

<script>

export default {
    props: {
        errors: [Array,Object],
        modelValue: [String],
        label: [String],
        defaultCountry: [String],
    },
    components: {},
    data() {
        return {
            localValue: {
                country: null,
                number: ''
            },            
        };
    },
    computed: {
        useCompactSelector(){
            return this.frameWidth <= 400
        },
        allCountries() {
            let favorites = ['ES','NL','DE','FR','GB','US', 'AT']

            let shortcuts = []
            let allCountries = []
            for (let country of countries) {
                if(this.useCompactSelector){
                    country.label = `${country.flag}`
                }else{
                    country.label = `${country.flag} ${country.dialCode}`
                }
                
                allCountries.push(country)
                if(favorites.includes(country.code)){
                    let shortcut = JSON.parse(JSON.stringify(country));
                    shortcut.code = shortcut.code + '-'
                    shortcut.isShortcut = true
                    shortcuts.push(shortcut)
                }
            }
            allCountries.sort((a, b) => {
                let aName = a.name.toLowerCase();
                let bName = b.name.toLowerCase();
                return aName == bName ? 0 : (aName < bName ? -1 : 1)
                
            })

            let combined = shortcuts.concat(allCountries);




            return combined;
        }
    },
    mounted() {
        this.updateLocalValue()
    },
    watch: {
        modelValue() {
            this.updateLocalValue();
        },
        localValue: {
            deep: true,
            handler: function () {
                if (JSON.stringify(this.localValue) === JSON.stringify(this.modelValue)) {
                    return
                }
                this.valueChanged()
            },
        }
    },
    methods: {
        getCountryForCode(code) {
            code = code.replace(/-$/, "");
            
            for (let country of countries) {
                if (country.code == code) {
                    return country
                }
            }
            return null
        },
        getCountryForDialCode(dialCode) {
            for (let country of countries) {
                if (country.dialCode == dialCode) {
                    return country
                }
            }
            return null
        },
        getCountryForPhoneNumber(phonenumber) {
            let longestMatch = null
            for (let country of countries) {

                if (
                    phonenumber.startsWith(country.dialCode) && 
                    (!longestMatch ||  country.dialCode.length > longestMatch.dialCode.length)) {
                    longestMatch = country                
                }
            }
            return longestMatch
        },
        filterMe(option, label, search) {
            if(option.isShortcut){
                return ''
            }            
            return ((option && option.name) || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        },
        updateLocalValue() {


            let country = null
            if(('' + this.modelValue).startsWith('+')){
                country = this.getCountryForPhoneNumber(('' + this.modelValue))
            }
            
            
            if (!country) {
                let defaultCountry = this.getCountryForCode(this.defaultCountry)
                country = defaultCountry;
            }

            let nationalPhoneNumber = ('' + (this.modelValue ? this.modelValue : ''))
            if(country && nationalPhoneNumber.startsWith(country.dialCode)){
                nationalPhoneNumber = nationalPhoneNumber.slice(country.dialCode.length)
            }

            this.localValue = {
                'country': country ? country.code : null,
                number: nationalPhoneNumber
            }

            // this.localValue = { country: defaultCountry ? defaultCountry.code : null, number: null }



            // // if (!this.localValue || !this.localValue.number) {
            // //     let parsedNumber = this.modelValue ? parsePhoneNumber(this.modelValue) : null
            // //     if (parsedNumber) {
            // //         this.localValue = { country: parsedNumber.country, number: parsedNumber.nationalNumber }
            // //     } else {
            // //         this.localValue = { country: defaultCountry ? defaultCountry.code : null, number: null }
            // //     }

            // // }

            // let parsedNumber = this.modelValue ? parsePhoneNumber(this.modelValue) : null
            // if (parsedNumber) {
            //     this.localValue = { country: parsedNumber.country, number: parsedNumber.nationalNumber }
            // } else {
            //     let defaultCountry = this.getCountryForCode(this.defaultCountry)
            //     this.localValue = { country: defaultCountry ? defaultCountry.code : null, number: null }
            // }
            // console.log(parsedNumber)


        },
        valueChanged() {
            
            let country = this.getCountryForCode(this.localValue.country)
            

            //trim and remove leading zeros
            let cleanedNumber = (this.localValue.number + ``).trim().replace(/^0+/, '')

            let phonenumber = (country && country.dialCode ? country.dialCode : '') + (cleanedNumber ? '' + cleanedNumber : '')
            this.$emit("update:modelValue", phonenumber);
        },


    }

};
</script>
<style scoped>
.oido-phone-input .input-section {
    display: flex;
}

.phone-number-section {
    flex: 1;
}

.country-code-section {
    display: flex;
    align-items: flex-end;
}

ion-label {
    text-align: left;
    margin-bottom: 1rem;
    display: block;
}


ion-input {
    text-align: left;
    min-height: 0 !important;
}

ion-item::part(native) {
    min-height: 2rem;
}

.validation-errors{
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    margin-left: 1rem;
    text-align: left;
}
</style>



<style>
.oido-phone-input .country-code-section .v-select {
    width: 100%;
    min-width: 8rem;
    margin-right: 1rem;
    white-space: nowrap;
}
.oido-phone-input .country-code-section .v-select.compact {
    min-width: 0;
}

.oido-phone-input .country-code-section .v-select.compact .vs__selected{
    position: relative!important;
}
.oido-phone-input .country-code-section .v-select.compact .vs__search{
    width: 1px;
    padding: 0;
}

.oido-phone-input .country-code-section .vs__dropdown-menu {
    border-top: 1px solid #ddd;
    width: 80vw;
    max-width: 400px;

}

.oido-phone-input .country-code-section .vs__dropdown-menu .vs__dropdown-option {
    position: relative;
    display: flex;

}

.oido-phone-input .country-code-section .vs__dropdown-menu .flag {
    margin-right: 0.5rem;
    display: inline-block;
}

.oido-phone-input .country-code-section .vs__dropdown-menu .dial-code {
    display: inline-block;

}

.oido-phone-input .country-code-section .vs__dropdown-menu .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100% - 5rem);
    margin-right: 0.5rem;
}
</style>