<template>
  <ion-app
    :class="{ 'has-company-picture': companyPictureUrl, 'single-toplevel-category': hasOnlyOneTopLevelCategory }">
    <fab-translation-selector class="small" horizontal="end" vertical="top" side="bottom"></fab-translation-selector>
    <header class="company-info" v-if="company" @click="goToStartpage">
      <img :src="companyPictureUrl" class="company-picture" v-if="companyPictureUrl" />
      <div class="headings">

        <h1 class="company-name">{{ company.name }}</h1>
        <h2 class="pointofsale-name" v-if="pointofsale"
          v-text="services.translations.translation2Text(pointofsale.name)">
        </h2>
      </div>

      <div class="divider"></div>
    </header>


    <tabs-view v-if="!loading && company && visibleCategories" :tabs="tabs"></tabs-view>
    <div class="filter-view" v-if="!loading && company && company.tags && company.tags.length > 0 && isShopRoute">
      <span class="tag" v-for="tag of company.tags" :key="tag.id" :class="{ active: activeTags.includes(tag.id) }"
        @click="toggleTag(tag)" v-text="services.translations.translation2Text(tag.name)"></span>
    </div>
    <ion-content id="main">
      <ion-router-outlet :animated="false"></ion-router-outlet>
    </ion-content>

    <contact-panel v-if="company"></contact-panel>

    <closed-notice-panel
      v-if="(company && !company.isOpen) || (pointofsale && pointofsale.closed)"></closed-notice-panel>
    <shopping-cart-panel v-if="company && company.isOpen && pointofsale && !pointofsale.closed"></shopping-cart-panel>
  </ion-app>
</template>


<script setup>

import {
  IonApp,
  IonRouterOutlet,
  IonContent,

} from "@ionic/vue";

import { } from "ionicons/icons";
import getCustomDomain from './helpers/getCustomDomain'

import FabTranslationSelector from "./components/FabTranslationSelector.vue";
import TabsView from "./../../common/components/TabsView.vue";

import ShoppingCartPanel from "./views/ShoppingCartPanel.vue";
import ClosedNoticePanel from "./views/ClosedNoticePanel.vue";
import ContactPanel from "./views/ContactPanel.vue";
import LegalModal from "./views/LegalModal.vue";
import { modalController } from "@ionic/vue";

</script>
<script>


export default {
  computed: {
    loading() {
      return !this.company || !this.company.id;
    },
    selectedLanguage() {
      return this.services.translations.selectedLanguage ? this.services.translations.selectedLanguage.value : null;
    },
    activeTags() {
      return this.services.cartService.activeTags ? this.services.cartService.activeTags.value : [];
    },
    companyPictureUrl() {
      return this.services.company.companyPictureUrl ? this.services.company.companyPictureUrl.value : null;
    },
    slug() {
      return this.$route.params["companyId"] ? this.$route.params["companyId"] : null;
    },
    customDomain() {
      return getCustomDomain()
    },
    companySalt() {
      return this.$route.params["companySalt"] ? this.$route.params["companySalt"] : null;
    },
    categoryId() {
      return this.$route.params["categoryId"] ? this.$route.params["categoryId"] : null;
    },
    pointofsaleId() {
      return this.$route.params["pointofsaleId"] ? this.$route.params["pointofsaleId"] : null;
    },
    isShopRoute() {
      return Boolean(this.$route.name == 'menu' || this.$route.name == 'menu-cart')
    },
    hasOnlyOneTopLevelCategory() {
      return this.visibleCategories && this.visibleCategories.length == 1
    },    
    visiblePages() {
      let visiblePages = []

      if (this.company && this.company.pages && this.company.pages.length > 0) {
        for (let page of this.company.pages) {
          if (!page.showInMenu) {
            continue
          }

          if (this.pointofsale && this.pointofsale.pagesVisibilityRule == 'except') {
            let pagesToIgnore = this.pointofsale.pagesVisibilityConfig && this.pointofsale.pagesVisibilityConfig.length > 0 ? this.pointofsale.pagesVisibilityConfig : []
            if (pagesToIgnore.includes(page.id)) {
              continue
            }
          }
          if (this.pointofsale && this.pointofsale.pagesVisibilityRule == 'only') {
            let pagesToInclude = this.pointofsale.pagesVisibilityConfig && this.pointofsale.pagesVisibilityConfig.length > 0 ? this.pointofsale.pagesVisibilityConfig : []
            if (!pagesToInclude.includes(page.id)) {
              continue
            }
          }
          visiblePages.push(page)
        }
      }

      return visiblePages
    },


    tabs() {

      let tabs = [];
      let allParams = { ...this.$route.params };
      
      delete allParams.categoryId;


      if (this.visibleStories && this.visibleStories.length > 0) {
        tabs.push({
          label: this.$t("menu-stories-navigation-label"),
          to: { name: "stories-page", params: allParams },
          class: { active: this.$route.name == 'stories' || this.$route.name == 'story' },
          key: "stories",
        });
      }


      //if there is only one top level category we would end up with two nearly identical Menuitems (All, <<categoryname>>)
      //because the route to the all items page is used elsewhere, we rename the all-products item and hide the category

      let nameOfEverythingButton = this.hasOnlyOneTopLevelCategory ? this.services.translations.translation2Text(this.visibleCategories[0].name) : this.$t("menu-category-navigation-show-all")

      tabs.push({
        label: nameOfEverythingButton,
        to: { name: "menu", params: allParams },
        class: { active: this.$route.name == 'menu' && !this.categoryId, parent: this.categoryId ? true : false },
        key: "everything",
      });

      if (!this.hasOnlyOneTopLevelCategory) {
        for (let category of this.visibleCategories) {
          tabs.push({
            label: this.services.translations.translation2Text(category.name),
            to: { name: "menu", params: { ...this.$route.params, categoryId: category.id } },
            key: typeof category.id !== "undefined" ? category.id : category,
          });
        }
      }

      for (let page of this.visiblePages) {
        tabs.push({
          label: this.services.translations.translation2Text(page.title),
          to: { name: "content-page", params: { ...this.$route.params, pageId: page.id } },
          class: { active: this.$route.name == 'content-page' && this.$route.params.id == page.id },
          key: page.id,
        });
      }

      return tabs;
    },
  },
  async unmounted() { },
  watch: {
    company() {

      //if its a very old url which uses the companyId instead of the slug
      if (this.company && this.$route.params["companyId"] == this.company.id && this.company.slug != this.$route.params["companyId"]) {
        let posId = this.$route.params["pointofsaleId"] ? this.$route.params["pointofsaleId"] : this.company.defaultPointofsaleId
        this.$router.push({
          name: ('' + this.$route.name).replace("-old", ""),
          params: { ...this.$route.params, pointofsaleId: posId, companyId: this.company.slug },
          query: this.$route.query
        })
      } else if (!this.$route.params['pointofsaleId'] && this.company && this.company.defaultPointofsaleId) {
        // it has no pos set so use the default pos
        this.$router.push({
          name: this.$route.name == "default-menu" ? "menu" : this.$route.name,
          params: { ...this.$route.params, pointofsaleId: this.company.defaultPointofsaleId },
          query: this.$route.query
        })
      }


      if (this.company && (this.$route.name == 'menu' || this.$route.name == 'default-menu')) {
        this.services.statistics.countScan()
      }

      if (this.company && this.company.enableLegalAgePopup) {
        this.showLegalPopup()
      }
    },
    slug() {
      this.loadCompany();
    },
    pointofsaleId() {
      this.services.company.pointofsaleId.value = this.pointofsaleId;
    },
    companySalt() {
      this.loadCompany();
    },
    loading() {
      this.removeLoadingPanel();
    },
    selectedLanguage(selectedLanguage) {
      this.$i18n.locale = selectedLanguage;
    },
  },
  async mounted() {
    this.loadCompany();
  },
  methods: {
    goToStartpage() {
      this.$router.push({
        name: "menu",
        params: {companyId:this.$route.params.companyId, pointofsaleId: this.$route.params.pointofsaleId }      
      })
      window.location.reload();
    },
    async showLegalPopup() {
      if (!this.company || !this.company.enableLegalAgePopup) {
        console.error('showLegalPopup no company or not enabled', this.company)
        return
      }
      if (!this.pointofsale || !this.pointofsale.enableCheckout) {
        console.error('showLegalPopup no pointofsale or checkpout not enabled', this.company)
        return
      }
      let localStorageKey = this.services.cartService.localStorageKey + '-legal-popup'

      let savedState = JSON.parse(localStorage.getItem(localStorageKey));

      if (savedState && savedState.accepted && savedState.date) {
        let secondsSinceLastTime = ((new Date()).getTime() - new Date(savedState.date).getTime()) / 1000
        if (secondsSinceLastTime && secondsSinceLastTime < 60 * 60 * 24 * 5) {
          localStorage.setItem(localStorageKey, JSON.stringify({
            accepted: true,
            date: new Date()
          }))
          return
        } else {
          localStorage.removeItem(localStorageKey)
        }
      }

      this.pointofsale.enableCheckout = false

      const modal = await modalController.create({
        component: LegalModal,
        canDismiss: (value) => {
          return value != 'undefined'
        },
        componentProps: {},
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.pointofsale.enableCheckout = true
        localStorage.setItem(localStorageKey, JSON.stringify({
          accepted: true,
          date: new Date()
        }))
      } else {
        this.pointofsale.enableCheckout = false
      }
    },
    async loadCompany() {

      let companyId = null

      if (this.customDomain) {
        companyId = await this.companyService.checkCustomDomain(this.customDomain)
      }

      if (!companyId && this.slug) {
        companyId = await this.companyService.checkCompanySlug(this.slug)
      }

      this.companyService.loadCompany(companyId, this.pointofsaleId);
    },

    toggleTag(tag) {
      if (this.activeTags.includes(tag.id)) {
        this.activeTags.splice(this.activeTags.indexOf(tag.id), 1);
      } else {
        this.activeTags.push(tag.id);
      }
    },
    removeLoadingPanel() {
      if (!this.loading) {
        // document.body.classList.remove("loading");
        setTimeout(() => {
          document.body.classList.remove("loading");
        }, 1000)
      }
    }
  },
};
</script>

<style>
@import "vue-select/dist/vue-select.css";

#app {
  height: 100%;
}
</style>
<style scoped>
ion-app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-page {
  padding-bottom: 3rem;
}

.company-info {
  padding: 1.7rem 1rem 1.5rem;
  text-align: center;
  position: relative;
  color: #1e1e1e;
  cursor: pointer;
  /* min-height: 200px; */
}

.company-info .company-name {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
}

.company-info .pointofsale-name {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.company-info .company-picture {
  object-fit: cover;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.company-info .divider {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.company-info .divider::after {
  content: " ";
  box-shadow: 0px 1px 15px hsla(0, 0%, 39%, 0.25);
  border-bottom-left-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  position: absolute;
  left: -5%;
  right: -5%;
  top: 0px;
  bottom: 15px;
}

/* @media (max-width: 620px) { */
.company-info {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 1rem;
  min-height: auto;
  align-items: center;
}

.company-info .divider {
  display: none;
}

.company-info .company-picture {
  width: 50px;
  height: 50px;
  margin-bottom: 0px;
  margin-right: 15px;
}

.headings {
  text-align: left;
}

.company-info .company-name {
  margin-top: 5px;
}

.company-info .pointofsale-name {
  font-size: 1rem;
  margin: 0.2rem 0;
}

/* } */

/* @media (min-width: 1024px) {
  .company-info .company-picture {
    width: 60px;
    height: 60px;
    
  }
  .company-info .company-name{
    font-size: 1.3rem;

  }
  .company-info .pointofsale-name{
    font-size: 1.2rem;
  }
} */

.loading-indicator {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-indicator ion-spinner {
  width: 100px !important;
  height: 90px !important;
}

.loading-indicator .label {
  margin-top: 1rem;
  color: var(--ion-color-medium);
  font-size: 0.8rem;
  display: none;
}

.filter-view {
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  border-bottom: 1px solid var(--ion-color-light-shade);
  padding: 0rem 1rem;
  font-size: 0.8rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-view .tag {
  text-transform: capitalize;

  padding: 0.3rem 0.5rem;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.filter-view .tag.active {
  color: var(--ion-color-primary);
  background-color: rgba(0, 0, 0, 0.03);
  border-right: 1px solid #cecece;
  border-left: 1px solid #cecece;
}

.filter-view .tag.active+.tag.active {
  border-left: none;
}
</style>


<style>
body {
  background-color: white !important;
  color: var(--ion-color-dark);
}


ion-app:not(.has-company-picture) .fab-translation-selector {
  margin-top: -0.3rem;
}

@media (max-width: 620px) {
  ion-app:not(.has-company-picture) .fab-translation-selector {
    margin-top: -0.1rem;
  }
}

.fab-translation-selector>ion-fab-button::part(native) {
  box-shadow: none;
}

.fab-translation-selector.small>ion-fab-button::part(native) {
  margin-left: -0.4rem !important;
  margin-top: 1rem;
}

.fab-translation-selector.small ion-fab-button,
.fab-translation-selector.small ion-fab-button::part(native) {
  width: 30px;
  height: 30px;
}

.fab-translation-selector.small ion-fab-list {
  margin-top: 50px;
  min-width: 33px !important;
}

textarea {
  background-color: #fff !important;
}

.validation-errors.global {
  padding-left: 16px;
}

.validation-error {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  padding-top: 0.5rem !important;
  margin-bottom: -.3rem;
  display: block;
}

.currency-symbol {
  padding-left: .15rem;
}


.warning-sign {
  margin: 1rem 2rem;
  padding: 1rem;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background: #db22418a;
  background: var(--ion-color-primary);
  position: relative;
  text-align: center;
  z-index: 200;
  line-height: 1.5;
}

.warning-sign a {
  z-index: 200;
  position: relative;
  color: white
}

@media (min-width: 700px) {
  .warning-sign {
    margin: 1rem auto;
    max-width: 600px;
  }
}



@media (max-width: 700px) {

  ion-toast {
    top: -10px !important;
    left: -10px !important;
    width: calc(100vw + 20px) !important;
    border-radius: 0;
    max-width: auto !important;
  }
}


.rtf-note {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3;
}

.rtf-note h1 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem
}

.rtf-note h2 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 0.3rem;
  margin-bottom: 0.6rem
}

.rtf-note h3 {
  font-size: 0.9rem;
  font-weight: 590;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem
}

.rtf-note p {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}


@media (min-width: 900px) {

  .frontpage-notice.rtf-note {
    text-align: left !important;
  }

}

ion-modal::part(content) {
  background-color: transparent
}

ion-modal::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.75);
}
</style>



<style>
@media (max-width: 900px) {

  .related-stories {
    margin-top: 7rem;
  }

  .related-stories .story-list-item h1.title {
    font-size: 1rem;
  }

  /* .related-stories .story-list-item .list-item-content .meta,
  .related-stories .story-list-item {
    font-size: 0.8rem;
  } */

  .related-stories .story-list-item:first-child {
    padding-top: 1rem;
    border-top: 1px solid var(--ion-color-light-shade);
  }


}

@media (min-width: 900px) {

  .related-stories {
    width: 25%;
  }


  .related-stories .story-list-item h1.title {
    font-size: 0.8rem;
  }

  .related-stories .story-list-item .list-item-content .meta,
  .related-stories .story-list-item {
    font-size: 0.7rem;
  }

  .related-stories .story-list-item .list-item-before {
    padding-right: 0.7rem;
  }

  .related-stories .story-list-item:first-child {
    margin-top: 0;
    padding-top: 0rem;
  }

  .related-stories .story-list-item .picture {
    width: 40%;
    max-width: none
  }

  .related-stories .story-list-item .intro {
    display: none !important;
  }

}


.text-content {
  line-height: 1.4;
}

.text-content>p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.text-content img {
  width: 65%;
  max-width: 400px;
  margin: 2rem auto;
  display: block;
}

@media (max-width: 700px) {
  .text-content img {
    width: 100%;
  }

}

.text-content .quote {
  padding: 2rem;
}

.text-content .quote blockquote {
  font-weight: 200;
  font-size: 1.15rem;
  text-align: center;
  font-style: italic;
  margin: 0;
}

.text-content .quote .caption {
  font-weight: 300;
  font-size: 1.15rem;
  text-align: center;
  margin-top: 1rem;
}


.single-toplevel-category .menu-page>ion-content {}

.single-toplevel-category .menu-page .throttle-message-container {
  /* margin-top: -2rem; */
}



.single-toplevel-category .product-list-category-item.level-1>.category-name {
  display: none;
}

.single-toplevel-category .product-list-category-item.level-1>.children:first-of-type>.product-list-category-item.level-2:first-child>h2 {
  margin-top: 0;
}

.text-content .link-container {
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0px 1px 15px hsla(0, 0%, 39%, 0.15);
  border-radius: 8px;
}

.text-content .link-container h3.title {
  margin-top: 0.3rem;
  font-size: 1rem;
}

.text-content .link-container .description {
  margin-top: 0.3rem;
  margin-bottom: 0.8rem;
  font-size: 0.8rem;
}
</style>
