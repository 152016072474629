<template>
  <div class="discount-selector">
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>    
    <div class="discounts">
      <div class="discount" :class="{active: localValue.includes(discount.id) }" v-for="discount of company.discounts" :key="discount.id" v-text="services.translations.translation2Text(discount.name)" @click="toggleDiscount(discount)"></div>
    </div>
  </div>

</template>

<script setup>

</script>
<script>
export default {
  props: {
    errors: [Array, Object],
    modelValue: Array
  },
  data() {
    return {
      localValue: []
    };
  },
  mounted() {
    this.updateLocalValue()
  },
  watch: {
    modelValue() {
      this.updateLocalValue();
      
    },
    localValue: {
      deep: true,
      handler: function () {
        if (JSON.stringify(this.localValue) === JSON.stringify(this.modelValue)) {
          return
        }
        this.valueChanged()
      },
    }
  },
  methods: {
    toggleDiscount(discount){
      if(!discount || !discount.id){
        return 
      }
      if(this.localValue.includes(discount.id)){
        let index = this.localValue.indexOf(discount.id)
        this.localValue.splice(index, 1);
      }else{
        this.localValue.push(discount.id)
      }

    },
    updateLocalValue() {
      if (this.localValue !== this.modelValue) {
        this.localValue = this.modelValue ? this.modelValue : null;
      }
      if(!this.localValue){
        this.localValue = []
      }
    },
    valueChanged() {
      this.$emit("update:modelValue", this.localValue);
    },
  }

}
</script>
<style scoped>
.discounts{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.discounts .discount{
 background-color: var(--ion-color-primary-contrast);
 color: var(--ion-color-primary);
 border: 1px solid var(--ion-color-primary);
 font-size: 0.8rem;
 padding: 0.3rem 0.7rem;
 border-radius: 1.2rem;
 cursor: pointer;
 -webkit-user-select: none; /* Safari */
 user-select: none;
 margin-left: 0.5rem;

}
.discounts .discount.active{
 background-color: var(--ion-color-primary);
 color: var(--ion-color-primary-contrast);
 font-size: 0.8rem;
 padding: 0.3rem 0.7rem;
 border-radius: 1.2rem;

}
</style>