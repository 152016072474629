import { toRefs, reactive, watch, computed } from "vue";
import dayjs from "dayjs";
import mediaService from "./mediaService";
const mediaServiceInstance = mediaService();
import firebaseService from "./firebaseService";
const firebase = firebaseService();



const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.company = {};
    state.companyPictureUrl = null;
}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    company: {},
    companyPictureUrl: null,
    pointofsaleId: null
});

async function checkCompanySlug(slug) {

    let slugDoc = await firebase
        .firestore()
        .collection("slugs")
        .doc(slug)
        .get()
    if (slugDoc.exists) {
        let data = slugDoc.data()
        if (data.companyId) {
            return data.companyId;
        }
    }
    return null
}

async function checkCustomDomain(customDomain) {

    let doc = await firebase
        .firestore()
        .collection("custom-domains")
        .doc(customDomain)
        .get()

    if (doc.exists) {
        let data = doc.data()
        if (data.companyId) {
            return data.companyId;
        }
    }
    return null
}

async function loadCompany(companyId, pointofsaleId) {
    if (state.company && state.company.id == companyId) {
        return
    }
    teardown();
    let companyUnsubscribeHandle = firebase
        .firestore()
        .collection("publicCompanyInfos")
        .doc(companyId)
        .onSnapshot(async (snapshot) => {
            let data = snapshot.data()
            if (data && snapshot.id) {
                data.id = snapshot.id
                state.company = data;
                state.pointofsaleId = pointofsaleId ? pointofsaleId : state.company.defaultPointofsaleId;
                state.companyPictureUrl = await mediaServiceInstance.getMediaUrl(state.company.picture);
            } else {
                state.company = null
                state.pointofsaleId = null
                state.companyPictureUrl = null
            }

        })
    unsubscribeHandles.push(companyUnsubscribeHandle);
}

function getProductById(productId) {
    if (!state.company || !state.company.products) {
        return null;
    }
    for (let product of state.company.products) {
        if (product.id == productId) {
            return product;
        }
    }
    return null;
}
function getPointofsaleById(pointofsaleId) {

    if (!state.company || !state.company.pointsofsale) {
        return null;
    }
    for (let pointofsale of state.company.pointsofsale) {
        if (pointofsale.id == pointofsaleId) {
            return pointofsale;
        }
    }
    return null;
}
function getTaxForProduct(productId) {
    let product = getProductById(productId);
    if (!product) {

        console.log('getTaxForProduct productNotfound', productId);
        return 0;
    }
    if (!state.company || !state.company.vat) {
        return 0;
    }

    if (product.vatRate && state.company.vatRates && state.company.vatRates.length > 0) {
        for (let rate of state.company.vatRates) {
            if (product.vatRate == rate.id) {
                return rate.rate;
            }
        }
    }

    return state.company.vat
}
const activePointofsale = computed(() => {
    let pos = null
    if (state.pointofsaleId) {
        pos = getPointofsaleById(state.pointofsaleId);
    }
    if (!pos && state.company.defaultPointofsaleId) {
        pos = getPointofsaleById(state.company.defaultPointofsaleId);
    }
    if (!pos && state.company.pointsofsale && state.company.pointsofsale.length > 0) {
        pos = state.company.pointsofsale[0]
    }
    return pos;
});

const productsPerCategory = computed(() => {
    let map = {}
    if (state.company && state.company.products) {
        for (let product of state.company.products) {
            if (product.hidden) {
                continue
            }
            if (!map[product.categoryId]) {
                map[product.categoryId] = []
            }
            map[product.categoryId].push(product)
        }
    }
    return map
})

const visibleCategoryIds = computed(() => {
    if (!visibleCategories.value) {
        return []
    }
    let ids = []
    let getIds = (category) => {
        if (!category) {
            return []
        }
        let ids = []
        ids.push(category.id);

        if (category.children) {
            for (let child of category.children) {
                ids = ids.concat(getIds(child))
            }
        }
        return ids;
    }

    for (let category of visibleCategories.value) {
        ids = ids.concat(getIds(category))
    }
    return ids
})


const visibleCategories = computed(() => {

    let visibleCategories = []
    if (!activePointofsale.value) {
        return;
    }
    let rule = activePointofsale.value.categoryVisibilityRule || "all"
    let config = activePointofsale.value.categoryVisibilityConfig || []

    let getVisibleCats = (category) => {

        if (category.children && category.children.length > 0) {
            let visibleKids = []
            for (let child of category.children) {

                let visibleChild = getVisibleCats(child);
                if (!visibleChild) {
                    continue
                }
                visibleKids.push(visibleChild)
            }
            category.children = visibleKids;
        }

        let numberOfPRoducts = productsPerCategory.value && productsPerCategory.value[category.id] ? productsPerCategory.value[category.id].length : 0

        let shouldbeAdded =
            numberOfPRoducts > 0 &&
            (
                (rule == "all") ||
                (rule == "only" && config.includes(category.id)) ||
                (rule == "except" && !config.includes(category.id))
            )

        return shouldbeAdded ? category : null
    }

    for (let category of state.company.categories) {
        let cat = getVisibleCats(JSON.parse(JSON.stringify(category)));
        if (cat) {
            visibleCategories.push(cat)
        }
    }
    return visibleCategories;
});


function timeStringToDate(dayDate, timeString) {
    let day = dayjs(dayDate).startOf('day')
    let startParts = ('' + timeString).split(':')

    if (startParts.length != 2) {
        return null
    }
    day = day.hour(Number.parseInt(startParts[0]))
    day = day.minute(Number.parseInt(startParts[1]))
    return day
}

function getDeliveryHoursForDay(date) {
    
    // console.log('state.company',state.company)
    if (!state.company) {
        return null
    }
    
    if (state.company.deliveryHoursSameAsOpeningHours) {
        return getOpeningHoursForDay(date)
    }

    if (!state.company || !state.company.deliveryHours) {
        return null
    }

    return getApplicableHoursForDay(date, state.company.deliveryHours)

}

function getOpeningHoursForDay(date) {
    
    if (!state.company || !state.company.openingHours) {
        return null
    }    
    return getApplicableHoursForDay(date, state.company.openingHours)
}

function getApplicableHoursForDay(date,hoursConfig) {

    if (!date) {
        return null
    }

    if (!state.company) {
        return null
    }
    if (!hoursConfig) {
        return null
    }
    let day = dayjs(date).startOf('day')

    let weekday = day.day()

    let hours = []

    let applicableOpeningHours = null
    for (let rule of hoursConfig) {
        if (rule.occurrence == 'periodic' && rule.weekday == weekday) {
            applicableOpeningHours = rule.hours
            break
        }
    }

    if (applicableOpeningHours && applicableOpeningHours.length > 0) {
        for (let range of applicableOpeningHours) {

            let startDate = timeStringToDate(day, range.start)
            let endDate = timeStringToDate(day, range.end)
            if (!startDate || !endDate) {
                console.error('invalid date range in ', applicableOpeningHours)
                continue
            }
            hours.push({
                startDate,
                endDate
            })
        }
    }

    return hours
}

export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        state.initialized = true
        state.initializing = false
    }

    return {
        ...toRefs(state),
        loadCompany,
        getProductById,
        getPointofsaleById,
        getTaxForProduct,
        activePointofsale,
        checkCompanySlug,
        visibleCategories,
        visibleCategoryIds,
        checkCustomDomain,
        getOpeningHoursForDay,
        getDeliveryHoursForDay,
        productsPerCategory
    }
}
