<template>
    <div class="oido-country-selector">

        <ion-label v-text="label" v-if="label"></ion-label>

        <div class="validation-errors" v-if="errors">
            <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
        </div>

        <div class="input-section">

            <v-select v-model="localValue" :searchable="!useCompactSelector" :filterable="!useCompactSelector"
                :reduce="country => country.code" label="label" key="code" :options="allCountries" :clearable="false"
                :filterBy="filterMe" ref="select" :class="{ compact: useCompactSelector }">
                <template #option="{ flag, name }">
                    <span class="flag">{{ flag }}</span>
                    <span class="name">{{ name }}</span>
                </template>
            </v-select>

        </div>


    </div>
</template>

<script setup>
import {
    countries
} from "country-codes-flags-phone-codes";


import {
    IonLabel
} from "@ionic/vue";


</script>

<script>
const favorites = ['ES', 'NL', 'DE', 'FR', 'GB', 'US', 'AT']
export default {
    props: {
        errors: [Array, Object],
        modelValue: String,
        label: String,
        defaultCountry: String,
    },
    components: {},
    data() {
        return {
            localValue: null,
        };
    },
    computed: {
        useCompactSelector() {
            return this.frameWidth <= 400
        },
        allCountries() {


            let shortcuts = []
            let allCountries = []
            for (let country of countries) {
                // if (this.useCompactSelector) {
                //     country.label = `${country.flag}`
                // } else {
                //     country.label = `${country.flag} ${country.name}`
                // }
                country.label = `${country.flag} ${country.name}`

                allCountries.push(country)
                if (favorites.includes(country.code)) {
                    let shortcut = JSON.parse(JSON.stringify(country));
                    shortcut.code = shortcut.code + '-'
                    shortcut.isShortcut = true
                    shortcuts.push(shortcut)
                }
            }
            allCountries.sort((a, b) => {
                let aName = a.name.toLowerCase();
                let bName = b.name.toLowerCase();
                return aName == bName ? 0 : (aName < bName ? -1 : 1)

            })

            let combined = shortcuts.concat(allCountries);

            return combined;
        }
    },
    mounted() {
        this.updateLocalValue()
    },
    watch: {
        modelValue() {
            this.updateLocalValue();
        },
        localValue: {
            deep: true,
            handler: function () {
                if (JSON.stringify(this.localValue) === JSON.stringify(this.modelValue)) {
                    return
                }
                this.valueChanged()
            },
        }
    },
    methods: {
        getCountryForCode(code) {
            code = code.replace(/-$/, "");

            for (let country of countries) {
                if (country.code == code) {
                    return country
                }
            }
            return null
        },
        filterMe(option, label, search) {
            if (option.isShortcut) {
                return ''
            }
            return ((option && option.name) || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        },
        updateLocalValue() {
            if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {

                let valueWithoutShortcutDash = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : null
                let valueWithShortcutDash = favorites.includes(valueWithoutShortcutDash) ? valueWithoutShortcutDash + '-' : valueWithoutShortcutDash
                this.localValue = this.modelValue ? valueWithShortcutDash : null;
            }
            if (!this.localValue) {
                this.localValue = null
            }

        },
        valueChanged() {
            let code = ('' + this.localValue).replace(/-$/, "");
            this.$emit("update:modelValue", code);
        },


    }

};
</script>
<style scoped>
.country-code-section {
    display: flex;
    align-items: flex-end;
}

ion-label {
    text-align: left;
    margin-bottom: 1rem;
    display: block;
}


ion-input {
    text-align: left;
    min-height: 0 !important;
}

ion-item::part(native) {
    min-height: 2rem;
}

.validation-errors {
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    margin-left: 1rem;
    text-align: left;
}
</style>



<style>
.oido-country-selector .country-code-section .v-select {
    width: 100%;
    min-width: 8rem;
    margin-right: 1rem;
    white-space: nowrap;
}

.oido-country-selector .country-code-section .v-select.compact {
    min-width: 0;
}

.oido-country-selector .country-code-section .v-select.compact .vs__selected {
    position: relative !important;
}

.oido-country-selector .country-code-section .v-select.compact .vs__search {
    width: 1px;
    padding: 0;
}

.oido-country-selector .country-code-section .vs__dropdown-menu {
    border-top: 1px solid #ddd;
    width: 80vw;
    max-width: 400px;

}

.oido-country-selector .country-code-section .vs__dropdown-menu .vs__dropdown-option {
    position: relative;
    display: flex;

}

.oido-country-selector .country-code-section .vs__dropdown-menu .flag {
    margin-right: 0.5rem;
    display: inline-block;
}

.oido-country-selector .country-code-section .vs__dropdown-menu .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100% - 5rem);
    margin-right: 0.5rem;
}


.oido-country-selector .vs__dropdown-option {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}


.oido-country-selector .vs__selected-options {
    overflow: hidden;
    white-space: nowrap;
    min-height: 1.5rem;
}

.oido-country-selector .v-select:not(.vs--open) .vs__selected-options  .vs__search{
    display: none;
}
.oido-country-selector .vs__selected-options .vs__selected {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
}
</style>
