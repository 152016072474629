const firebaseConfig = {
  apiKey: "AIzaSyDe_2dsGNlP8lBXa0wLySGIzfhDjmQcja8",
  authDomain: "oido-testumgebung.firebaseapp.com",
  projectId: "oido-testumgebung",
  storageBucket: "oido-testumgebung.appspot.com",
  messagingSenderId: "267600152402",
  appId: "1:267600152402:web:6831331836d8863d5b5c8b"
};

export default firebaseConfig;

