<template >
  <div class="order-progress-indicator" :class="{[order.status]: true}">

    <div class="timeline">
      <div class="state" v-for="(state, stateIndex) of possibleStates" :key="state.key"
        :class="{ [state.key]: true, 'active': currentState == state.key, 'finished': currentStateIndex > stateIndex }">
        <span class="label">{{ $t('order-progress-indicator-' + state.key + '-label') }}</span>
        <div class="circle">
          <div class="percent-indicator" v-if="state.showProgressBar"
            :style="{ width: (100 - ( percentCompleted > minProgressBarWidthInPercent ? percentCompleted: minProgressBarWidthInPercent ) ) + '%' }">
          </div>
        </div>
        <div class="line before"></div>
        <div class="line after"></div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: ['order'],
  emits: ['stateChanged'],
  computed: {
    minProgressBarWidthInPercent(){
      return 2;
    },
    possibleStates() {
      return [
        {
          key: 'new',
          showProgressBar: false
        },
        {
          key: 'wip',
          showProgressBar: true
        },
        {
          key: 'ready',
          showProgressBar: false
        }
      ]
    },
    percentCompleted() {
      if (!this.order || !this.order.items || this.order.items.length <= 0) {
        return 0;
      }
      let numberOfItems = this.order.items.length;
      let numberOfFinishedItems = 0;
      for (let item of this.order.items) {
        if (item.done) {
          numberOfFinishedItems++;
        }
      }

      return Number.parseInt(numberOfFinishedItems / numberOfItems * 100);

    },
    currentState() {
      return this.order ? this.order.status : 'new'
    },
    currentStateIndex() {
      let i = 0;
      for (let possibleState of this.possibleStates) {
        if (possibleState.key == this.currentState) {
          return i;
        }
        i++
      }
      return 0;
    }

  }
};
</script>

<style scoped>
.order-progress-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;;
  margin: 0 auto;
}

.order-progress-indicator .timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.4rem;
  white-space: nowrap;
  flex:1;
  width: 90%;
}

.state {
  text-align: center;
  position: relative;
}

.state .label {
  font-weight: 200;
  font-size: 0.8rem;
  color: var(--ion-color-primary);
  padding-bottom: .2rem;
  display: inline-block;
}

.state.active .label {
  color: var(--ion-color-primary);
  font-weight: 400;
}

.state.finished .label {
  color: var(--ion-color-primary);

}

.state.wip{
  width: 100%;  
}
.state .circle {
  margin: 0.1rem auto 0;
  display: block;
  width: 1.5rem;
  height: 1.2rem;
    
  background-color: #dedede;
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.state.wip .circle {
  width: 95%;
  position: relative;


}

.state.wip .percent-indicator {
  height: 100%;
  background-color: #dedede;
  right: 0;
  position: absolute;
  z-index: 200;
}

.state.active .circle {
  border-color: var(--ion-color-primary);
  border-width: 2px;
  background: var(--ion-color-primary);

}

.state.finished .circle {
  border-color: #666;
  border-width: 1.5px;
  background-color: var(--ion-color-primary);
  line-height: 0;
}

.state .line {
  border-bottom: 2px solid #999;
  position: absolute;
  width: 50%;
  bottom: 0.7rem;
  z-index: 50;
  display: none;
}

.state .line.before {
  left: 0;
}

.state .line.after {
  right: 0;
}

.state:first-child .line.before {
  display: none;
}

.state:last-child .line.after {
  display: none;
}

.state.finished .line.after {
  border-width: 2px;
  color: #666;
  margin-bottom: -0.5px;
}

.state.finished .line.before,
.state.active .line.before {
  border-width: 2px;
  margin-bottom: -0.5px;
  color: #666;
}

.status-message {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #666;
  max-width: 50vw;
}

.order-progress-indicator.ready .status-message {
  font-weight: bold;
  color: var(--ion-color-success)
}

</style>
