<template>
  <div class="contact-panel" :class="{ open: open }" v-show="open">
    <div class="panel-header" @click="togglePanel">
      <div class="close-background" v-if="open"></div>
      <div class="cart-icon">
        <ion-icon class="toggle-icon" v-if="open" :icon="chevronDownOutline"></ion-icon>
        <ion-icon class="toggle-icon" v-if="!open" :icon="alertOutline"></ion-icon>
      </div>
      <div class="content">

        <h2 v-text="$t('contact-panel-title')"></h2>
        <div class="subtitle" v-text="$t('contact-panel-subtitle')"></div>


      </div>
    </div>
    <div class="body" v-if="open">
      <div class="content">
        <div class="panel-header">

        </div>
        <div class="name" v-text="company.name"></div>
        <div class="address">
          <div class="company-name" v-if="company.companyname" v-text="company.companyname"></div>
          <div class="street" v-if="company.street" v-text="company.street"></div>
          <div class="zipcity" v-if="company.zip || company.city"
            v-text="(company.zip ? company.zip + ' ' : '') + (company.city ? company.city : '')"></div>
          <div class="country" v-if="country" v-text="country"></div>
        </div>

        <div class="description" v-if="company.description"
          v-text="services.translations.translation2Text(company.description)"></div>

        <div class="contacts">
          <div class="contact phone " v-if="company.phone">
            <span class="label" v-text="$t('contact-panel-contacts-phone-label')"></span>
            <a :href="phoneUrl" class="value" v-text="company.phone"></a>
          </div>
          <div class="contact website " v-if="websiteUrl && websiteUrl.length > 0">
            <span class="label" v-text="$t('contact-panel-contacts-website-label')"></span>
            <a :href="websiteUrl" target="_blank" class="value" v-text="websiteUrlAsLabel"></a>
          </div>
          <div class="contact email " v-if="company.email">
            <span class="label" v-text="$t('contact-panel-contacts-email-label')"></span>
            <a :href="emailUrl" class="value" v-text="company.email"></a>
          </div>
        </div>
                
        <div class="opening-hours-section" v-if="openingHours && !hasDefaultOpeningHours">
          <h5 v-text="$t('opening-hours-heading')"></h5>
          <div class="opening-hour" v-for="openingHour of openingHours" :key="openingHour.key">
            <h6 class="label" v-text="openingHour.label"></h6>
            <div class="no-hours-label" v-if="!openingHour.hours || openingHour.hours.length == 0"
              v-text="$t('opening-hour-closed-label')"></div>
            <div class="time-range" v-for="range, rangeIndex of openingHour.hours"
              :key="openingHour.key + '-' + rangeIndex">
              <span class="start" v-text="range.start"></span> - <span class="end" v-text="range.end"></span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { IonIcon } from "@ionic/vue";
import { chevronDownOutline, alertOutline } from "ionicons/icons";

</script>

<script>

export default {
  data() {
    return {

    };
  },
  watch: {
    open() {
      this.updateBodyClass()
    },
  },
  computed: {
    hasDefaultOpeningHours() {

      //only show opening hours if those are changed from the default of 24/7
      if(!this.openingHours){
        return true
      }
      
      
      if(this.company.openingHours.length != 7){
        return false
      }

      for (let rule of this.company.openingHours) {

        if(rule.occurrence != 'periodic'){
          return false
        }

        if(!rule.hours || rule.hours.length != 1){
          return false
        }

        if(rule.hours[0].start != "00:00" || rule.hours[0].end != "24:00"){
          return false
        }
      }

      return true
    },
    openingHours() {
      if (!this.company || !this.company.openingHours) {
        return null
      }
      let openingHours = []
      for (let openingHour of this.company.openingHours) {
        openingHour.label = null
        if (openingHour.occurrence == 'periodic') {
          openingHour.label = this.$t(`weekday-${openingHour.weekday}-label`)
          openingHour.key = `periodic-weekday-${openingHour.weekday}`
        }
        openingHours.push(openingHour)
      }
      return openingHours
    },
    open() {
      return this.$route.name == "contact"
    },
    emailUrl() {
      if (!this.company.email) {
        return ''
      }
      return (('') + this.company.email).startsWith('mailto:') ? this.company.email : "mailto:" + this.company.email;
    },
    websiteUrl() {
      if (!this.company.website) {
        return ''
      }
      let url = ('' + this.company.website).toLowerCase()
      return url.startsWith('http') ? url : "https://" + url;
    },
    websiteUrlAsLabel() {
      if (!this.websiteUrl) {
        return ''
      }
      return ('' + this.websiteUrl).replace('http://', '').replace('https://', '')
    },
    phoneUrl() {
      if (!this.company.phone) {
        return ''
      }
      return (('') + this.company.phone).startsWith('tel:') ? this.company.phone : "tel:" + this.company.phone;
    },
    country() {
      if (!this.company.country || this.company.country.length <= 0) {
        return null
      }
      let name = this.services.locale.getCountryName(this.company.country)
      return name ? name : null
    }

  },
  async mounted() {
    this.updateBodyClass()
  },
  methods: {
    updateBodyClass() {
      if (this.open) {
        document.body.classList.add("open-contact-panel");
      } else {
        document.body.classList.remove("open-contact-panel");
      }
    },
    togglePanel() {
      this.$router.push({
        name: "menu",
        params: { ...this.$route.params },
      });
    },
  },
};
</script>
<style scoped>
.contact-panel {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 20;
}

.contact-panel.open {
  height: calc(100%);
}

.contact-panel .panel-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.contact-panel .panel-header .subtitle {
  font-size: 0.75rem;
}

.contact-panel .panel-header .cart-icon {
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.3);
  margin-top: -2rem;
  background-color: var(--ion-color-primary);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.contact-panel .panel-header .cart-icon .toggle-icon {
  color: white;
  font-size: 2rem;
}

.contact-panel .close-background {
  height: 30vh;
  min-height: 5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  position: relative;
}

.contact-panel .body {
  background-color: white;
  flex: 1;
  flex-direction: column;
  padding: 0 2rem 2rem;
  overflow: hidden;
  overflow-y: auto;

}

.contact-panel .body .content {
  text-align: center;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

.contact-panel .panel-header .content {
  width: 100%;
  padding: 2rem 2rem 1rem 1.5rem;
  align-items: center;
  background-color: white;
  margin-top: -2rem;
  box-shadow: 0 1px 20px hsla(0, 0%, 39%, 0.2);
  text-align: center;
}

.contact-panel.open .panel-header .content {
  padding-top: 2.5rem;

}

.body .content .name {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 599;
}

.content .address {
  margin-top: 0.4rem;
  margin-bottom: 1.3rem;
  font-size: 0.8rem;
  color: #000;
  font-weight: 599;
}

.content .description {
  white-space: pre-wrap;
  padding: 1rem 0;


}

.content .contacts .contact {
  margin-bottom: 0.2rem;

}

.content .contacts .label {
  font-weight: 400;
  margin-right: 0.3rem;
}

.content .contacts .value {
  font-weight: 600;
  margin-right: 0.3rem;
  text-decoration: none;
}

.opening-hours-section {
  margin-top: 2rem;
}

.opening-hours-section .opening-hour .label {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}
</style>

<style>
.open-contact-panel ion-content::part(scroll) {
  overflow: hidden;
}
</style>