<template>
  <div class="meal-item list-item" lines="full" :data-product-id="item.id" :data-sort-order="item.sortOrder">
    <ion-icon class="sort-icon drag-handle" :icon="reorderTwoOutline"></ion-icon>
    <div class="list-item-content" v-if="product">
      <h2 class="list-item-name">
        <span class="name">{{ services.translations.translation2Text(product.name) }}</span>
        <span class="parent-name" v-if="parentProduct">({{ services.translations.translation2Text(parentProduct.name)
          }})</span>
      </h2>
      <div class="addons" v-if="hasAddonsSelected">
        <div class="preview">
          <span class="cart-addons-label" v-text="$t('shopping-cart-addons-label')"
            v-if="selectedAddons.length > 0"></span>
          <div class="addon" v-for="addon, addonIndex of selectedAddons" :key="addon.id">
            <span class="addonname" v-text="services.translations.translation2Text(addon.name)"></span>
            <span class="seperator" v-if="addonIndex < selectedAddons.length - 1">,</span>
          </div>
        </div>
      </div>
      <div class="combined-products" v-if="isCombinedProduct">
        <div class="preview">
          <div class="slot" v-for="slot of slots" :key="slot.id">

            <span class="slotname" v-text="services.translations.translation2Text(slot.name) + ':'"></span>
            <div class="product">
              <span class="productname" v-if="cartItem"
                v-text="services.translations.translation2Text(companyService.getProductById(cartItem.chosen[slot.id]).name)"></span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="note-buttons" v-if="enableNotes">
      <ion-button class="note-button add" fill="clear" @click="addNote" v-if="!showNote && !comment"> {{
    $t('order-item-add-note') }} </ion-button>
      <ion-button class="note-button remove" fill="clear" @click="removeNote" v-if="showNote || comment"> {{
    $t('order-item-remove-note') }} </ion-button>
    </div>







    <aside class="list-item-actions note below" v-if="showNote || comment">
      <textarea class="note-input" auto-grow outlined rows="1" :label="$t('shopping-cart-input-label-item-note')"
        v-model="comment"></textarea>
    </aside>
  </div>
</template>

<script setup>
import { IonButton, IonIcon } from "@ionic/vue";
import { reorderTwoOutline } from "ionicons/icons";
</script>
<script>

export default {
  props: ["item", "hasDescription", "enableNotes"],
  data() {
    return {
      showNote: false,
      comment: "",
    };
  },
  watch: {
    comment: function () {
      this.$emit('notechanged', this.comment);
    },
    item() {
      if (this.item && this.item.note != this.comment) {
        this.updateNote()
      }
    }
  },
  computed: {
    product() {
      if (!this.item || !this.item.productId) {
        return null;
      }
      return this.companyService.getProductById(this.item.productId)
    },
    cartItem() {
      if (!this.item || !this.item.cartItemId) {
        console.log('itemnotfpound', this.item)
        return null;
      }
      let cartItem = this.services.cartService.getCartItemById(this.item.cartItemId)
      return cartItem;
    },
    parentProduct() {
      if (!this.item || !this.item.parentId) {
        return null;
      }
      return this.companyService.getProductById(this.item.parentId)
    },
    isCombinedProduct() {
      return this.product && (this.product.type == 'combined')
    },
    hasDetails() {
      return this.isCombinedProduct || this.hasAddons
    },
    hasAddons() {
      return this.product && this.product.addons && Object.keys(this.product.addons).length > 0
    },
    hasAddonsSelected() {
      return this.cartItem && this.cartItem.addons && Object.keys(this.cartItem.addons).length > 0
    },
    selectedAddons() {
      if (!this.hasAddonsSelected) {
        return []
      }
      let addons = []
      for (let addon of this.cartItem.product.addons) {
        if (this.cartItem.addons[addon.id]) {
          addons.push(addon);
        }
      }
      return addons

    },
    slots() {
      if (!this.isCombinedProduct) {
        return [];
      }

      let slots = JSON.parse(JSON.stringify(this.product.slots));

      let validSlots = [];
      for (let slot of slots) {
        if (!slot.availableProducts) {
          continue
        }
        let products = [];
        for (let productLine of Object.values(slot.availableProducts)) {
          if (productLine.hidden) { continue }

          let product = productLine.productId ? this.companyService.getProductById(productLine.productId) : null;
          if (!product) { continue }

          products.push(product);
        }
        slot.products = products;

        if (slot.products.length < 1) {
          continue
        }
        validSlots.push(slot);
      }
      return validSlots;
    },
  },
  async mounted() {
    this.updateNote()

  },
  methods: {
    updateNote() {
      this.comment = this.item.note;
    },
    addNote() {
      this.showNote = true;
      this.comment = "";
    },
    removeNote() {
      this.showNote = false;
      this.comment = "";
    },
  },
};
</script>
<style scoped>
.list-item {
  padding-bottom: 1rem;
  display: flex;
  border-bottom: 1px solid var(--ion-color-light-shade);
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0.5rem 0;
}

.list-item.dragging {
  border-top: 1px solid var(--ion-color-light-shade);
  overflow: hidden;
}



.list-item .list-item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.list-item .list-item-name {
  font-size: 1.1rem;
  font-weight: 500;
  padding-bottom: 0em;
  margin-bottom: 0em;
  margin-top: 0;
  flex: 1;
  text-align: left;
}

@media (max-width: 500px) {
  .list-item .list-item-name {
    font-size: 1rem;
  }
}
.list-item .list-item-name .parent-name {
  font-size: 0.9rem;
  font-weight: 450;
  padding-left: 0.4rem;
  text-align: left;
}

.list-item .list-item-description {
  font-size: 0.9rem;
  color: var(--ion-color-dark);
}

.list-item .list-item-description::first-letter {
  text-transform: uppercase;
}

.list-item .list-item-parent-name {
  font-size: 0.9rem;
  color: var(--ion-color-dark);
}

.list-item .list-item-actions {
  flex-basis: 100%;
}

.list-item .list-item-actions textarea {
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  min-height: 3rem;
}

.note-button {
  margin-right: -0.5rem;
  font-size: 0.8rem;
}

@media (max-width: 500px) {
  .note-button {

    font-size: 0.7rem;
  }

}

.meal-item .drag-handle {
  cursor: move;
  cursor: grab;
  font-size: 2rem;
  color: #999;
  margin-right: 0.8rem;
}


.list-item-actions.combined {
  flex-basis: 100%;
  width: 100%;
  margin-left: 0 !important;
}

.combined-products .preview {
  margin-top: 0.1rem !important;
  margin-bottom: 1rem;
  text-align: left;

}

.combined-products .preview .slotname {
  font-weight: 500;
  font-size: 0.8rem;
  display: inline;
  text-transform: capitalize;
  display: inline-block;
}

.combined-products .preview .slotname::first-letter {
  text-transform: uppercase;
}

.combined-products .preview .product {
  display: inline-block;
  padding-left: 0.2rem;
}

.combined-products .preview .productname {
  font-size: 0.8rem;
}

.combined-products .preview .seperator.lastone {
  font-size: 0.8rem;
  display: inline-block;
  padding-left: 0.2rem;
}

.addons .preview {
  margin-top: 0.3rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  text-align: left;

}


.addons .preview .addon {
  display: inline-block;
  padding-left: 0.2rem;
}

.addons .preview .addonname {
  font-size: 0.8rem;
}



.combined-products .details {
  margin-top: 2rem;

  width: 100%;

  text-align: center;
  margin-bottom: 2rem;

}

.combined-products .details .slot {
  margin-bottom: 1rem;
}

.combined-products .details .slotname {
  margin-top: 1rem;

  padding-bottom: 0.2rem;
  display: block;
  font-weight: 300;
  font-size: 1.1rem;
}

.combined-products .details .product {

  padding-left: 0.2rem;
}

.combined-products .details .productname {
  font-size: 0.8rem;
}

.combined-products .details .seperator.lastone {
  font-size: 0.8rem;
  display: inline-block;
  padding-left: 0.2rem;
}
</style>
